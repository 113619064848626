var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"news"}},[_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),(_vm.userCanCreate)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.add()}}},[_vm._v("Добавить")]):_vm._e()],1)],1),_c('v-card',[_c('v-container',{staticClass:"mb-2",attrs:{"fluid":""}},[_c('ui-filter',{attrs:{"fields":_vm.filterFields},on:{"apply":_vm.filterApplyHandle},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.active_from, 'DD.MM.YYYY HH:mm:ss'))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.active_to, 'DD.MM.YYYY HH:mm:ss'))+" ")]}},{key:"item.active_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.active_from))+" ")]}},{key:"item.active_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.active_to))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCanUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.userCanDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1),(_vm.userCanDelete)?_c('delete-news-dialog',{attrs:{"entity-id":_vm.idForDelete},on:{"deleted":function($event){return _vm.handleDeleted()}},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }