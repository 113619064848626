import axios from 'axios';
import { generateMutationFormData, removeObservation } from '@/utils';
import { compact, differenceBy } from 'lodash';

export default {
  /**
   * Получить список залов.
   *
   * @param {Boolean} availableNow
   */
  async gyms(filter = null) {
    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, {
      query: `
        query($filter: GymFilterInput) {
          gyms(filter: $filter) {
            id
            title
            point {
              id
              lat
              lon
            }
          }
        }`,
      variables: {
        filter
      }
    });
  },

  /**
   * Получить зал по ID.
   *
   * @param {Number|String} id ID зала
   */
  async gym(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            gym(id: ${id}) {
              id
              active
              maintenance
              title
              address
              has_shower
              around_the_clock
              how_to_find
              free_cancel_time
              min_cancel_time
              price
              door_key
              door_ip
              hasActiveWorkout
              point {
                id
                lat
                lon
              }
              gymWorkTimes {
                id
                start_time
                end_time
                price
                day_of_week
              }
              files {
                id
                src
                url
                name
                mime
              }
              howToFindImages {
                id
                src
                url
                name
                mime
              }
              equipments {
                id
                sort
                title
                file {
                  id
                  src
                  url
                  name
                }
                items {
                  id
                  sort
                  title
                }
              }
              equipmentItems {
                id
                sort
                title
              }
              machines {
                id
                serial_number
              }
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание зала.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async createGym(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'createGym',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Обновление зала.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async updateGym(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'updateGym',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Удаление зала.
   *
   * @param {Number|String} id ID зала
   */
  async deleteGym(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteGym(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Открытие двери
   *
   * @param {Number|String} id ID зала
   */
  async openDoorInGym(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            openDoorInGym(id: $id)
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};



/**
 * Генерация FormData для зала.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные
 * @param {Object} input Новые данные
 * @param {Object} files Файлы
 * @param {Object} fileFields Поля, в которых находятся файлы
 * @param {String[]} additionalReturnsFields Дополнительные возвращаемые поля
 */
function getFormData(
  mutationName,
  currentInput,
  input,
  files = {},
  fileFields = {},
  additionalReturnsFields = []
) {
  input = removeObservation(input);

  // Параметры мутации
  let mutationParams = [
    '$active: Boolean!',
    '$maintenance: Boolean!',
    '$title: String!',
    '$address: String!',
    '$has_shower: Boolean!',
    '$around_the_clock: Boolean!',
    '$how_to_find: String!',
    '$free_cancel_time: Int!',
    '$min_cancel_time: Int!',
    '$price: Int!',
    '$door_ip: String!',
    '$door_key: String!'
  ];

  // Параметры метода
  let methodParams = [
    'active: $active',
    'maintenance: $maintenance',
    'title: $title',
    'address: $address',
    'has_shower: $has_shower',
    'around_the_clock: $around_the_clock',
    'how_to_find: $how_to_find',
    'free_cancel_time: $free_cancel_time',
    'min_cancel_time: $min_cancel_time',
    'price: $price',
    'door_ip: $door_ip',
    'door_key: $door_key'
  ];

  const returnFields = [
    'id',
    'active',
    'maintenance',
    'title',
    'address',
    'has_shower',
    'around_the_clock',
    'how_to_find',
    'free_cancel_time',
    'min_cancel_time',
    'price',
    'door_ip',
    'door_key',
    'point { id, lat, lon }',
    'gymWorkTimes { id, start_time, end_time, price, day_of_week }',
    'files { id, src, url, name, mime }',
    'howToFindImages { id, src, url, name, mime }',
    'equipments { id, sort, title, file { id, src, url, name, mime }, items { id, sort, title } }',
    'machines { id, serial_number }'
  ].concat(additionalReturnsFields);

  if (input.id) {
    mutationParams.push('$id: ID!');
    methodParams.push('id: $id');
  }

  // Точка на карте
  if (input.point) {
    const point = input.point;
    const params = {};

    if (point.id) {
      params.update = point;
    } else {
      params.create = point;
    }

    input.point = params;

    if (mutationName === 'createGym')
      mutationParams.push(`$point: CreatePointInputHasOne!`);
    else mutationParams.push(`$point: CreatePointInputHasOne`);

    methodParams.push(`point: $point`);
  }

  // Оборудование
  if (input.equipments) {
    const equipments = input.equipments;
    const params = {};
    const equipmentIds = equipments.map(item => item.id);

    params.sync = equipmentIds;

    input.equipments = params;

    mutationParams.push(`$equipments: UpdateEquipmentBelongsToMany`);
    methodParams.push(`equipments: $equipments`);
  }

  // Элементы оборудования
  if (input.equipmentItems) {
    const equipmentItems = input.equipmentItems;
    const params = {};
    const equipmentItemIds = equipmentItems.map(item => item.id);

    params.sync = equipmentItemIds;

    input.equipmentItems = params;

    mutationParams.push(`$equipmentItems: UpdateGymEquipmentItemHasMany`);
    methodParams.push(`equipmentItems: $equipmentItems`);
  }

  // Автоматы
  if (input.machines) {

    const machines = input.machines;
    const params = {};
    const machineIds = machines.map(item => item.id);

    if (machineIds.length > 0) params.connect = machineIds;

    if (currentInput && currentInput.machines) {
      const removedMachine = differenceBy(
        currentInput.machines,
        machines,
        'id'
      );

      let removedMachineIds = removedMachine.map(item => item.id);
      removedMachineIds = compact(removedMachineIds);

      if (removedMachineIds.length > 0) {
        params.disconnect = removedMachineIds;
      }
    }

    input.machines = params;

    mutationParams.push(`$machines: MachinesHasMany`);
    methodParams.push(`machines: $machines`);
  }

  // Время работы и цена
  if (input.gymWorkTimes) {
    const worktime = input.gymWorkTimes;
    const params = {};

    // Созданные элементы
    const newWorktime = worktime.filter(item => !item.id);

    // Существующие элементы
    const existingWorktime = worktime.filter(item => item.id);

    if (newWorktime.length > 0) params.create = newWorktime;

    if (existingWorktime.length > 0) params.update = existingWorktime;

    if (currentInput && currentInput.gymWorkTimes) {
      const removedWorktime = differenceBy(
        currentInput.gymWorkTimes,
        existingWorktime,
        'id'
      );

      let removedWorktimeIds = removedWorktime.map(item => item.id);
      removedWorktimeIds = compact(removedWorktimeIds);

      if (removedWorktimeIds.length > 0) {
        params.delete = removedWorktimeIds;
      }
    }

    input.gymWorkTimes = params;

    mutationParams.push(`$gymWorkTimes: GymWorkTimesHasMany`);
    methodParams.push(`gymWorkTimes: $gymWorkTimes`);
  }

  return generateMutationFormData(
    mutationName,
    currentInput,
    input,
    methodParams,
    mutationParams,
    returnFields,
    files,
    fileFields
  );
}
