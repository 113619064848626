<template>
  <div class="edit">
    <validation-observer ref="observer">
      <v-container
        fluid
        class="px-0"
        v-for="(row, index) in filterFields(fieldsRow)"
        :key="index"
      >
        <div
          class="text-h5 mb-3"
          v-if="row.title"
        >{{row.title}}</div>

        <v-row>
          <v-col
            v-for="(field) in row.fields"
            :key="field.id"
            v-bind="field.colProps || row.colsProp"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="field.id"
              :rules="field.rules"
            >
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                v-bind="field.props"
                outlined
                :id="field.id"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import { Entity, Validation } from '@/mixins';
import { VTextarea } from 'vuetify/lib';
import { NumberInput } from '@/components/UI';

export default {
  name: 'edit-faq',

  mixins: [Entity, Validation],

  components: {
    VTextarea,
    NumberInput
  },

  computed: {
    fieldsRow() {
      return [
        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12
          },

          fields: [
            {
              id: 'sort',
              label: 'Сортировка',
              component: 'NumberInput',
              rules: 'required',
              props: {
                min: 1
              }
            },
            {
              id: 'question',
              label: 'Вопрос',
              component: 'VTextarea',
              rules: 'required',
            },
            {
              id: 'answer',
              label: 'Ответ',
              component: 'VTextarea',
              rules: 'required',
            }
          ]
        }
      ];
    }
  }
};
</script>