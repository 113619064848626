<template>
  <div class="machine-viewer">
    <v-autocomplete
      v-model="localValue"
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      item-text="serial_number"
      label="Автоматы"
      placeholder="Введите серийный номер для поиска"
      multiple
      return-object
      outlined
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
          :color="data.item.gym ? 'red' : null"
        >
          {{ data.item.serial_number }} <span
            v-if="data.item.gym"
            class="ml-1"
          >({{data.item.gym.title}})</span>
        </v-chip>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>#{{data.item.id}} {{data.item.serial_number}}</v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.gym.title"
            v-if="data.item.gym"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { Notify } from '@/mixins';

export default {
  mixins: [Notify],

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    loading: false,
    search: null,
    items: []
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    search(val) {
      if (val === '' || this.loading) return;

      this.fetch(val);
    },

    localValue(val) {
      this.items = val;
    }
  },

  mounted() {
    setTimeout(() => {
      this.items = this.localValue;
    });
  },

  methods: {
    async fetch(serailNumber = '') {
      this.loading = true;

      const input = {};

      if (serailNumber !== '') {
        let filter = {};
        filter['serial_number'] = `%${serailNumber}%`;
        input.filter = filter;
      }

      const result = await this.$api.machine.machines(null, 1000, input);

      if (result.data.data.machines) {
        this.items = result.data.data.machines.data;
      }

      this.loading = false;
    },

    remove(item) {
      let itemIndex = this.localValue.findIndex(
        (element) => element.id === item.id
      );

      if (itemIndex !== -1) {
        this.localValue.splice(itemIndex, 1);
      }
    }
  }
};
</script>
