<template>
  <div id="news">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
        <v-spacer />
        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>

    </v-card>

    <v-card>
      <v-container
        class="mb-2"
        fluid
      >
        <ui-filter
          :fields="filterFields"
          v-model="filter"
          @apply="filterApplyHandle"
        />
      </v-container>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{vFormatDate(item.active_from, 'DD.MM.YYYY HH:mm:ss')}}
        </template>

        <template v-slot:[`item.updated_at`]="{ item }">
          {{vFormatDate(item.active_to, 'DD.MM.YYYY HH:mm:ss')}}
        </template>

        <template v-slot:[`item.active_from`]="{ item }">
          {{vFormatDate(item.active_from)}}
        </template>

        <template v-slot:[`item.active_to`]="{ item }">
          {{vFormatDate(item.active_to)}}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            v-if="userCanUpdate"
          >mdi-pencil</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="userCanDelete"
          >mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <delete-news-dialog
      v-model="deleteDialog"
      :entity-id="idForDelete"
      @deleted="handleDeleted()"
      v-if="userCanDelete"
    />
  </div>
</template>

<script>
import { UserOptions, Filterable, Sortable, EntityIndex } from "@/mixins";
import { DeleteNewsDialog } from "@/components/news/Dialogs";
import { formatDateUnix, removeObservation } from "@/utils";
import { UiFilter } from "@/components/UI";

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [UserOptions, Filterable, Sortable, EntityIndex],

  components: {
    UiFilter,
    DeleteNewsDialog
  },

  data: () => ({
    title: "Новости",
    loading: false,
    total: 0,
    idForDelete: 0,
    deleteDialog: false,
    items: [],
    filterFields: [
      {
        label: "ID",
        id: "id",
        component: "NumberInput"
      },
      {
        label: "Активные",
        id: "isActive",
        component: "VCheckbox"
      }
    ],
    policyKey: "news_policy"
  }),

  computed: {
    userFilterKey() {
      return "news-filter";
    },

    userOptionsKey() {
      return "news-grid";
    },

    headers() {
      const headers = [
        { text: "#", value: "id" },
        { text: "Заголовок", value: "title" },
        { text: "Описание", value: "description" },
        { text: "Дата создания", value: "created_at" },
        { text: "Дата обновления", value: "updated_at" },
        { text: "Начало активности", value: "active_from" },
        { text: "Конец активности", value: "active_to" }
      ];

      if (this.userCanUpdate || this.userCanDelete) {
        headers.push({
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;

      const input = {};

      const filter = this.getFilterInput(
        removeObservation(this.filter),
        this.getFilterMap(this.filterFields)
      );

      if (filter) {
        input.filter = filter;
      }

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      const result = await this.$api.news.news(page, itemsPerPage, input);

      if (result.data.data.news) {
        this.items = result.data.data.news.data;
        this.total = result.data.data.news.paginatorInfo.total;
      }

      this.loading = false;
    },

    add() {
      this.$router.push({ name: "NewsEdit", params: { id: 0 } });
    },

    edit(item) {
      this.$router.push({ name: "NewsEdit", params: { id: item.id } });
    },

    deleteItem(item) {
      this.idForDelete = item.id;
      this.deleteDialog = true;
    },

    handleDeleted() {
      this.fetch();
    },

    vFormatDate(date, format = "DD.MM.YYYY") {
      return formatDateUnix(date, format);
    },

    filterApplyHandle() {
      this.fetch();
    }
  }
};
</script>