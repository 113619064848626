import axios from 'axios';

export default {
  /**
   * Создать новую транзакцию.
   *
   * @param {Number} value Значение транзакции
   * @param {Object} input
   */
  async createTransaction(value, input) {
    if (value > 0) {
      input.income = value;
      input.outcome = 0;
    } else if (value < 0) {
      input.outcome = Math.abs(value);
      input.income = 0;
    }

    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreateTransactionInput!) {
            createTransaction(input: $input) {
              id
              user {
                id
                account
                transactions {
                  id
                  income
                  outcome
                  description
                }
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
