<template>
  <div class="file-input-select">
    <v-menu bottom :disabled="disabled || readonly">
      <template v-slot:activator="{ on, attrs, value }">
        <v-file-input
          v-model="files"
          ref="fileInput"
          :label="label"
          :multiple="multiple"
          :placeholder="placeholder"
          prepend-icon="mdi-paperclip"
          :outlined="outlined"
          :dense="dense"
          :disabled="disabled"
          :show-size="1000"
          :clearable="clearable"
          @click="handleClick($event, value)"
          :error-messages="errorMessages"
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:selection="{ index, file }">
            <v-chip
              v-if="index < 2"
              dark
              label
              small
              @click.stop="handleClickFile(file)"
            >
              <template v-slot:default>
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 150px;"
                >
                  {{ file.name }}
                </span>
              </template>
            </v-chip>

            <span
              v-else-if="index === 2"
              class="overline grey--text text--darken-3 mx-2"
            >
              +{{ files.length - 2 }} {{getMoreText(files.length - 2, ['Файл', 'Файла', 'Файлов'])}}
            </span>
          </template>

          <template
            v-slot:append-outer
            v-if="showHistoryBtn"
          >
            <v-btn
              icon
              class="mt-n2"
              @click="historyClick"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </template>

      <v-list>
        <v-list-item @click="fileViewerDialog = true">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            Из медиатеки
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="loadFromComputer()">
          <v-list-item-icon class="mr-3">
            <v-icon>mdi-upload</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Загрузить новый файл</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <file-viewer-dialog
      v-model="fileViewerDialog"
      :multiple="multiple"
      @select="files = $event"
    />

    <CoolLightBox
      :items="lightBoxItems"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
      :slideshow="false"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import InputComponent from '../mixin/inputComponent';
import { FileViewerDialog } from './Dialogs';
import { declOfNum } from '@/utils';
import { File as fileMixin } from '@/mixins';

export default {
  name: 'file-input-select',

  mixins: [InputComponent, fileMixin],

  components: {
    FileViewerDialog
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    },

    showHistoryBtn: {
      type: Boolean,
      default: false
    },

    value: {
      type: [Array, Object, File],
      default: () => []
    }
  },

  data: () => ({
    fileViewerDialog: false,
    lightBoxIndex: null
  }),

  computed: {
    files: {
      get() {
        return !this.value || Array.isArray(this.value)
          ? this.value
          : [this.value];
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    placeholder() {
      return this.multiple ? 'Выберите файлы' : 'Выберите файл';
    },

    lightBoxItems() {
      if (!this.images) return [];
      return this.images.map((image) => ({
        title: image.name,
        src: image.url
      }));
    },

    images() {
      if (!this.files) return [];
      return this.files.filter((file) => this.isImage(file));
    },

    imagesMap() {
      const map = {};

      if (!this.images) return map;

      this.images.forEach((image, index) => {
        map[image.id] = index;
      });

      return map;
    }
  },

  methods: {
    getMoreText(n, words) {
      return declOfNum(n, words);
    },

    loadFromComputer() {
      this.$refs.fileInput.$el.querySelector('input').click();
    },

    handleClick(event, value) {
      if (this.readonly || !value) {
        event.preventDefault();
        return false;
      }

      return true;
    },

    getImageIndex(id) {
      return this.imagesMap[id];
    },

    handleClickFile(file) {
      if (!this.isImage(file)) return false;

      this.lightBoxIndex = this.getImageIndex(file.id);
    },

    historyClick() {
      this.$emit('history-click', event);
    }
  }
};
</script>