<template>
  <div id="delete-files-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title>
          Подтверждение удаления
        </v-card-title>

        <v-card-text>
          {{descriptionText}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteFiles()"
            :loading="loading"
          >
            Удалить
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
            :disabled="loading"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Dialog } from '@/mixins';

export default {
  name: 'delete-files-dialog',

  mixins: [Dialog],

  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },

    descriptionText: {
      type: String,
      default: 'Вы действительно хотите удалить выбранные файлы?'
    }
  },

  methods: {
    async deleteFiles() {
      this.loading = true;

      if (Array.isArray(this.items)) {
        for (let file of this.items) {
          await this.$api.file.deleteFile(file.id);
        }
      } else {
        await this.$api.file.deleteFile(this.items.id);
      }

      this.$emit('deleted', this.items);
      this.loading = false;
      this.dialog = false;
    }
  }
};
</script>