<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="htmlClass"
        :value="computedDateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="outlined"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        :id="id"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
import InputComponent from './mixin/inputComponent';
import { formatDate, formatDateUnix } from '@/utils';

export default {
  name: 'date-picker',

  mixins: [InputComponent],

  props: {
    value: {
      type: [String, Number]
    },

    returnString: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    menu: false
  }),

  computed: {
    date: {
      get() {
        const format = 'YYYY-MM-DD';

        if (parseInt(this.value) > 0) {
          return this.value ? formatDateUnix(this.value, format) : this.value;
        } else {
          return this.value ? formatDate(this.value, format) : this.value;
        }
      },

      set(value) {
        const momentValue = moment(value);

        this.$emit(
          'input',
          this.returnString
            ? momentValue.format('YYYY-MM-DD 00:00:00')
            : momentValue.unix()
        );
      }
    },

    computedDateFormatted() {
      return formatDate(this.date);
    }
  }
};
</script>