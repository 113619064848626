<template>
  <div class="edit">
    <validation-observer ref="observer">
      <v-container
          fluid
          class="px-0"
          v-for="(row, index) in filterFields(fieldsRow)"
          :key="index"
      >
        <div
            class="text-h5 mb-3"
            v-if="row.title"
        >{{ row.title }}
        </div>
        <v-row>
          <v-col
              v-for="(field) in row.fields"
              :key="field.id"
              v-bind="field.colProps || row.colsProp"
              class="py-0"
          >
            <validation-provider
                v-slot="{ errors }"
                :name="field.id"
                :rules="field.rules"
                v-if="!field.hidden"
            >
              <component
                  :is="field.component"
                  v-model="localValue[field.id]"
                  :label="field.label"
                  :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                  v-bind="field.props"
                  outlined
                  :id="field.id"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-tabs
            v-model="CurrentTab"
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
              v-for="(param, index) in parameters"
              :href="`#tab-` + index"
              :key="index"
          >
            {{ param.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items
            v-model="CurrentTab"
            class="mt-3"
        >
          <v-tab-item
              v-for="(param, index) in parameters"
              :key="index"
              :value="`tab-` + index"
          >
            <div
                v-for="prop in param.properties"
                :key="prop.id"
                class="py-0"
            >
              <component
                  v-model="additionalParams[index][prop.label].value"
                  :is="prop.component"
                  :label="prop.label"
                  v-bind="prop.props"
                  outlined
                  :id="prop.id"
                  @change="fillAdditionalParams"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import {Entity, Validation} from '@/mixins';
import {VTextField} from 'vuetify/lib';
import {DatePicker, NumberInput, FileInputSelect} from '@/components/UI';

export default {
  name: 'edit-products',

  mixins: [Entity, Validation],

  components: {
    VTextField,
    NumberInput,
    DatePicker,
    FileInputSelect
  },

  data: () => ({
    CurrentTab: null,
    additionalParams : {
      'portion': {
        'Белки': {value:'', id: 0, sort: 1},
        'Жиры': {value:'', id: 0, sort: 2},
        'Углеводы': {value:'', id: 0, sort: 3},
        'Пищевые волокна': {value:'', id: 0, sort: 4},
        'Энергетическая ценность': {value:'', id: 0, sort: 5},
        'Срок годности': {value:'', id: 0, sort: 6},
        'Вес': {value:'', id: 0, sort: 7},
        'Бренд': {value:'', id: 0, sort: 8},
      },
      '100g': {
        'Белки': {value:'', id: 0, sort: 1},
        'Жиры': {value:'', id: 0, sort: 2},
        'Углеводы': {value:'', id: 0, sort: 3},
        'Пищевые волокна': {value:'', id: 0, sort: 4},
        'Энергетическая ценность': {value:'', id: 0, sort: 5},
        'Срок годности': {value:'', id: 0, sort: 6},
        'Вес': {value:'', id: 0, sort: 7},
        'Бренд': {value:'', id: 0, sort: 8},
      },
      'ssp': {
        'Белки': {value:'', id: 0, sort: 1},
        'Жиры': {value:'', id: 0, sort: 2},
        'Углеводы': {value:'', id: 0, sort: 3},
        'Пищевые волокна': {value:'', id: 0, sort: 4},
        'Энергетическая ценность': {value:'', id: 0, sort: 5},
        'Срок годности': {value:'', id: 0, sort: 6},
        'Вес': {value:'', id: 0, sort: 7},
        'Бренд': {value:'', id: 0, sort: 8}
      }
    }
  }),

  async mounted() {

    this.refresh();
    this.$bus.$on('refresh', () => {
      this.refresh();
    });
  },
  methods: {
    refresh(){
      for(let key in this.entityData.parameters){
        this.additionalParams[this.entityData.parameters[key].type][this.entityData.parameters[key].title].id = this.entityData.parameters[key].id;
        this.additionalParams[this.entityData.parameters[key].type][this.entityData.parameters[key].title].value = this.entityData.parameters[key].value;
      }
    },
    fillAdditionalParams(){

      this.localValue.parameters = this.additionalParams;
    }
  },

  computed: {
    parameters() {
      return {
        "portion": {
          title: "На порцию",
          properties: [
            {
              id: 'belki',
              label: 'Белки',
              component: 'VTextField',
            },
            {
              id: 'giri',
              label: 'Жиры',
              component: 'VTextField',
            },
            {
              id: 'uglevodi',
              label: 'Углеводы',
              component: 'VTextField',
            },
            {
              id: 'volokna',
              label: 'Пищевые волокна',
              component: 'VTextField',
            },
            {
              id: 'energy',
              label: 'Энергетическая ценность',
              component: 'VTextField',
            },
            {
              id: 'term',
              label: 'Срок годности',
              component: 'VTextField',
            },
            {
              id: 'weight',
              label: 'Вес',
              component: 'VTextField',
            },
            {
              id: 'brand',
              label: 'Бренд',
              component: 'VTextField',
            }
          ]
        },
        "100g": {
          title: "На 100 г",
          properties: [
            {
              id: 'belki',
              label: 'Белки',
              component: 'VTextField',
            },
            {
              id: 'giri',
              label: 'Жиры',
              component: 'VTextField',
            },
            {
              id: 'uglevodi',
              label: 'Углеводы',
              component: 'VTextField',
            },
            {
              id: 'volokna',
              label: 'Пищевые волокна',
              component: 'VTextField',
            },
            {
              id: 'energy',
              label: 'Энергетическая ценность',
              component: 'VTextField',
            },
            {
              id: 'term',
              label: 'Срок годности',
              component: 'VTextField',
            },
            {
              id: 'weight',
              label: 'Вес',
              component: 'VTextField',
            },
            {
              id: 'brand',
              label: 'Бренд',
              component: 'VTextField',
            }
          ]
        },
        "ssp": {
          title: "№ от ССП",
          properties: [
            {
              id: 'belki',
              label: 'Белки',
              component: 'VTextField',
            },
            {
              id: 'giri',
              label: 'Жиры',
              component: 'VTextField',
            },
            {
              id: 'uglevodi',
              label: 'Углеводы',
              component: 'VTextField',
            },
            {
              id: 'volokna',
              label: 'Пищевые волокна',
              component: 'VTextField',
            },
            {
              id: 'energy',
              label: 'Энергетическая ценность',
              component: 'VTextField',
            },
            {
              id: 'term',
              label: 'Срок годности',
              component: 'VTextField',
            },
            {
              id: 'weight',
              label: 'Вес',
              component: 'VTextField',
            },
            {
              id: 'brand',
              label: 'Бренд',
              component: 'VTextField',
            }
          ]
        }
      }
    },
    fieldsRow() {

      return [
        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12
          },

          fields: [
            {
              id: 'title',
              label: 'Название',
              component: 'VTextField',
              rules: 'required'
            },
            {
              id: 'images',
              label: 'Картинки',
              component: 'FileInputSelect',
              props: {
                multiple: true
              }
            },
            {
              id: 'short_description',
              label: 'Краткое описание',
              component: 'VTextField',
            },
            {
              id: 'description',
              label: 'Описание',
              component: 'VTextField',
            },
            {
              id: 'may_contain',
              label: 'Может содержать',
              component: 'VTextField',
            },
            {
              id: 'composition',
              label: 'Состав',
              component: 'VTextField',
            }
          ]
        }
      ];
    }
  }
};
</script>