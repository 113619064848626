<template>
  <div id="file-history-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title>
          {{title}}
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
            item-key="uuid"
            @current-items="setCurrentItems"
          >
            <template v-slot:[`item.preview`]="{ item }">
              <v-card
                height="100"
                width="100"
                class="file-item my-3"
                flat
                style="overflow: hidden;"
              >
                <v-sheet
                  :color="getFileColor(item)"
                  height="100%"
                  width="100"
                  class="file-item__sheet d-flex align-center justify-center"
                >
                  <v-hover v-if="isImage(item)">
                    <template v-slot:default="{ hover }">
                      <v-img
                        :src="item.url"
                        aspect-ratio="1"
                        v-if="isImage(item)"
                        class="grey darken-3"
                        @click="lightBoxIndex = getImageIndex(item.id)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="secondary"
                            style="cursor: pointer;"
                          >
                            <v-icon size="32">mdi-magnify-plus-outline</v-icon>
                          </v-overlay>
                        </v-fade-transition>
                      </v-img>
                    </template>
                  </v-hover>
                </v-sheet>
              </v-card>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              {{item.name}}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="closeDialog()"
            :disabled="loading"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CoolLightBox
      :items="lightBoxItems"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
      :slideshow="false"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import { Dialog, File } from '@/mixins';
import { generateId } from '@/utils';

export default {
  name: 'file-history-dialog',

  mixins: [Dialog, File],

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data: () => ({
    title: 'История изменений',
    itemsPerPage: 5,
    footerProps: { itemsPerPageOptions: [5, 10, 15] },
    options: {},
    lightBoxIndex: null,
    headers: [
      { text: 'Изображение', value: 'preview', sortable: false },
      { text: 'Название', value: 'name' }
    ],

    /**
     * Видимые элементы в таблице
     */
    currentItems: []
  }),

  computed: {
    lightBoxItems() {
      if (this.items.length === 0) return [];

      let items = [];

      this.currentItems.forEach((item) => {
        items.push({
          title: item.name,
          src: item.url
        });
      });

      return items;
    },

    imagesMap() {
      const map = {};

      if (this.items.length === 0) return map;

      this.currentItems.forEach((item, index) => {
        map[item.id] = index;
      });

      return map;
    }
  },

  mounted() {
    this.items.forEach((item) => {
      item.uuid = generateId();
    });
  },

  methods: {
    getImageIndex(id) {
      return this.imagesMap[id];
    },

    setCurrentItems(items) {
      this.currentItems = items;
    }
  }
};
</script>
