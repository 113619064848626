import axios from 'axios';
import { clearPhone } from '@/utils';

export default {
  async loginSms(phone) {
    phone = clearPhone(phone);

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, {
      query: `
        mutation loginSms($phone: String!) {
          loginSms(phone: $phone)
        }`,
      variables: {
        phone
      }
    });
  },

  async login(phone, code) {
    phone = clearPhone(phone);

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, {
      query: `
        mutation login($phone: String!, $code: String!) {
            login(phone: $phone, code: $code) {
              token
            }
        }`,
      variables: {
        phone,
        code
      }
    });
  }
};
