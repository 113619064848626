<template>
  <div class="equipment-viewer">
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="items"
      :options.sync="options"
      :show-select="!loading"
      v-model="localValue"
      :footer-props="footerProps"
      sort-by="id"
      sort-desc
      @item-selected="equipmentSelectedHandle"
      @current-items="setCurrentItems"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Оборудование</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer />

          <v-btn
            color="info"
            small
            @click="addNewEquipment"
          >Добавить</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.items`]="{ item }">
        <div class="my-3">
          <v-checkbox
            v-for="(el, id) in item.items"
            :key="id"
            :label="el.title"
            dense
            color="white"
            hide-details
            :value="el"
            @change="equipmentItemSelectedHandle"
            v-model="selectedEquipmentItems"
          >
            <template v-slot:label>
              <span class="white--text text-body-2">{{el.title}}</span>
            </template>
          </v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.preview`]="{ item }">
        <v-card
          height="100"
          width="100"
          class="file-item my-3"
          flat
          style="overflow: hidden;"
        >
          <v-sheet
            :color="getFileColor(item.file)"
            height="100%"
            width="100"
            class="file-item__sheet d-flex align-center justify-center"
          >
            <v-hover v-if="isImage(item.file)">
              <template v-slot:default="{ hover }">
                <v-img
                  :src="item.file.url"
                  aspect-ratio="1"
                  v-if="isImage(item.file)"
                  class="grey darken-3"
                  @click="lightBoxIndex = getImageIndex(item.file.id)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="secondary"
                      style="cursor: pointer;"
                    >
                      <v-icon size="32">mdi-magnify-plus-outline</v-icon>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </template>
            </v-hover>
          </v-sheet>
        </v-card>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editEquipment(item)"
        >mdi-pencil</v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>

    <CoolLightBox
      :items="lightBoxItems"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
      :slideshow="false"
    >
    </CoolLightBox>

    <gym-equipment-dialog
      v-model="equipmentDialog"
      :item="equipmentItemDialog"
      @close="equipmentDialogCloseHandle"
      @saved="updateItem"
    />

    <delete-gym-equipment-dialog
      v-model="deleteEquipmentDialog"
      :entity-id="deleteItemId"
      @deleted="deletedHandle"
      v-if="deleteItemId"
    />
  </div>
</template>

<script>
import { File, Notify, UserOptions } from '@/mixins';
import {
  GymEquipmentDialog,
  DeleteGymEquipmentDialog
} from '@/components/gym/Dialogs';
import { removeObservation } from '@/utils';

export default {
  mixins: [File, Notify, UserOptions],

  components: {
    GymEquipmentDialog,
    DeleteGymEquipmentDialog
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    loading: false,
    equipmentDialog: false,
    equipmentItemDialog: {},
    deleteEquipmentDialog: false,
    deleteItemId: null,

    /**
     * Видимые элементы в таблице
     */
    currentItems: [],
    footerProps: { itemsPerPageOptions: [5, 25, 50] },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Изображение', value: 'preview', sortable: false },
      { text: 'Название', value: 'title' },
      { text: 'Элементы', value: 'items', sortable: false },
      { text: 'Действия', value: 'actions', sortable: false, align: 'end' }
    ],
    items: [],
    selectedEquipmentItems: [],
    lightBoxIndex: null
  }),

  computed: {
    userOptionsKey() {
      return 'gym-equipments';
    },

    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    lightBoxItems() {
      if (this.items.length === 0) return [];

      let items = [];

      this.currentItems.forEach((item) => {
        items.push({
          title: item.file.name,
          src: item.file.url
        });
      });

      return items;
    },

    imagesMap() {
      const map = {};

      if (this.items.length === 0) return map;

      this.currentItems.forEach((item, index) => {
        map[item.file.id] = index;
      });

      return map;
    },

    selectedEquipmentItemIds() {
      return this.selectedEquipmentItems.map((item) => item.id);
    }
  },

  async mounted() {
    this.eventsUp();

    this.$bus.$emit('get-equipment-items');

    await this.fetch();
  },

  destroyed() {
    this.eventsDown();
  },

  methods: {
    eventsUp() {
      this.$bus.$on('equipment-items-update', (items) => {
        this.$set(this, 'selectedEquipmentItems', items);
      });
    },

    eventsDown() {
      this.$bus.$off('equipment-items-update');
    },

    async fetch() {
      this.loading = true;

      const result = await this.$api.gymEquipments.gymEquipments();

      if (result.data.data.gymEquipments) {
        this.items = result.data.data.gymEquipments;
      }

      this.loading = false;
    },

    getImageIndex(id) {
      return this.imagesMap[id];
    },

    addNewEquipment() {
      this.equipmentDialog = true;
    },

    editEquipment(item) {
      this.$set(this, 'equipmentItemDialog', removeObservation(item));

      setTimeout(() => {
        this.equipmentDialog = true;
      });
    },

    equipmentDialogCloseHandle() {
      this.equipmentItemDialog = {};
    },

    updateItem(itemData) {
      this.equipmentDialogCloseHandle();

      const index = this.items.findIndex((item) => item.id === itemData.id);

      if (index !== -1) {
        this.$set(this.items, index, itemData);
      } else {
        this.items.push(itemData);
      }
    },

    setCurrentItems(items) {
      this.currentItems = items;
    },

    deleteItem(item) {
      this.deleteItemId = item.id;
      this.deleteEquipmentDialog = true;
    },

    deletedHandle(item) {
      const deleteGymEquipment = item.deleteGymEquipment;
      this.deleteItemId = null;

      this.notifySuccess('Оборудование удалено.');

      const index = this.items.findIndex(
        (_item) => _item.id === deleteGymEquipment.id
      );

      if (index !== -1) {
        this.items.splice(index, 1);
      }
    },

    equipmentSelectedHandle(event) {
      if (event.value) {
        this.$bus.$emit('select-equipment', event.item);
      } else {
        this.$bus.$emit('unselect-equipment', event.item);
      }
    },

    equipmentItemSelectedHandle(event) {
      this.$bus.$emit('select-equipment-items', event);
    }
  }
};
</script>
