<template>
  <div class="number-input-range">
    <number-input
      :label="labelFrom"
      :outlined="outlined"
      :hide-details="hideDetails"
      :clearable="clearable"
      v-model="valueFrom"
    />
    <span class="number-input-range__divider">&#8213;</span>
    <number-input
      :label="labelTo"
      :outlined="outlined"
      :hide-details="hideDetails"
      :clearable="clearable"
      v-model="valueTo"
    />
  </div>
</template>

<script>
import InputComponent from './mixin/inputComponent';
import NumberInput from './NumberInput';

export default {
  name: 'number-input-range',

  mixins: [InputComponent],

  components: {
    NumberInput
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    valueFrom: {
      get() {
        const value = parseInt(this.value[0]);
        return !isNaN(value) ? value : null;
      },

      set(value) {
        value = parseInt(value);
        this.$emit('input', [value, this.valueTo]);
      }
    },

    valueTo: {
      get() {
        const value = parseInt(this.value[1]);
        return !isNaN(value) ? value : null;
      },

      set(value) {
        value = parseInt(value);
        this.$emit('input', [this.valueFrom, value]);
      }
    },

    labelFrom() {
      if (this.label) {
        return `${this.label} От`.trim();
      }

      return 'От';
    },

    labelTo() {
      if (this.label) {
        return `${this.label} До`.trim();
      }

      return 'До';
    }
  }
};
</script>

<style lang="scss" scoped>
.number-input-range {
  display: flex;

  .number-input {
    width: 100%;
  }

  &__divider {
    margin: 16px 6px 0;
  }
}
</style>