export default {
  computed: {
    WORKOUT_STATUS_PENDING() {
      return 'PENDING';
    },

    WORKOUT_STATUS_PROCESS() {
      return 'PROCESS';
    },

    WORKOUT_STATUS_FINISHED() {
      return 'FINISHED';
    },

    WORKOUT_STATUS_CANCELED() {
      return 'CANCELED';
    },

    WorkoutStatuses() {
      return [
        {
          text: this.getWorkoutStatusText(this.WORKOUT_STATUS_PENDING),
          value: this.WORKOUT_STATUS_PENDING
        },
        {
          text: this.getWorkoutStatusText(this.WORKOUT_STATUS_PROCESS),
          value: this.WORKOUT_STATUS_PROCESS
        },
        {
          text: this.getWorkoutStatusText(this.WORKOUT_STATUS_FINISHED),
          value: this.WORKOUT_STATUS_FINISHED
        },
        {
          text: this.getWorkoutStatusText(this.WORKOUT_STATUS_CANCELED),
          value: this.WORKOUT_STATUS_CANCELED
        }
      ];
    }
  },

  methods: {
    getWorkoutStatusColor(value) {
      if (value === this.WORKOUT_STATUS_FINISHED) return 'green';
      else if (value === this.WORKOUT_STATUS_PROCESS) return 'amber darken-1';
      else if (value === this.WORKOUT_STATUS_PENDING) return 'purple';
      else if (value === this.WORKOUT_STATUS_CANCELED) return 'red';
      else return 'gray';
    },

    getWorkoutStatusText(value) {
      if (value === this.WORKOUT_STATUS_FINISHED) return 'Завершена';
      else if (value === this.WORKOUT_STATUS_PROCESS) return 'Активна';
      else if (value === this.WORKOUT_STATUS_PENDING) return 'В ожидании';
      else if (value === this.WORKOUT_STATUS_CANCELED) return 'Отменена';
      else return `Неизвестный статус ${value}`;
    }
  }
};
