var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slides-viewer"},[(_vm.localValue.length === 0)?_c('div',{staticClass:"mb-3"},[_vm._v("Нет элементов.")]):_vm._e(),_c('validation-observer',{ref:"observer"},[(_vm.localValue.length > 0)?_c('v-row',_vm._l((_vm.localValue),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"mb-3",attrs:{"color":"secondary"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("image" + index),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('file-input-select',{attrs:{"outlined":"","label":"Изображение","error-messages":_vm.getErrorMessage(errors)},model:{value:(item.image),callback:function ($$v) {_vm.$set(item, "image", $$v)},expression:"item.image"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("button_text" + index),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Текст кнопки","outlined":"","error-messages":_vm.getErrorMessage(errors)},model:{value:(item.button_text),callback:function ($$v) {_vm.$set(item, "button_text", $$v)},expression:"item.button_text"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("button_action" + index),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.buttonActions,"label":"Действие кнопки","outlined":"","error-messages":_vm.getErrorMessage(errors)},on:{"change":function($event){return _vm.onButtonActionChange(item, $event)}},model:{value:(item.button_action.entity),callback:function ($$v) {_vm.$set(item.button_action, "entity", $$v)},expression:"item.button_action.entity"}})]}}],null,true)})],1),(item.button_action.entity === 'gym')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("button_action" + index),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.gyms,"loading":_vm.loading,"hide-no-data":"","item-text":"title","item-value":"id","label":"Зал","placeholder":"Введите название зала для поиска","outlined":"","cache-items":"","error-messages":_vm.getErrorMessage(errors)},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeGym(item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(("id: " + (data.item.id))))])],1)]}}],null,true),model:{value:(item.button_action.entity_id),callback:function ($$v) {_vm.$set(item.button_action, "entity_id", $$v)},expression:"item.button_action.entity_id"}})]}}],null,true)})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","small":"","text":""},on:{"click":function($event){return _vm.removeItem(index)}}},[_vm._v("Удалить")])],1)],1)],1)}),1):_vm._e()],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":_vm.addItem}},[_vm._v("Добавить")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }