<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-img :src="image" aspect-ratio="1" class="grey darken-3" @click="$emit('onShow')">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>

        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="secondary" style="cursor: pointer;">
            <v-icon size="32">mdi-magnify-plus-outline</v-icon>
          </v-overlay>
        </v-fade-transition>
      </v-img>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: ''
    }
  }
}
</script>