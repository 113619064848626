<template>
  <div id="users">
    <v-card class="mb-3">
      <v-card-title>
        {{ title }}
        <v-spacer />

        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>
    </v-card>

    <v-card>
      <user-viewer :use-actions="userCanUpdate" />
    </v-card>
  </div>
</template>

<script>
import { EntityIndex } from "@/mixins";
import UserViewer from "@/components/UserViewer/UserViewer.vue";

export default {
  name: "index",

  components: {
    UserViewer
  },

  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [EntityIndex],

  data: () => ({
    title: "Пользователи",
    policyKey: "user_policy"
  }),

  methods: {
    add() {
      this.$router.push({ name: "UserEdit", params: { id: 0 } });
    }
  }
};
</script>
