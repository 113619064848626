<template>
  <v-container>
    <workout-table :fetch-items="fetchWorkouts" />
  </v-container>
</template>

<script>
import WorkoutTable from '@/components/workout/table/index.vue';

export default {
  components: {
    WorkoutTable
  },
  props: {
    entityData: {
      type: Object,
      default: () => ({}),
      required: true
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false
    }
  },
  methods: {
    async fetchWorkouts(payload) {
      const result = await this.$api.workout.workouts(
        payload.page,
        payload.itemsPerPage,
        {
          filter: { ...payload.input.filter, user_id: this.entityData.id },
          orderBy: payload.input.orderBy
        }
      );
      return result.data?.data?.workouts
    },
  }
}
</script>