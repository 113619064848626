<template>
  <div class="user-input-select">
    <v-combobox
      class="user-input-select-combobox"
      v-model="users"
      :label="label"
      :multiple="multiple"
      :placeholder="placeholder"
      prepend-icon="mdi-account-outline"
      :outlined="outlined"
      :disabled="disabled"
      :show-size="1000"
      :clearable="clearable"
      :error-messages="errorMessages"
      deletable-chips
      return-object
      @click="openModal"
      @keydown.stop="comboboxKeydownHandle"
      :append-icon="null"
    >
      <template v-slot:selection="{ attrs, item, disabled }">
        <v-chip
          :key="JSON.stringify(item)"
          v-bind="attrs"
          :disabled="disabled"
          close
          dark
          label
          small
          @click:close="closeChipClickHandle(item)"
        >
          <template v-slot:default>
            <span>
              {{ getItemName(item) }} ({{ item.phone | VMask('+# (###) ###-##-##') }})
            </span>
          </template>
        </v-chip>
      </template>
    </v-combobox>

    <user-viewer-dialog
      v-model="userViewerDialog"
      :multiple="multiple"
      @select="selectHandle"
    />
  </div>
</template>

<script>
import InputComponent from '../mixin/inputComponent';
import { UserViewerDialog } from './Dialogs';

export default {
  name: 'user-input-select',

  mixins: [InputComponent],

  components: {
    UserViewerDialog
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    },

    value: {
      type: [Array, Object],
      default: () => {}
    }
  },

  data: () => ({
    userViewerDialog: false
  }),

  computed: {
    users: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    placeholder() {
      return this.multiple ? 'Выберите пользователей' : 'Выберите пользователя';
    }
  },

  methods: {
    handleClick(event, value) {
      if (!value) {
        event.preventDefault();
        return false;
      }

      return true;
    },

    selectHandle(event) {
      this.users = event;
    },

    closeChipClickHandle(item) {
      if (this.multiple) {
        const userIndex = this.users.find((element) => element.id === item.id);

        if (userIndex) {
          this.users.splice(userIndex, 1);
        }
      } else {
        this.users = null;
      }
    },

    comboboxKeydownHandle(event) {
      event.preventDefault();

      if (event.key === 'Enter') {
        this.openModal();
      }
    },

    openModal() {
      this.userViewerDialog = true;
    },

    getItemName(item) {
      let name = '';

      if (item.first_name) {
        name += item.first_name;
      }

      if (item.last_name) {
        name += ` ${item.last_name}`;
      }

      name.trim();

      if (name === '') {
        name = 'Без имени';
      }

      return name;
    }
  }
};
</script>

<style lang="scss">
.user-input-select {
  &-combobox {
    input {
      caret-color: transparent !important;
    }
  }

  .v-text-field__slot,
  input {
    cursor: pointer;
  }
}
</style>