/** @format */

import axios from 'axios';

export default {
  /**
   * Получить список переменных.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async configList(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: ConfigListInput) {
            configList(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                name
                value
                description
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить переменную по ID.
   *
   * @param {Number|String} id ID переменной
   */
  async config(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            config(id: ${id}) {
              id
              name
              value
              description
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление переменной.
   *
   * @param {Object} input Новые данные
   */
   async createConfig(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreateConfigInput!) {
            createConfig(input: $input) {
              id
              name
              value
              description
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление переменной.
   *
   * @param {Object} input Новые данные
   */
  async updateConfig(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: UpdateConfigInput!) {
            updateConfig(input: $input) {
              id
              name
              value
              description
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Удаление переменной.
   *
   * @param {Number|String} id ID переменной
   */
   async deleteConfig(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteConfig(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
