import { timestampRangeDay, OptionsController } from '@/utils';
import { isEqual } from 'lodash';

export default {
  data: () => ({
    filter: {}
  }),

  computed: {
    /**
     * Задаем это свойство в дочерних компонентах.
     */
    userFilterKey() {
      return null;
    }
  },

  beforeMount() {
    if (!this.userFilterKey) {
      return;
    }

    this.userFilterOptions = new OptionsController(this.userFilterKey);

    if (this.userFilterOptions.optionsExists()) {
      this.filter = this.userFilterOptions.getOptions();
    }
  },

  watch: {
    filter(value, oldValue) {
      if (isEqual(value, oldValue)) {
        return;
      }

      if (this.userFilterKey) {
        this.userFilterOptions.saveOptions(value);
      }
    }
  },

  methods: {
    getFilterInput(filter, map = {}) {
      if (Object.keys(filter).length === 0) {
        return null;
      }

      let input = {};

      for (let id in filter) {
        let value = filter[id];

        if (map[id]) {
          if (map[id] === 'DatePickerRange') {
            const from = filter[id][0];
            const _to = filter[id][1];

            value = {
              from,
              to: _to
            };

            if (!_to) {
              const { to } = timestampRangeDay(from);
              value.to = to;
            }
          }

          if (map[id] === 'NumberInputRange') {
            const from = filter[id][0];
            const to = filter[id][1];

            value = {
              from: from !== null ? from : -2147483647, // Min PHP int
              to: to !== null ? to : 2147483647 // Max PHP int
            };
          }
        }

        input[id] = value;
      }

      return input;
    },

    getFilterMap(filterFields = []) {
      let map = {};

      filterFields.forEach(field => {
        map[field.id] = field.component;
      });

      return map;
    }
  }
};
