<template>
  <v-app id="inspire">
    <app-bar />

    <v-main>
      <v-container
        fluid
        :fill-height="$route.name === 'Login'"
      >
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>

    <notifications class="vue-notification-styles" />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";

export default {
  components: {
    AppBar
  }
};
</script>

<style lang="scss">
.vue-notification-styles {
  margin-top: 5rem;

  .vue-notification {
    font-size: 0.875rem;

    &.success {
      background: #68cd86 !important;
      border-left-color: #42a85f !important;
    }
  }
}
</style>