<template>
  <div id="user-group-edit">
    <v-card
      :loading="loadingState"
      class="mb-3"
    >
      <v-card-title>
        {{cardTitle}}

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteDialog = true"
          v-if="canRemove && userCanDelete"
          :disabled="loadingState"
        >Удалить</v-btn>
      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert
        v-if="isCreateNew && !userCanCreate"
        type="error"
      >
        Нет доступа.
      </v-alert>

      <v-card
        v-else-if="entityData"
        class="mb-3"
      >
        <v-tabs
          v-model="currentTab"
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :href="`#tab-${tab.index}`"
            :key="tab.index"
            :disabled="!tab.component"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="currentTab"
          class="mt-3"
        >
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.index"
            :value="`tab-${tab.index}`"
          >
            <v-card flat>
              <v-card-text>
                <component
                  ref="tabContent"
                  :is="tab.component"
                  :entity-data="entityData"
                  v-bind="tab.props"
                  v-model="editInfo"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions
        :saving="saving"
        :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate"
        @save="saveItem"
        @saveAndClose="saveAndClose"
        @close="close"
        v-if="entityData"
      />
    </v-scroll-y-reverse-transition>

    <delete-user-group-dialog
      v-model="deleteDialog"
      :entity-id="$route.params.id"
      @deleted="handleDeleted()"
      v-if="canRemove && userCanDelete"
    />
  </div>
</template>

<script>
import { EditGroup, EditPolicies } from "@/components/user-group/edit";
import { DeleteUserGroupDialog } from "@/components/user-group/Dialogs";
import { EntityCard } from "@/mixins";

export default {
  mixins: [EntityCard],

  components: {
    EditGroup,
    EditPolicies,
    DeleteUserGroupDialog
  },

  data: () => ({
    childFetch: true,
    createNewTitle: "Новая группа пользователей",
    notFoundMessage: "Группа пользователей не найдена.",

    // API
    apiEntityName: "userGroup",
    apiFetchMethodName: "group",
    apiCreateMethodName: "createGroup",
    apiUpdateMethodName: "updateGroup",

    // Router
    routerIndexName: "UserGroupIndex",
    routerEditName: "UserGroupEdit",

    policyKey: "user_group_policy"
  }),

  computed: {
    tabs() {
      return [
        {
          index: 1,
          title: "Основное",
          component: "EditGroup"
        },
        {
          index: 2,
          title: "Доступы",
          component: "EditPolicies",
          props: {
            disabled: parseInt(this.$route.params.id) === 1
          }
        }
      ];
    },

    canRemove() {
      return (
        this.dbData &&
        !this.isCreateNew &&
        parseInt(this.$route.params.id) !== 1
      );
    },

    deletedMessage() {
      return `Группа пользователей «${this.dbData.title}» удалена.`;
    }
  },

  async mounted() {
    await this.fetch();
    this.prepareItem();
  },

  methods: {
    prepareItem() {
      if (this.isCreateNew) {
        this.editInfo = {
          title: "",
          policies: []
        };

        return;
      }

      this.editInfo = this.entityData;
    }
  }
};
</script>