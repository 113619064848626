<template>
  <div id="promocode">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
        <v-spacer />
        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>

    </v-card>

    <v-card>
      <v-container
        class="mb-2"
        fluid
      >
        <ui-filter
          :fields="filterFields"
          v-model="filter"
          @apply="filterApplyHandle"
        />
      </v-container>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      >
        <template v-slot:[`item.bonus`]="{ item }">
          {{item.bonus || 0}}
        </template>

        <template v-slot:[`item.start_validity_period_at`]="{ item }">
          {{vFormatDate(item.start_validity_period_at) || notSetText}}
        </template>

        <template v-slot:[`item.end_validity_period_at`]="{ item }">
          {{vFormatDate(item.start_validity_period_at) || notSetText}}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            v-if="userCanUpdate"
          >mdi-pencil</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="userCanDelete"
          >mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <delete-promocode-dialog
      v-model="deleteDialog"
      :entity-id="idForDelete"
      @deleted="handleDeleted()"
      v-if="userCanDelete"
    />
  </div>
</template>

<script>
import { UserOptions, Filterable, Sortable, EntityIndex } from "@/mixins";
import { DeletePromocodeDialog } from "@/components/promocode/Dialogs";
import { formatDateUnix, removeObservation } from "@/utils";
import { UiFilter } from "@/components/UI";

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [UserOptions, Filterable, Sortable, EntityIndex],

  components: {
    UiFilter,
    DeletePromocodeDialog
  },

  data: () => ({
    title: "Промокоды",
    notSetText: "Не задан",
    loading: false,
    total: 0,
    idForDelete: 0,
    deleteDialog: false,
    items: [],
    filterFields: [
      {
        label: "Промокод",
        id: "code",
        component: "VTextField"
      },
      {
        label: "Бонус",
        id: "bonus",
        component: "NumberInputRange"
      },
      {
        label: "Возм. кол-во активаций",
        id: "count_activated",
        component: "NumberInputRange"
      },
      {
        label: "Начало активности",
        id: "start_validity_period_at",
        component: "DatePickerRange"
      },
      {
        label: "Конец активности",
        id: "end_validity_period_at",
        component: "DatePickerRange"
      }
    ],
    policyKey: 'promocode_policy'
  }),

  computed: {
    userFilterKey() {
      return "promocode-filter";
    },

    userOptionsKey() {
      return "promocode-grid";
    },

    headers() {
      const headers = [
        { text: "#", value: "id" },
        { text: "Промокод", value: "code" },
        { text: "Бонус", value: "bonus" },
        { text: "Возм. кол-во активаций", value: "count_activated" },
        { text: "Начало активности", value: "start_validity_period_at" },
        { text: "Конец активности", value: "end_validity_period_at" }
      ];

      if (this.userCanUpdate || this.userCanDelete) {
        headers.push({
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;

      const input = {};

      const filter =
        this.getFilterInput(
          removeObservation(this.filter),
          this.getFilterMap(this.filterFields)
        ) || {};

      filter.type = "global";

      if (filter) {
        input.filter = filter;
      }

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      const result = await this.$api.promocode.promocodes(
        page,
        itemsPerPage,
        input
      );

      if (result.data.data.promocodes) {
        this.items = result.data.data.promocodes.data;
        this.total = result.data.data.promocodes.paginatorInfo.total;
      }

      this.loading = false;
    },

    add() {
      this.$router.push({ name: "PromocodeEdit", params: { id: 0 } });
    },

    edit(item) {
      this.$router.push({ name: "PromocodeEdit", params: { id: item.id } });
    },

    deleteItem(item) {
      this.idForDelete = item.id;
      this.deleteDialog = true;
    },

    handleDeleted() {
      this.fetch();
    },

    vFormatDate(date) {
      return formatDateUnix(date);
    },

    filterApplyHandle() {
      this.fetch();
    }
  }
};
</script>