<template>
  <div class="gym-viewer">
    <v-row v-if="items.length > 0">
      <v-col
        v-for="item in items"
        :key="item.id"
        md="4"
        sm="6"
        cols="12"
        class="d-flex"
      >
        <v-card
          color="blue-grey darken-4 d-flex flex-column align-stretch"
          width="100%"
        >
          <v-card-title>{{item.title}}</v-card-title>
          <v-card-text
            class="pb-0"
            v-if="getChips(item).length > 0"
          >
            <v-chip
              color="amber"
              small
              outlined
              class="mr-1 mb-1"
              v-for="(chip, index) in getChips(item)"
              :key="index"
            >{{chip}}</v-chip>
          </v-card-text>
          <v-card-text>Стоимость: {{item.price}}</v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            {{item.address}}
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="open(item)"
            >
              Открыть
              <v-icon
                right
                v-if="newWindow"
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div
      class="text-center"
      v-else
    >
      {{noItemsText}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },

    noItemsText: {
      type: String,
      default: 'Нет элементов.'
    },

    newWindow: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getChips(item) {
      let chips = [];

      if (item.active) {
        chips.push('Активен');
      }

      if (item.around_the_clock) {
        chips.push('Круглосуточно');
      }

      if (item.maintenance) {
        chips.push('Техническое обслуживание');
      }

      if (item.has_shower) {
        chips.push('Есть душ');
      }

      return chips;
    },

    open(item) {
      if (this.newWindow) {
        let routeData = this.$router.resolve({
          name: 'GymEdit',
          params: { id: item.id }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'GymEdit', params: { id: item.id } });
      }
    }
  }
};
</script>