import axios from 'axios';
import { generateMutationFormData, removeObservation } from '@/utils';
export default {
  /**
   * Получить список продуктов.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */

  async productsList(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            products(page: ${page}, first: ${itemsPerPage}) {
              data {
                  id
                  title
                  description
                  short_description
                  may_contain
                  composition 
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },
    async product(id) {
        return await axios.post(
            process.env.VUE_APP_GRAPHQL_URL,
            {
                query: `
                  query {
                    product(id: ${id}) {
                      id
                      title
                      description
                      short_description
                      may_contain
                      composition
                      parameters {
                          id
                          product_id
                          type
                          title
                          value
                          sort
                      }
                       images {
                          id
                          src
                          url
                          name
                          mime
                          ext
                          created_at
                          updated_at
                        }
                    }
                  }`,
                variables: {
                    id
                }
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        );
    },
    async updateProduct(currentInput, input, files, fileFields) {


        const formData = getFormData(
            'updateProduct',
            currentInput,
            input,
            files,
            fileFields
        );
        return await axios.post(
            process.env.VUE_APP_GRAPHQL_URL,
            formData,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        )
    }
};

function getFormData(
    mutationName,
    currentInput,
    input,
    files = {},
    fileFields = {},
    additionalReturnsFields = []
) {

    input = removeObservation(input);

    // Параметры мутации
    let mutationParams = [
        '$title: String!',
        '$description: String',
        '$short_description: String',
        '$may_contain: String',
        '$composition: String',
        '$parameters: UpdateProductParameters'
    ];

    // Параметры метода
    let methodParams = [
        'title: $title',
        'description: $description',
        'short_description: $short_description',
        'may_contain: $may_contain',
        'composition: $composition',
        'parameters: $parameters'
    ];

    const returnFields = [
        'id',
        'title',
        'description',
        'short_description',
        'may_contain',
        'composition',
        'parameters { id, product_id, type, title, value, sort}',
        'images { id, src, url, name, mime }'
    ].concat(additionalReturnsFields);

    if (input.id) {
        mutationParams.push('$id: ID!');
        methodParams.push('id: $id');
    }

    return generateMutationFormData(
        mutationName,
        currentInput,
        input,
        methodParams,
        mutationParams,
        returnFields,
        files,
        fileFields
    );
}
