<template>
  <div class="file-viewer__data-table">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="optionsLocal"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :items-per-page="itemsPerPage"
      :footer-props="footerProps"
      disable-sort
      :single-select="!multiple"
      v-model="selectedItemsLocal"
      :show-select="selectionMode"
    >
      <template v-slot:[`item.preview`]="{ item }">
        <v-card
          height="70"
          width="70"
          class="file-item ma-3"
          flat
          style="overflow: hidden;"
        >
          <v-sheet
            :color="getFileColor(item)"
            height="100%"
            width="70"
            class="file-item__sheet d-flex align-center justify-center"
          >
            <v-hover v-if="isImage(item)">
              <template v-slot:default="{ hover }">
                <v-img
                  :src="item.url"
                  aspect-ratio="1"
                  v-if="isImage(item)"
                  class="grey darken-3"
                  @click="lightBoxIndex = getImageIndex(item.id)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="secondary"
                      style="cursor: pointer;"
                    >
                      <v-icon size="32">mdi-magnify-plus-outline</v-icon>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </template>
            </v-hover>

            <v-icon v-else>{{getFileIcon(item)}}</v-icon>
          </v-sheet>
        </v-card>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{getFileName(item.name)}}
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{vFormatDate(item.created_at)}}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="handleClick(item)"
        >mdi-pencil</v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >mdi-delete</v-icon>
      </template>

      <template v-slot:no-data>
        <v-container>
          <div
            class="d-flex justify-center align-center"
            style="min-height: 100px;"
          >
            <span v-if="searchMessage === ''">Файлы не найдены.</span>
            <span v-else>По запросу «{{searchMessage}}» ничего не найдено.</span>

          </div>
        </v-container>
      </template>
    </v-data-table>

    <delete-files-dialog
      v-model="deleteFileDialog"
      :items="itemForDeletion"
      description-text="Вы действительно хотите удалить файл?"
      @deleted="handleDeleted()"
    />

    <CoolLightBox
      :items="lightBoxItems"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import view from './mixin/view';
import { DeleteFilesDialog } from '../Dialogs';
import { formatDateUnix } from '@/utils';

export default {
  mixins: [view],

  components: {
    DeleteFilesDialog
  },

  data: () => ({
    itemForDeletion: [],
    deleteFileDialog: false,
    lightBoxIndex: null
  }),

  computed: {
    headers() {
      let headers = [
        { value: 'preview' },
        { text: 'Название', value: 'name' },
        { text: 'Расширение', value: 'ext' },
        { text: 'Дата добавления', value: 'created_at' }
      ];

      if (!this.selectionMode) {
        headers.push({ text: 'Действия', value: 'actions', align: 'end' });
      }

      return headers;
    },

    lightBoxItems() {
      if (!this.images) return [];
      return this.images.map((image) => ({
        title: image.name,
        src: image.url
      }));
    },

    images() {
      if (!this.items) return [];
      return this.items.filter((file) => this.isImage(file));
    },

    imagesMap() {
      const map = {};

      if (!this.images) return map;

      this.images.forEach((image, index) => {
        map[image.id] = index;
      });

      return map;
    }
  },

  methods: {
    deleteItem(item) {
      this.itemForDeletion.push(item);
      this.deleteFileDialog = true;
    },

    handleDeleted() {
      this.itemForDeletion = [];
      this.$emit('deleted');
    },

    getFileName(x) {
      return x.replace(/\.[^/.]+$/, '');
    },

    getImageIndex(id) {
      return this.imagesMap[id];
    },

    vFormatDate(date) {
      return formatDateUnix(date);
    }
  }
};
</script>
