export default {
  methods: {
    getSortInput(sortBy, sortDesc) {
      if (sortBy.length === 0) {
        return null;
      }

      const result = [
        {
          column: sortBy[0],
          order: sortDesc[0] ? 'DESC' : 'ASC'
        }
      ];

      return result;
    }
  }
};
