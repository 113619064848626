import { sortedUniq } from 'lodash';

const getters = {
  isAuthorized(state) {
    return state.isAuthorized;
  },

  id(state) {
    return state.id;
  },

  fullName(state) {
    return `${state.firstName} ${state.lastName}`.trim();
  },

  groups(state) {
    return state.groups;
  },

  policies(state) {
    let policies = [];

    state.groups.forEach((g) => (policies = policies.concat(g.policies)));

    return sortedUniq(policies);
  }
};

export default getters;
