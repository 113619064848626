import axios from 'axios';
import { clearPhone, removeObservation } from '@/utils';

export default {
  /**
   * Получение логов SMS.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов
   * @param {*} input Параметры фиьтрации и сортировки
   */
  async smsLogs(page, itemsPerPage, input = null) {
    input = removeObservation(input);

    if (input.filter.phone) {
      input.filter.phone = clearPhone(input.filter.phone);
    }

    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: SmsLogListInput) {
            smsLogs(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                phone
                status
                meta
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
