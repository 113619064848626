<template>
  <div class="user-profile">
    <v-card :loading="loadingState" class="mb-3">
      <v-card-title>
        {{ cardTitle }}
        <v-tooltip bottom v-if="verified">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" color="success" v-bind="attrs" v-on="on">mdi-check</v-icon>
          </template>
          {{ getUserStatusText(USER_STATUS_VERIFIED) }}
        </v-tooltip>
      </v-card-title>

      <v-card-text v-if="!isCreateNew && entityData">
        <user-account v-if="dbData" :user-id="dbData.id" :account="dbData.account"
          @create-transaction="createTransactionHandle" />
      </v-card-text>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert v-if="isCreateNew && !userCanCreate" type="error">
        Нет доступа.
      </v-alert>

      <v-card v-else-if="entityData" class="mb-3">
        <v-tabs v-model="currentTab" show-arrows>
          <v-tabs-slider />

          <v-tab v-for="(tab, index) in tabs" :href="`#tab-${index}`" :key="index" :disabled="!tab.component">
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab" class="mt-3">
          <v-tab-item v-for="(tab, index) in tabs" :key="index" :value="`tab-${index}`">
            <v-card flat>
              <v-card-text>
                <component ref="tabContent" :is="tab.component" :entity-data="entityData" v-bind="tab.props"
                  v-model="editInfo" @validation-update="validationErrors.input = $event"
                  @file-history-click="fileHistoryClick" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions :saving="saving" :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate" @save="saveItem" @saveAndClose="saveAndClose"
        @close="close" v-if="entityData" />
    </v-scroll-y-reverse-transition>

    <file-history-dialog v-model="fileHistoryDialog" :items="fileHistoryItems" />
  </div>
</template>

<script>
import {
  EditFormUser,
  EditFormGroups,
  EditFormTransactions,
  EditFavoriteGyms,
  EditWorkouts
} from "@/components/user/edit";
import {
  ViewWorkouts
} from "@/components/user/view";
import { EntityCard, UserStatus } from "@/mixins";
import { mapGetters } from "vuex";
import { FileHistoryDialog } from "@/components/user/Dialogs";
import UserAccount from "@/components/user/UserAccount";

export default {
  name: "edit",

  mixins: [EntityCard, UserStatus],

  components: {
    EditFormUser,
    EditFormGroups,
    EditFormTransactions,
    EditFavoriteGyms,
    EditWorkouts,
    FileHistoryDialog,
    UserAccount,
    ViewWorkouts
  },

  data: () => ({
    changeAccountDialog: false,
    fileHistoryItems: [],
    fileHistoryDialog: false,
    useFormData: true,

    /**
     * Поля, в которых содержатся файлы.
     */
    fileFields: {
      passportFront: "connect",
      passportSelfie: "connect",
      sign: "connect"
    },
    createNewTitle: "Новый пользователь",
    notFoundMessage: "Пользователь не найден.",

    // API
    apiEntityName: "user",
    apiFetchMethodName: "user",
    apiCreateMethodName: "createUser",
    apiUpdateMethodName: "updateUser",

    // Router
    routerIndexName: "UserIndex",
    routerEditName: "UserEdit",

    policyKey: "user_policy"
  }),

  computed: {
    ...mapGetters("user", {
      meId: "id"
    }),

    tabs() {
      return [
        {
          title: "Основное",
          component: "EditFormUser",
          props: {
            fieldErrors: this.validationErrors.input
          }
        },
        {
          title: "Избранные залы",
          component: "EditFavoriteGyms"
        },
        {
          title: "Тренировки",
          component: "EditWorkouts"
        },
        {
          title: "Транзакции",
          component: "EditFormTransactions"
        },
        {
          title: "Группы доступа",
          component: "EditFormGroups"
        },
        {
          title: "Бронирование",
          component: "ViewWorkouts"
        }
      ];
    },

    userFullName() {
      let name = "";

      if (this.dbData.first_name) {
        name = this.dbData.first_name;
      }

      if (this.dbData.second_name) {
        name += ` ${this.dbData.second_name}`;
      }

      name = name.trim();

      if (name === "") {
        name = "Без имени";
      }

      return name;
    },

    cardTitle() {
      if (this.isCreateNew) return this.createNewTitle;
      return this.dbData ? this.userFullName : "Загрузка...";
    },

    entityData() {
      return this.isCreateNew ? { status: this.USER_STATUS_NEW } : this.dbData;
    },

    isMe() {
      return parseInt(this.meId) === parseInt(this.$route.params.id);
    },

    verified() {
      return this.entityData
        ? this.entityData.status === this.USER_STATUS_VERIFIED
        : false;
    }
  },

  methods: {
    async fetch() {
      if (this.isCreateNew) return;

      this.loading = true;

      const result = await this.$api[this.apiEntityName][
        this.apiFetchMethodName
      ](this.$route.params.id);

      if (!result.data.data[this.apiEntityName]) {
        if (!this.isCreateNew) {
          this.notifyError(this.notFoundMessage);
          this.close();
        }
      } else {
        this.dbData = result.data.data[this.apiEntityName];
        this.title = this.cardTitle;
        this.prepareUserPhone();
      }

      this.loading = false;
    },

    async saveFormData() {
      if (!(await this.validateAll())) {
        this.showValidationNotify();

        return false;
      }

      this.loading = true;

      const { requestData, files } = this.prepareRequestData(this.editInfo);
      const apiMethod = this.isCreateNew
        ? this.apiCreateMethodName
        : this.apiUpdateMethodName;

      let result = null;

      if (this.isCreateNew) delete requestData.id;
      else requestData.id = this.$route.params.id;

      result = await this.$api[this.apiEntityName][apiMethod](
        this.entityData,
        requestData,
        files,
        this.fileFields
      );

      if (!result || result.data.errors) {
        this.loading = false;

        this.setValidationErrors(result.data.errors);
        this.showValidationNotify(true);

        return false;
      }

      this.notifySuccess("Данные успешно сохранены.");

      const _entityData = result.data.data[apiMethod];

      if (this.isCreateNew) {
        this.dbData = {};
      }

      this.dbData = Object.assign(this.dbData, _entityData);
      this.prepareUserPhone();

      if (this.isMe) {
        this.$store.dispatch("user/updateInfo", _entityData);
      }

      this.title = this.cardTitle;

      this.loading = false;

      return true;
    },

    createTransactionHandle(data) {
      this.notifySuccess("Баланс пользователя успешно изменен.");
      this.$set(this.dbData, "account", data[this.apiEntityName].account);
      this.$set(
        this.dbData,
        "transactions",
        data[this.apiEntityName].transactions
      );
      this.refreshData();
    },

    prepareUserPhone() {
      // Удаляем первый символ, чтобы номер телефона корректно внедрился в маску
      this.dbData.phone = this.dbData.phone.substring(1);
    },

    fileHistoryClick(id) {
      this.setFileHistoryItems(id);
      this.fileHistoryDialog = true;
    },

    setFileHistoryItems(id) {
      this.fileHistoryItems = this.dbData[id] || [];
    }
  }
};
</script>
