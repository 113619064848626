const mutations = {
  AUTHORIZE_USER(state, payload) {
    state.isAuthorized = true;
    state.id = payload.id;
  },

  DEAUTHORIZE_USER(state) {
    state.isAuthorized = false;
    state.id = null;
    state.firstName = null;
    state.lastName = null;
    state.groups = [];
  },

  UPDATE_USER_INFO(state, payload) {
    state.firstName = payload.first_name;
    state.lastName = payload.last_name;
  },

  SET_GROUPS(state, payload) {
    state.groups = payload;
  }
};

export default mutations;
