<template>
  <div id="file-view">
    <v-card
      :loading="loadingState"
      class="mb-3"
    >
      <v-card-title>
        {{cardTitle}}
        <v-spacer />

        <template v-if="file && userCanDelete">
          <v-btn
            color="error"
            @click="confirmDeleteDialog = true"
          >Удалить</v-btn>
        </template>
      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-card
        v-if="file"
        class="mb-3"
      >
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-card
                :color="getFileColor(file)"
                width="250"
                height="250"
              >
                <v-hover v-if="isImage(file)">
                  <template v-slot:default="{ hover }">
                    <v-img
                      :src="file.url"
                      aspect-ratio="1"
                      class="grey darken-3"
                      @click="lightBoxIndex = 0"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>

                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="secondary"
                          style="cursor: pointer;"
                        >
                          <v-icon size="64">mdi-magnify-plus-outline</v-icon>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </template>
                </v-hover>

                <v-card-text
                  class="fill-height"
                  v-else
                >
                  <v-row
                    class="fill-height ma-0 flex-column"
                    align="center"
                    justify="center"
                  >
                    <div class="mb-1">
                      <v-icon>{{getFileIcon(file)}}</v-icon>
                    </div>
                    <span
                      style="max-width: 100%;"
                      class="d-inline-block text-truncate"
                    >{{file.name}}</span>
                  </v-row>
                </v-card-text>
              </v-card>

              <div class="file-meta ma-6">
                <div class="file-meta__item">
                  Дата добавления: {{vFormatDate(file.created_at)}}
                </div>
                <div class="file-meta__item">
                  Название: {{file.name}}
                </div>
                <div class="file-meta__item">
                  Расширение: {{file.ext}}
                </div>
                <div class="file-meta__item">
                  MIME-тип: {{file.mime}}
                </div>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <v-card
        v-if="file"
        :disabled="loadingState"
      >
        <v-card-text>
          <v-btn
            color="secondary"
            @click="close()"
          >Закрыть</v-btn>
        </v-card-text>
      </v-card>
    </v-scroll-y-reverse-transition>

    <delete-file-dialog
      v-model="confirmDeleteDialog"
      :entity-id="$route.params.id"
      @deleted="deletedHandle()"
      v-if="userCanDelete"
    />

    <CoolLightBox
      v-if="file && isImage(file)"
      :items="lightBoxItem"
      :index="lightBoxIndex"
      @close="lightBoxIndex = null"
      :slideshow="false"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import { Notify, File, EntityCard } from '@/mixins';
import { formatDateUnix } from '@/utils';
import { DeleteFileDialog } from '@/components/file/Dialogs';

export default {
  name: 'file-view',

  mixins: [Notify, File, EntityCard],

  components: {
    DeleteFileDialog
  },

  metaInfo() {
    return {
      title: this.cardTitle
    };
  },

  data: () => ({
    loading: false,
    confirmDeleteDialog: false,
    file: null,
    lightBoxIndex: null,
    useAPI: false,
    policyKey: "file_policy"
  }),

  computed: {
    loadingState() {
      return !this.file || this.loading;
    },

    lightBoxItem() {
      if (!this.isImage(this.file)) return [];

      return [{ title: this.file.name, src: this.file.url }];
    },

    cardTitle() {
      return this.file ? this.file.name : 'Загрузка...';
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.loading = true;

      const result = await this.$api.file.file(this.$route.params.id);

      if (result.data.errors || !result.data.data.file) {
        this.notifyError(`Файл не найден.`);
        this.close();
      }

      this.file = result.data.data.file;
      this.title = this.file.name;

      this.loading = false;
    },

    deletedHandle() {
      this.notifySuccess(`Файл «${this.file.name}» удален.`);
      this.close();
    },

    close() {
      this.$router.push({ name: 'FileIndex' });
    },

    vFormatDate(date) {
      return formatDateUnix(date, 'DD.MM.YYYY HH:mm:ss');
    }
  }
};
</script>
