import axios from 'axios';

export default {
  /**
   * Получение списка автоматов.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async machines(page, itemsPerPage, input = null) {
    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, {
      query: `
        query($input: IndexMachineInput) {
          machines(page: ${page}, first: ${itemsPerPage}, input: $input) {
            data {
              id
              serial_number
              gym {
                id
                title
              }
            }
          }
        }`,
      variables: {
        input
      }
    });
  },

  /**
   * Создание автомата.
   *
   * @param {Object} input Новые данные
   */
  async createMachine(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreateMachineInput!) {
            createMachine(input: $input) {
              id
              serial_number
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление автомата.
   *
   * @param {Object} input Новые данные
   */
  async updateMachine(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: UpdateMachineInput!) {
            updateMachine(input: $input) {
              id
              serial_number
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Удаление автомата.
   *
   * @param {Number|String} id ID автомата
   */
  async deleteMachine(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteMachine(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
