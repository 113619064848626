import auth from './auth';
import config from './config';
import faq from './faq';
import file from './file';
import gym from './gym';
import gymEquipments from './gymEquipments';
import gymProblem from './gymProblem';
import logs from './logs';
import machine from './machine';
import news from './news';
import promocode from './promocode';
import products from './products';
import transaction from './transaction';
import user from './user';
import userGroup from './userGroup';
import workout from './workout';

export default {
  auth,
  config,
  faq,
  file,
  gym,
  gymEquipments,
  gymProblem,
  logs,
  machine,
  news,
  promocode,
  products,
  transaction,
  user,
  userGroup,
  workout
};
