import axios from 'axios';

export default {
  /**
   * Получить список доступов.
   */
  async policies() {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            policies
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить список групп пользователей.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   */
  async groups(page, itemsPerPage) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            groups(page: ${page}, first: ${itemsPerPage}) {
              data {
                  id
                  title
              }
              paginatorInfo {
                  total
              }
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить группу пользователей по ID.
   *
   * @param {Number|String} id ID группы пользователей
   */
  async group(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            group(id: ${id}) {
              id
              title
              policies
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание группы пользователей.
   *
   * @param {Object} input Новые данные
   */
  async createGroup(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreateGroupInput!) {
            createGroup(input: $input) {
              id
              title
              policies
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление группы пользователей.
   *
   * @param {Object} input Новые данные
   */
  async updateGroup(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: UpdateGroupInput!) {
            updateGroup(input: $input) {
              id
              title
              policies
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Удаление группы пользователей.
   *
   * @param {Number|String} id ID группы пользователей
   */
  async deleteGroup(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteGroup(id: $id) {
              id
              title
              policies
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
