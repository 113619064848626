<template>
  <v-card :disabled="loadingState">
    <v-card-text>
      <v-btn
        color="success"
        @click="save()"
        :disabled="loadingState"
        :loading="saving"
        :block="$vuetify.breakpoint.smAndDown"
        v-if="canSave"
      >Сохранить</v-btn>
      <v-btn
        color="secondary"
        :class="{'ml-3': !$vuetify.breakpoint.smAndDown, 'mt-3': $vuetify.breakpoint.smAndDown}"
        @click="saveAndClose()"
        :disabled="loadingState"
        :block="$vuetify.breakpoint.smAndDown"
        v-if="canSave"
      >Сохранить и закрыть</v-btn>
      <v-btn
        color="secondary"
        :class="{'ml-3': !$vuetify.breakpoint.smAndDown && canSave, 'mt-3': $vuetify.breakpoint.smAndDown}"
        @click="close()"
        :disabled="loadingState"
        :block="$vuetify.breakpoint.smAndDown"
      >Закрыть</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "entityCardActions",

  props: {
    loadingState: {
      type: Boolean,
      default: false
    },

    canSave: {
      type: Boolean,
      default: true
    },

    saving: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    save() {
      this.$emit("save");
    },

    saveAndClose() {
      this.$emit("saveAndClose");
    },

    close() {
      this.$emit("close");
    }
  }
};
</script>