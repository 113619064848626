import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/auth/login';
import UserIndex from '../views/user/index';
import UserEdit from '../views/user/edit';
import FileIndex from '../views/file/index';
import FileView from '../views/file/view';
import GymIndex from '../views/gym/index';
import GymEdit from '../views/gym/edit';
import GymProblemIndex from '../views/gym-problem/index';
import GymProblemEdit from '../views/gym-problem/edit';
import UserGroupIndex from '../views/user-group/index';
import UserGroupEdit from '../views/user-group/edit';
import LogSmsIndex from '../views/log/sms/index';
import ProductsIndex from '../views/products/index';
import ProductsEdit from '../views/products/edit';
import PromocodeIndex from '../views/promocode/index';
import PromocodeEdit from '../views/promocode/edit';
import FaqIndex from '../views/faq/index';
import FaqEdit from '../views/faq/edit';
import NewsIndex from '../views/news/index';
import NewsEdit from '../views/news/edit';
import ConfigIndex from '../views/config/index';
import ConfigEdit from '../views/config/edit';
import AccessDeniedIndex from '../views/access-denied/index';
import WorkoutsIndex from '../views/workout/index.vue';
import Meta from 'vue-meta';
import api from '../api';
import Store from '../store';
import routerPolicyUrl from './routerPolicyUrl';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user',
    name: 'UserIndex',
    component: UserIndex
  },
  {
    path: '/user/:id',
    name: 'UserEdit',
    component: UserEdit
  },
  {
    path: '/user-group',
    name: 'UserGroupIndex',
    component: UserGroupIndex
  },
  {
    path: '/user-group/:id',
    name: 'UserGroupEdit',
    component: UserGroupEdit
  },
  {
    path: '/gym',
    name: 'GymIndex',
    component: GymIndex
  },
  {
    path: '/gym/:id',
    name: 'GymEdit',
    component: GymEdit
  },
  {
    path: '/gym-problem',
    name: 'GymProblemIndex',
    component: GymProblemIndex
  },
  {
    path: '/gym-problem/:id',
    name: 'GymProblemEdit',
    component: GymProblemEdit
  },
  {
    path: '/news',
    name: 'NewsIndex',
    component: NewsIndex
  },
  {
    path: '/news/:id',
    name: 'NewsEdit',
    component: NewsEdit
  },
  {
    path: '/file',
    name: 'FileIndex',
    component: FileIndex
  },
  {
    path: '/file/:id',
    name: 'FileView',
    component: FileView
  },
  {
    path: '/products/',
    name: 'ProductsIndex',
    component: ProductsIndex
  },
  {
    path: '/products/:id',
    name: 'ProductsEdit',
    component: ProductsEdit
  },
  {
    path: '/promocode',
    name: 'PromocodeIndex',
    component: PromocodeIndex
  },
  {
    path: '/promocode/:id',
    name: 'PromocodeEdit',
    component: PromocodeEdit
  },
  {
    path: '/config',
    name: 'ConfigIndex',
    component: ConfigIndex
  },
  {
    path: '/config/:id',
    name: 'ConfigEdit',
    component: ConfigEdit
  },
  {
    path: '/faq',
    name: 'FaqIndex',
    component: FaqIndex
  },
  {
    path: '/faq/:id',
    name: 'FaqEdit',
    component: FaqEdit
  },
  {
    path: '/log-sms',
    name: 'LogSmsIndex',
    component: LogSmsIndex
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDeniedIndex
  },
  {
    path: '/workouts',
    name: 'WorkoutsIndex',
    component: WorkoutsIndex
  }
];

const defaultRoute = {
  path: '*',
  beforeEnter: (to, from, next) => {
    const policies = Store.getters['user/policies'];

    if (policies.length > 0) {
      let path = '/login';

      for (const route of routes) {
        const rpu = routerPolicyUrl.find((r) => r.name === route.name);

        if (rpu) {
          for (const p of rpu.policies) {
            if (p.includes('view') && policies.includes(p)) {
              path = route.path;
              break;
            }
          }
        }

        if (path !== '/login') {
          break;
        }
        // if (
        //   routerPolicyUrl[route.name] &&
        //   policies.includes(routerPolicyUrl[route.name])
        // ) {
        //   path = route.path;
        //   break;
        // }
      }

      try {
        next({ path });
        // eslint-disable-next-line no-empty
      } catch (err) { }
    } else {
      Store.dispatch('user/logout');
      next({ path: '/login' });
    }
  }
};

routes.push(defaultRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  const policies = Store.getters['user/policies'];

  if (policies.length > 0) {
    if (to.name && to.name !== 'AccessDenied') {
      const rpu = routerPolicyUrl.find((r) => r.name === to.name);

      if (rpu) {
        let isAccess = false;

        for (const p of rpu.policies) {
          if (policies.includes(p)) {
            isAccess = true;
            break;
          }
        }

        if (!isAccess) {
          router.push({ path: '/access-denied' });
          return false;
        }
      } else {
        router.push({ path: '/access-denied' });
        return false;
      }
    }

    next();
    return;
  } else {
    // Store.dispatch('user/logout');
    // next({ path: '/login' });
    // return;
  }

  next();
});

router.beforeEach(async (to, from, next) => {
  const result = await api.user.me();

  if (result.data.errors) {
    if (Store.getters['user/isAuthorized']) {
      Store.dispatch('user/logout');
    }

    if (to.name === 'Login') {
      next();
      return;
    }

    router.push({ name: 'Login' });
    next();
    return;
  } else {
    if (to.name === 'Login') {
      router.push('/');
      return;
    }
  }

  if (!Store.getters['user/isAuthorized']) {
    Store.dispatch('user/login', result.data.data.me);

    if (result.data.data.me.groups.length > 0) {
      Store.dispatch('user/setGroups', result.data.data.me.groups);
    }
  }

  if (to.name === 'AccessDenied') {
    next();
    return;
  }

  const policies = Store.getters['user/policies'];

  if (policies.length === 0) {
    router.push({ path: '/access-denied' });
    return false;
  }

  next();
});

export default router;
