import { find } from 'lodash';
import { removeObservation } from '@/utils';
import { required, email } from 'vee-validate/dist/rules';
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate';
import Notify from './Notify';
import moment from 'moment';

setInteractionMode('aggressive');

extend('required', {
  ...required,
  message: 'Поле не может быть пустым.'
});

extend('email', {
  ...email,
  message: 'Email должен быть корректным.'
});

extend('beforeOrEqual', {
  message: (field, params) => {
    if (params[1]) {
      return `Значение должно быть меньше или равняться "${params[1]}"`;
    } else if (params[0]) {
      return `Значение должно быть меньше или равняться "${params[0]}"`;
    }

    return 'Поле заполнено некорректно';
  },
  validate: (value, args) => {
    if (args.length > 0) {
      const id = args[0];

      if (id) {
        const el = document.getElementById(id);

        if (el) {
          const elValue = el.value;

          if (elValue !== '') {
            const timestamp = moment(elValue, 'DD.MM.YYYY').unix();

            return value <= timestamp;
          }
        }
      }
    }

    return true;
  }
});

extend('afterOrEqual', {
  message: (field, params) => {
    if (params[1]) {
      return `Значение должно быть больше или равняться "${params[1]}"`;
    } else if (params[0]) {
      return `Значение должно быть больше или равняться "${params[0]}"`;
    }

    return 'Поле заполнено некорректно';
  },
  validate: (value, args) => {
    if (args.length > 0) {
      const id = args[0];

      if (id) {
        const el = document.getElementById(id);

        if (el) {
          const elValue = el.value;

          if (elValue !== '') {
            const timestamp = moment(elValue, 'DD.MM.YYYY').unix();

            return value >= timestamp;
          }
        }
      }
    }

    return true;
  }
});

extend('notZero', {
  message: 'Значение не должно быть 0.',
  validate: value => {
    return parseInt(value) !== 0;
  }
});

export default {
  mixins: [Notify],

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    fieldErrors: {
      type: Object,
      default: () => ({})
    },

    /** @var Провести валидацию после инициализации компонента */
    initValidate: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    validationErrors: {}
  }),

  mounted() {
    this.$nextTick(() => {
      if (this.initValidate && this.$refs.observer) {
        this.$refs.observer.validate();
      }
    });

    // $bus - это общая шина
    this.$bus.$on('validate', async () => {
      this.resetValidation(this);
      const result = await this.validate(this);

      if (!this.$bus.validationError && !result) {
        this.$bus.validationError = true;
      }
    });
  },

  beforeDestroy() {
    this.$bus.$off('validate');
  },

  methods: {
    async validateAll() {
      this.$bus.validationError = false;

      // Ждем когда выполнятся все события
      for (const validate of this.$bus._events.validate) {
        await validate();
      }

      // $bus.validationError - глобальная переменная.
      // Если хоть в одном компоненте будет ошибка валидации, то возвращаем false.
      return !this.$bus.validationError;
    },

    setValidationErrors(errors = []) {
      const validationExtensions = this.getValidationExtensions(errors);

      if (validationExtensions) {
        this.$set(
          this,
          'validationErrors',
          this.getErrors(validationExtensions.extensions.validation)
        );
      }
    },

    getValidationExtensions(errors = []) {
      return find(
        errors,
        error => error.extensions && error.extensions.category === 'validation'
      );
    },

    getErrors(data) {
      let errors = {};

      for (let prop in data) {
        const errorGroup = prop.substr(0, prop.indexOf('.'));
        const errorId = prop.substr(prop.indexOf('.') + 1);

        if (errorGroup) {
          if (!errors[errorGroup]) {
            errors[errorGroup] = {};
          }

          errors[errorGroup][errorId] = data[prop];
        } else {
          errors[errorId] = data[prop];
        }
      }

      return errors;
    },

    removeFieldError(id) {
      let fieldErrors = removeObservation(this.fieldErrors);

      if (fieldErrors[id]) {
        delete fieldErrors[id];
      }

      this.$emit('validation-update', fieldErrors);
    },

    getErrorMessage(veeValidateError, serverErrors = []) {
      if (!serverErrors) {
        serverErrors = [];
      }

      return veeValidateError.length > 0 ? veeValidateError : serverErrors;
    },

    async validate(item) {
      if (!item.$refs.observer) {
        return true;
      }

      return await item.$refs.observer.validate();
    },

    async validateItems(items = []) {
      let valid = true;

      for (const item of items) {
        const result = await this.validate(item);

        if (!result) {
          valid = result;
          break;
        }
      }

      return valid;
    },

    resetValidation(item) {
      this.$bus.validationError = false;
      if (!item.$refs.observer) return;
      item.$refs.observer.reset();
    },

    async resetValidationItems(items = []) {
      for (const item of items) {
        this.resetValidation(item);
      }
    },

    showValidationNotify(checkErrors = false) {
      if (checkErrors && !Object.keys(this.validationErrors) === 0) {
        return;
      }

      this.notifyError('Некоторые поля заполнены некорректно.');
    }
  }
};


extend('ip', {
  validate(value) {
    if (value) {
      return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);
    }

    return false;
  },
  message: 'IP адрес должен быть корректным.'
});
