<template>
  <div id="delete-news-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title>
          {{title}}
        </v-card-title>

        <v-card-text>
          {{text}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteEntity"
            :loading="loading"
          >
            Удалить
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
            :disabled="loading"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DeleteDialog } from '@/mixins';

export default {
  name: 'delete-news-dialog',

  mixins: [DeleteDialog],

  data: () => ({
    text: 'Вы действительно хотите удалить новость?',

    // API
    apiEntityName: 'news',
    apiDeleteMethodName: 'deleteNews'
  })
};
</script>