<template>
  <div class="ui-filter">
    <v-menu :close-on-click="false" :close-on-content-click="false" v-model="menu" :max-height="maxHeight">
      <template v-slot:activator="{ on, attrs }">
        <v-combobox ref="uiFilterCombobox" class="ui-filter-combobox" solo-inverted flat prepend-inner-icon="mdi-magnify"
          :label="label" v-bind="attrs" v-model="items" v-on="on" chips deletable-chips multiple hide-no-data
          return-object hide-details tabindex="-1" @keydown.stop="(event) => event.preventDefault()"
          @focus="clickComboboxHandle">
          <template v-slot:selection="{ parent, attrs, item, selected, disabled }">
            <v-chip :key="JSON.stringify(item)" v-bind="attrs" :input-value="selected" :disabled="disabled" close
              color="primary" @click.stop="chipClickHandle" @click:close="closeChipClickHandle(parent, item)">
              <template v-if="!$slots[item.id] && !$scopedSlots[item.id]">
                <template v-if="rangeComponents.includes(item.component)">
                  <strong>{{ item.label }}:</strong><span class="ml-1">{{ item.value.join(' ~ ') }}</span>
                </template>
                <template v-else>
                  <strong>{{ item.label }}:</strong><span class="ml-1">{{ item.valueLabel }}</span>
                </template>
              </template>

              <slot :name="item.id" v-bind:item="item"></slot>
            </v-chip>
          </template>
        </v-combobox>
      </template>

      <v-card color="grey darken-3" :max-height="maxHeight">
        <v-card-title class="pb-0">
          {{ label }}
        </v-card-title>

        <v-card-text :style="{ overflow: 'auto', maxHeight: '304px' }">
          <v-form @submit.prevent="applyFilter()" autocomplete="off">
            <template v-for="(field, index) in fields">
              <component class="mt-3" htmlClass="mt-3" hide-details :is="field.component" :key="index"
                v-model="tmpValue[field.id]" :label="field.label" :name="vGenerateId()" list="autocompleteOff" outlined
                clearable autocomplete="off" v-bind="field.props" />
            </template>

            <v-btn type="submit" v-show="false">submit</v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="applyFilter()">Применить</v-btn>
          <v-btn text @click="closeMenu()">Закрыть</v-btn>
          <v-spacer />
          <v-btn text @click="clearFilter()">Очистить</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { VTextField, VSelect, VCheckbox } from "vuetify/lib";
import { removeObservation, generateId } from "@/utils";
import { compact, find, pickBy, identity, size } from "lodash";
import {
  DatePickerRange,
  DatePicker,
  NumberInputRange,
  NumberInput
} from "@/components/UI";
import { UserStatus } from "@/mixins";
import moment from "moment";

export default {
  name: "ui-filter",

  mixins: [UserStatus],

  components: {
    VTextField,
    VSelect,
    VCheckbox,
    DatePicker,
    DatePickerRange,
    NumberInputRange,
    NumberInput
  },

  props: {
    label: {
      type: String,
      default: "Поиск"
    },

    fields: {
      type: Array,
      default: () => []
    },

    value: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    tmpValue: {},
    menu: false,
    items: [],
    maxHeight: "420",
    rangeComponents: ["DatePickerRange", "NumberInputRange"]
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      }
    }
  },

  watch: {
    menu(value) {
      if (value) {
        this.tmpValue = removeObservation(this.localValue);
        setTimeout(() => {
          this.blurCombobox();
        });
      } else {
        this.blurCombobox();
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.setItems(this.localValue);
    });
  },

  methods: {
    clickComboboxHandle() {
      this.menu = true;
    },

    applyFilter() {

      // Удаляем null и undefined
      this.tmpValue = this.sanitizeValue(this.tmpValue);

      this.localValue = this.getValues(this.tmpValue);

      setTimeout(() => {
        this.setItems(this.tmpValue);
        this.$emit("apply", this.localValue);
        this.closeMenu();
      });
    },

    clearFilter() {
      this.tmpValue = {};
      this.applyFilter();
    },

    closeMenu() {
      this.menu = false;
    },

    getValues(values) {
      let _values = removeObservation(values);
      for (let id in values) {
        const field = find(this.fields, { id });

        if (field) {
          if (field.component === "VSelect") {
            if (typeof values[id] === "object") {
              _values[id] = values[id].value;
            }
          } else if (field.component === 'DatePicker') {
            if (id.includes('end')) {
              const temp = moment.unix(values[id]).endOf('day');
              _values[id] = temp.unix();
            }
          }
        }
      }

      return _values;
    },

    setItems(value) {
      this.items = [];

      value = removeObservation(value);

      for (let id in value) {
        const field = find(this.fields, { id });

        if (field) {
          const vLabel = field?.props?.items?.find(e => e.value == value[id])?.text;
          let item = {
            id,
            component: field.component,
            label: field.label,
            value: value[id],
            valueLabel: vLabel ?? value[id]
          };

          if (field.component === "DatePickerRange") {
            item.value = [];
            const valueForm = parseInt(value[id][0]);
            const valueTo = parseInt(value[id][1]);

            if (!isNaN(valueForm) && !isNaN(valueTo) && valueForm === valueTo) {
              item.value.push(moment.unix(valueForm).format("DD.MM.YYYY"));
            } else {
              if (!isNaN(valueForm) && valueForm >= 0) {
                item.value.push(moment.unix(valueForm).format("DD.MM.YYYY"));
              }

              if (!isNaN(valueTo) && valueTo >= 0) {
                item.value.push(moment.unix(valueTo).format("DD.MM.YYYY"));
              }
            }
          }

          if (field.component === "NumberInputRange") {
            item.value = [];
            const valueForm = parseInt(value[id][0]);
            const valueTo = parseInt(value[id][1]);

            if (!isNaN(valueForm) && !isNaN(valueTo) && valueForm === valueTo) {
              item.value.push(valueForm);
            } else {
              if (!isNaN(valueForm)) {
                if (isNaN(valueTo)) {
                  item.value.push(`От ${valueForm}`);
                } else {
                  item.value.push(valueForm);
                }
              }

              if (!isNaN(valueTo)) {
                if (isNaN(valueForm)) {
                  item.value.push(`До ${valueTo}`);
                } else {
                  item.value.push(valueTo);
                }
              }
            }
          }

          if (field.component === "DatePicker") {
            item.valueLabel = moment.unix(value[id]).format("DD.MM.YYYY")
          }

          this.items.push(item);
        }
      }
    },

    chipClickHandle() {
      this.blurCombobox();

      return false;
    },

    closeChipClickHandle(parent, item) {
      parent.selectItem(item);
      delete this.tmpValue[item.id];
      this.applyFilter();
      this.blurCombobox();
    },

    blurCombobox() {
      this.$refs.uiFilterCombobox.blur();
    },

    vGenerateId() {
      return generateId();
    },

    sanitizeValue(value) {
      value = removeObservation(value);

      for (let prop in value) {
        if (Array.isArray(value[prop])) {
          const arrayNullValues = value[prop].filter((v) => v === null);

          if (arrayNullValues.length === value[prop].length)
            value[prop] = compact(value[prop]);
        }
      }

      value = pickBy(value, identity);
      value = pickBy(value, (v) => {
        if (typeof v === 'boolean' && v) {
          return true;
        }
        if (typeof v === 'number' && v) {
          return true;
        }
        return size(v)
      });

      return value;
    }
  }
};
</script>

<style lang="scss">
.ui-filter {
  &-combobox {
    input {
      caret-color: transparent !important;
    }
  }

  .theme--dark.v-text-field--solo-inverted.v-input--is-focused {
    .v-input__slot {
      background: rgba(255, 255, 255, 0.16);
    }

    i,
    .v-icon {
      color: #ffffff !important;
    }
  }
}
</style>