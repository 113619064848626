import { removeObservation } from '@/utils';
import Notify from './Notify';
import Validation from './Validation';
import EntityCardActions from '@/components/EntityCardActions';

export default {
  metaInfo() {
    if (this.useDialog) return {};

    return {
      title: this.title
    };
  },

  mixins: [Notify, Validation],

  components: {
    EntityCardActions
  },

  data: () => ({
    loading: false,
    saving: false,
    dbData: null,
    title: null,
    createNewTitle: 'Добавить элемент',
    notFoundMessage: 'Элемент не найден.',
    successSaveMessage: 'Данные успешно сохранены.',
    currentTab: null,
    editInfo: {},
    policyKey: null,
    userCanCreate: false,
    userCanUpdate: false,
    userCanDelete: false,

    /**
     * Отображать диалог удаления сущности
     */
    deleteDialog: false,

    /**
     * При сохранении будет использоваться FormData.
     * Использовать, если нужно сохранять файлы.
     */
    useFormData: false,

    /**
     * Поля, в которых содержатся файлы.
     * Будет использоваться при активном useFormData.
     *
     * {key - ключ поля: value - тип переменной GraphQL (connect или sync) }
     *
     * connect - когда CreateFileBelongsTo (один файл.) sync - когда CreateFileBelongsToMany (много файлов)
     */
    fileFields: {},

    /**
     * Выполнить fetch() в дочернем компоненте.
     *
     * Иногда нужно подождать выполнение метода, перед использованием
     * дургих методов в mounted() дочернего компонента.
     */
    childFetch: false,

    /**
     * Сущность редактируется в модальном окне.
     *
     * В данном случае прерываем все операции связанные с router.
     * Также не будет выполнен fetch() в mounted().
     */
    useDialog: false,

    useAPI: true,

    // API (задаем эти свойства в дочернем компоненте)
    apiEntityName: null,
    apiFetchMethodName: null,
    apiCreateMethodName: null,
    apiUpdateMethodName: null,

    // Router (задаем эти свойства в дочернем компоненте)
    routerIndexName: null,
    routerEditName: null,

    // Только обновление сущности
    updateonly: false
  }),

  computed: {
    loadingState() {
      if (this.isCreateNew) {
        return this.loading;
      }

      return !this.dbData || this.loading;
    },

    isCreateNew() {
      return !this.updateonly && parseInt(this.$route.params.id) === 0;
    },

    canRemove() {
      return !this.isCreateNew && this.dbData;
    },

    cardTitle() {
      if (this.isCreateNew) {
        return this.createNewTitle;
      }

      return this.dbData ? this.dbData.title : 'Загрузка...';
    },

    entityData() {
      return this.isCreateNew ? {} : removeObservation(this.dbData);
    },

    deletedMessage() {
      return 'Элемент удален.';
    }
  },

  async mounted() {
    if (this.useAPI) {
      this.checkProperties();
    }

    this.title = this.cardTitle;

    if (!this.useDialog) this.eventsUp();

    if (!this.isCreateNew && !this.childFetch && !this.useDialog) {
      await this.fetch();
    }
  },

  async created() {
    this.userCanCreate = await this.canCreate();
    this.userCanUpdate = await this.canUpdate();
    this.userCanDelete = await this.canDelete();
  },

  destroyed() {
    if (!this.useDialog) this.eventsDown();
  },

  methods: {
    eventsUp() {},

    eventsDown() {},

    checkProperties() {
      const message = 'Не задано свойство';

      if (this.apiEntityName === null) {
        const propertyMessage = `${message} apiEntityName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (this.apiFetchMethodName === null) {
        const propertyMessage = `${message} apiFetchMethodName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (!this.updateonly && this.apiCreateMethodName === null) {
        const propertyMessage = `${message} apiCreateMethodName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (this.apiUpdateMethodName === null) {
        const propertyMessage = `${message} apiUpdateMethodName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (this.routerIndexName === null) {
        const propertyMessage = `${message} routerIndexName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (this.routerEditName === null) {
        const propertyMessage = `${message} routerEditName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }
    },

    async fetch() {
      if (this.isCreateNew) return;

      try {
        this.loading = true;

        const result = await this.$api[this.apiEntityName][
          this.apiFetchMethodName
        ](this.$route.params.id);

        if (!result.data.data[this.apiFetchMethodName]) {
          if (!this.isCreateNew) {
            this.notifyError(this.notFoundMessage);
            this.close();
          }
        } else {
          this.dbData = result.data.data[this.apiFetchMethodName];
          this.title = this.cardTitle;
          this.fetched(removeObservation(this.dbData));
        }

        this.loading = false;
      } catch (exception) {
        this.loading = false;
        this.notifyError(exception);
        throw Error(exception);
      }
    },

    fetched(data) {
      return data;
    },

    async saveItem(data = null) {
      this.saving = true;

      if (this.useFormData) {
        if (await this.saveFormData(data)) {
          if (this.isCreateNew) this.goToYourself();

          if (!this.useDialog) this.refreshData();

          this.$emit('save-item:after');
          this.$bus.$emit('save-item:after');
        }
      } else {
        if (await this.save(data)) {
          if (this.isCreateNew) this.goToYourself();

          if (!this.useDialog) this.refreshData();

          this.$emit('save-item:after');
          this.$bus.$emit('save-item:after');
        }
      }

      this.saving = false;
    },

    async save(data = null) {
      if (!(await this.validateAll())) {
        this.showValidationNotify();

        return false;
      }

      try {
        if (!data) {
          data = removeObservation(this.editInfo);
        }

        this.loading = true;

        let result = null;

        if (this.isCreateNew) {
          // Создание нового элемент
          delete data.id;
          result = await this.$api[this.apiEntityName][
            this.apiCreateMethodName
          ](data);
        } else {
          // Обновление элемента
          if (!data.id) data.id = this.$route.params.id;
          result = await this.$api[this.apiEntityName][
            this.apiUpdateMethodName
          ](data, this.dbData);
        }

        if (!result || result.data.errors) {
          this.loading = false;

          return false;
        }

        this.notifySuccess(this.successSaveMessage);

        const _entityData = this.isCreateNew
          ? result.data.data[this.apiCreateMethodName]
          : result.data.data[this.apiUpdateMethodName];

        if (this.isCreateNew || !this.dbData) {
          this.dbData = {};
        }

        this.dbData = Object.assign(this.dbData, _entityData);

        this.title = this.cardTitle;

        this.loading = false;

        return true;
      } catch (exception) {
        this.loading = false;
        this.notifyError(exception);
        throw Error(exception);
      }
    },

    async saveFormData(data = null) {
      if (!(await this.validateAll())) {
        this.showValidationNotify();

        return false;
      }

      if (!data) {
        data = removeObservation(this.editInfo);
      }

      this.loading = true;

      const { requestData, files } = this.prepareRequestData(data);
      const apiMethod = this.isCreateNew
        ? this.apiCreateMethodName
        : this.apiUpdateMethodName;

      let result = null;

      if (this.isCreateNew) delete requestData.id;
      else {
        if (!requestData.id) requestData.id = this.$route.params.id;
      }

      result = await this.$api[this.apiEntityName][apiMethod](
        this.dbData,
        requestData,
        files,
        this.fileFields
      );

      if (!result || result.data.errors) {
        this.loading = false;

        this.setValidationErrors(result.data.errors);
        this.showValidationNotify(true);

        return false;
      }

      this.notifySuccess(this.successSaveMessage);

      const _entityData = result.data.data[apiMethod];

      if (this.isCreateNew || !this.dbData) {
        this.dbData = {};
      }

      this.dbData = Object.assign(
        this.dbData,
        this.beforeAssignDbData(_entityData)
      );

      this.title = this.cardTitle;

      this.loading = false;

      return true;
    },

    beforeAssignDbData(data) {
      return data;
    },

    /**
     * Подготовка данных запроса.
     * Указываем как необходимо связывать файлы с сущностью.
     *
     * @param {Object} data Данные сущности
     */
    prepareRequestData(data) {
      /** @var Данные запроса. */
      let requestData = removeObservation(data);

      /** @var Добавленные файлы по полям. */
      let files = {};

      for (let field in this.fileFields) {
        let loadFormPC = false;
        let loadFormPCParams = {
          create: []
        };

        /** @var sync или connect */
        const gqlVariableType = this.fileFields[field];

        /** @var Переменная GraphQL для синхронизации файлов. */
        const syncConnectVariable = `$${field}Id`;

        /** @var Переменная GraphQL для синхронизации файлов. */
        const disconnectVariable = `$${field}IdDisconnect`;

        if (data[field]) {
          let fileIdsDbData = [];

          if (this.dbData) {
            if (Array.isArray(this.dbData[field])) {
              fileIdsDbData = this.dbData[field].map((item) => item.id);
            } else {
              if (this.dbData[field] && this.dbData[field].id)
                fileIdsDbData = [this.dbData[field].id];
            }
          }

          if (!Array.isArray(data[field])) {
            data[field] = [data[field]];
          }

          requestData[field] = [];

          data[field].forEach((file, index) => {
            // При обработке файлов в requestData будет указан тип связи (connect, create)
            // и переменные GraphQL, которые необходимо использовать в запросе.
            // Переменные указываем для того, чтобы корректно смогла сформироваться FormData в модуле API.

            if (file.id && parseInt(file.id) > 0) {
              // Файл выбран из медиатеки
              if (
                !requestData[field].some(
                  (e) => e[gqlVariableType] === syncConnectVariable
                )
              ) {
                const requestDataItem = {};
                requestDataItem[gqlVariableType] = syncConnectVariable; // Переменная GraphQL

                requestData[field].push(requestDataItem);
              }
            } else {
              // Загружен новый файл
              if (!loadFormPC) loadFormPC = true;

              if (gqlVariableType === 'connect') {
                // Один файл
                loadFormPCParams.create = {
                  name: `$${field}Name${index}`, // Переменная GraphQL
                  src: `$${field}Src${index}` // Переменная GraphQL
                };
              } else {
                // Много файлов
                loadFormPCParams.create.push({
                  name: `$${field}Name${index}`, // Переменная GraphQL
                  src: `$${field}Src${index}` // Переменная GraphQL
                });
              }
            }

            if (!files[field]) {
              files[field] = [];
            }

            files[field].push(file);
          });

          if (loadFormPC) {
            // Если загружаем файлы с ПК
            requestData[field].push(loadFormPCParams);

            if (gqlVariableType !== 'connect' && fileIdsDbData.length > 0) {
              requestData[field].push({
                disconnect: disconnectVariable // Переменная GraphQL
              });
            }
          }

          if (requestData[field].length === 1) {
            requestData[field] = requestData[field][0];
          }

          if (files[field] && files[field].length === 1) {
            files[field] = files[field][0];
          }
        }
      }

      return { requestData, files };
    },

    async saveAndClose() {
      if (this.useFormData) {
        if (await this.saveFormData()) {
          this.close();
        }
      } else {
        if (await this.save()) {
          this.close();
        }
      }
    },

    close() {
      if (this.useDialog) return;

      this.$router.push({ name: this.routerIndexName });
    },

    goToYourself() {
      if (this.useDialog) return;

      this.$router.push({
        name: this.routerEditName,
        params: { id: this.dbData.id }
      });
    },

    /**
     * Обновляем данные в табах.
     */
    refreshData() {
      this.$refs.tabContent.forEach((tc) => {
        tc.refreshData();
      });
    },

    handleDeleted() {
      this.notifySuccess(this.deletedMessage);
      this.close();
    },

    async canCreate() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canCreate', this.policyKey);
    },

    async canUpdate() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canUpdate', this.policyKey);
    },

    async canDelete() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canDelete', this.policyKey);
    }
  }
};
