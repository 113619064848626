<template>
  <div class="gym-edit">
    <v-card :loading="loadingState" class="mb-3">
      <v-card-title>
        {{ cardTitle }}

        <v-spacer />
        <div class="d-flex align-center">
          <v-chip :color="getGymStatusColor(isActiveWorkout)" class="mx-2">{{
            getGymStatusText(isActiveWorkout)
          }}</v-chip>
          <v-btn v-if="!isCreateNew" color="primary" :loading="loadingOpenDoor" @click="openDoor">Открыть зал</v-btn>
        </div>

      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert v-if="isCreateNew && !userCanCreate" type="error">
        Нет доступа.
      </v-alert>

      <v-card v-else-if="entityData" class="mb-3">
        <v-tabs v-model="currentTab" show-arrows>
          <v-tabs-slider></v-tabs-slider>

          <v-tab v-for="tab in tabs" :href="`#tab-${tab.index}`" :key="tab.index" :disabled="!tab.component">
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab" class="mt-3">
          <v-tab-item v-for="tab in tabs" :key="tab.index" :value="`tab-${tab.index}`">
            <v-card flat>
              <v-card-text>
                <component ref="tabContent" :is="tab.component" :entity-data="entityData" v-bind="tab.props"
                  v-model="editInfo" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions :saving="saving" :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate" @save="beforeSaveItem" @saveAndClose="saveAndClose"
        @close="close" v-if="entityData" />
    </v-scroll-y-reverse-transition>

    <!-- <delete-gym-dialog
      v-model="deleteDialog"
      :entity-id="$route.params.id"
      @deleted="handleDeleted()"
      v-if="canRemove"
    /> -->
  </div>
</template>

<script>
import { EntityCard, GymStatus } from "@/mixins";
import {
  EditEquipment,
  EditMachine,
  EditGym,
  EditMap,
  EditWorktime
} from "@/components/gym/edit";
import {
  ViewWorkouts
} from "@/components/gym/view";
// import { DeleteGymDialog } from '@/components/gym/Dialogs';
import { differenceBy } from "lodash";

export default {
  name: "edit",

  mixins: [EntityCard, GymStatus],

  components: {
    EditEquipment,
    EditMachine,
    EditGym,
    EditMap,
    EditWorktime,
    ViewWorkouts
    // DeleteGymDialog
  },

  data: () => ({
    createNewTitle: "Добавить зал",
    notFoundMessage: "Зал не найден.",
    useFormData: true,

    /**
     * Поля, в которых содержатся файлы.
     */
    fileFields: { files: "sync", howToFindImages: "sync" },

    // API
    apiEntityName: "gym",
    apiFetchMethodName: "gym",
    apiCreateMethodName: "createGym",
    apiUpdateMethodName: "updateGym",

    // Router
    routerIndexName: "GymIndex",
    routerEditName: "GymEdit",

    policyKey: "gym_policy",
    loadingOpenDoor: false,
  }),

  computed: {
    tabs() {
      return [
        {
          index: 1,
          title: "Основное",
          component: "EditGym"
        },
        {
          index: 2,
          title: "Точка на карте",
          component: "EditMap"
        },
        {
          index: 3,
          title: "Оборудование",
          component: "EditEquipment"
        },
        {
          index: 4,
          title: "Автоматы",
          component: "EditMachine"
        },
        {
          index: 5,
          title: "Время работы и цена",
          component: "EditWorktime"
        },
        {
          index: 6,
          title: "Бронирование",
          component: "ViewWorkouts"
        }
      ];
    },

    deletedMessage() {
      return `Зал «${this.dbData.title}» удален.`;
    },
    isActiveWorkout() {
      return this.entityData?.hasActiveWorkout ?? false;
    }
  },

  methods: {
    beforeSaveItem() {
      if (this.isCreateNew && !this.editInfo.point) {
        this.notifyError("Не указана точка на карте.");
        return;
      }

      this.saveItem();
    },

    eventsUp() {
      this.$bus.$on("select-equipment-items", (items) => {
        this.$set(this.editInfo, "equipmentItems", items);

        this.broadcastEquipmentItemsUpdate();
      });

      this.$bus.$on("select-equipment", (equipment) => {
        const items = this.editInfo.equipmentItems.concat(equipment.items);
        this.$set(this.editInfo, "equipmentItems", items);

        this.broadcastEquipmentItemsUpdate();
      });

      this.$bus.$on("unselect-equipment", (equipment) => {
        this.$set(
          this.editInfo,
          "equipmentItems",
          differenceBy(this.editInfo.equipmentItems, equipment.items, "id")
        );

        this.broadcastEquipmentItemsUpdate();
      });

      this.$bus.$on("get-equipment-items", () => {
        this.$bus.$emit("equipment-items-update", this.editInfo.equipmentItems);
      });
    },

    eventsDown() {
      this.$bus.$off("select-equipment-items");
      this.$bus.$off("select-equipment");
      this.$bus.$off("unselect-equipment");
      this.$bus.$off("get-equipment-items");
    },

    fetched(data) {
      this.editInfo.equipmentItems = data.equipmentItems || [];
    },

    broadcastEquipmentItemsUpdate() {
      setTimeout(() => {
        this.$bus.$emit("equipment-items-update", this.editInfo.equipmentItems);
      });
    },

    async openDoor() {
      this.loadingOpenDoor = true;

      try {
        await this.$api.gym.openDoorInGym(this.dbData.id);
        this.notifySuccess("Дверь открыта");
      } catch (_) {
        this.notifyError("Не удалось открыть дверь");
      }

      this.loadingOpenDoor = false;
    }
  }
};
</script>
