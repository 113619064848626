<template>
  <div class="groups-viewer">
    <v-data-table
      v-model="localValue"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      disable-sort
      show-select
      @click:row="rowClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Группы доступа</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    loading: false,
    groups: [],
    options: {},
    items: [],
    total: 0,
    headers: [{ text: 'Название', value: 'title', align: 'start' }]
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      const result = await this.$api.userGroup.groups(page, itemsPerPage);

      if (result.data.data.groups) {
        this.items = result.data.data.groups.data;
        this.total = result.data.data.groups.paginatorInfo.total;
      }

      this.loading = false;
    },

    rowClick: function (item, row) {
      row.select(!row.isSelected);
    }
  }
};
</script>
