<template>
  <div id="promocode-edit">
    <v-card
      :loading="loadingState || checkReferal"
      class="mb-3"
    >
      <v-card-title>
        {{cardTitle}}

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteDialog = true"
          v-if="canRemove && userCanDelete"
          :disabled="loadingState"
        >Удалить</v-btn>
      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert
        v-if="isCreateNew && !userCanCreate"
        type="error"
      >
        Нет доступа.
      </v-alert>

      <v-card
        v-else-if="entityData && !checkReferal"
        class="mb-3"
      >
        <v-tabs
          v-model="currentTab"
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :href="`#tab-${tab.index}`"
            :key="tab.index"
            :disabled="!tab.component"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="currentTab"
          class="mt-3"
        >
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.index"
            :value="`tab-${tab.index}`"
          >
            <v-card flat>
              <v-card-text>
                <component
                  ref="tabContent"
                  :is="tab.component"
                  :entity-data="entityData"
                  v-bind="tab.props"
                  v-model="editInfo"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions
        :saving="saving"
        :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate"
        @save="saveItem"
        @saveAndClose="saveAndClose"
        @close="close"
        v-if="entityData && !checkReferal"
      />
    </v-scroll-y-reverse-transition>

    <delete-promocode-dialog
      v-model="deleteDialog"
      :entity-id="$route.params.id"
      @deleted="handleDeleted()"
      v-if="canRemove && userCanDelete"
    />
  </div>
</template>

<script>
import { EditPromocode } from "@/components/promocode/edit";
import { DeletePromocodeDialog } from "@/components/promocode/Dialogs";
import { EntityCard } from "@/mixins";

export default {
  mixins: [EntityCard],

  components: {
    EditPromocode,
    DeletePromocodeDialog
  },

  data: () => ({
    checkReferal: true,
    childFetch: true,
    createNewTitle: "Новый промокод",
    notFoundMessage: "Промокод не найден.",

    // API
    apiEntityName: "promocode",
    apiFetchMethodName: "promocode",
    apiCreateMethodName: "createPromocode",
    apiUpdateMethodName: "updatePromocode",

    // Router
    routerIndexName: "PromocodeIndex",
    routerEditName: "PromocodeEdit",

    policyKey: 'promocode_policy'
  }),

  computed: {
    tabs() {
      return [
        {
          index: 1,
          title: "Основное",
          component: "EditPromocode"
        }
      ];
    },

    canRemove() {
      return this.dbData && !this.isCreateNew;
    },

    deletedMessage() {
      return `Промокод «${this.dbData.code}» удален.`;
    },

    cardTitle() {
      if (this.isCreateNew) {
        return this.createNewTitle;
      }

      return this.dbData && !this.checkReferal
        ? `Промокод #${this.dbData.id}`
        : "Загрузка...";
    }
  },

  async mounted() {
    await this.fetch();

    if (!this.isCreateNew && this.dbData.type === "referal") {
      this.notifyError(this.notFoundMessage);
      this.close();
      return;
    }

    this.checkReferal = false;

    this.prepareItem();
  },

  methods: {
    prepareItem() {
      if (this.isCreateNew) {
        this.editInfo = {
          type: "global"
        };
      } else {
        this.editInfo = {
          type: this.entityData.type
        };
      }
    }
  }
};
</script>