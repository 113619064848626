<template>
  <div class="workouts-viewer">
    <v-data-table
      :headers="headers"
      :items="localValue"
      :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      sort-by="id"
      sort-desc
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Тренировки</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getWorkoutStatusColor(item.status)">{{getWorkoutStatusText(item.status)}}</v-chip>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ splitNumber(item.price) }}
      </template>

      <template v-slot:[`item.date_start`]="{ item }">
        {{ vFormatDate(item.date_start) }}
      </template>

      <template v-slot:[`item.date_end`]="{ item }">
        {{ vFormatDate(item.date_end) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { NumberMixin, WorkoutStatus } from '@/mixins';
import { formatDateUnix } from '@/utils';

export default {
  mixins: [NumberMixin, WorkoutStatus],

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    headers: [
      { text: '#', value: 'id' },
      { text: 'Статус', value: 'status' },
      { text: 'Стоимость', value: 'price' },
      { text: 'Дата начала', value: 'date_start' },
      { text: 'Дата конца', value: 'date_end' }
    ]
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    vFormatDate(date) {
      return formatDateUnix(date, 'DD.MM.YYYY HH:mm:ss');
    }
  }
};
</script>
