export function stringifyFields(fields, exclude = []) {
  let _fields = fields;

  if (Array.isArray(fields)) {
    _fields = {};

    fields.forEach(field => {
      if (field instanceof Object) {
        _fields = Object.assign(_fields, field);
      }
    });
  }

  let result = JSON.stringify(_fields).replace(/["]+/g, '');

  // Remove null
  exclude = exclude.filter(item => item);

  if (exclude.length > 0) {
    exclude.forEach(element => {
      const replace = `:${element}`;
      const re = new RegExp(replace, 'g');
      result = result.replace(re, `:"${element}"`);
    });
  }

  return result;
}

export function clearPhone(phone) {
  return phone.replace(/[^0-9]/g, '');
}

/**
 * Генерация FormData для мутации сущности.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные сущности
 * @param {Object} input Новые данные сущности
 * @param {*} methodParams Параметры метода
 * @param {*} mutationParams Параметры мутации
 * @param {*} returnFields Возвращаемые значения
 * @param {Object} files Файлы сущности
 * @param {String[]} fileFields Поля сущности, в которых находятся файлы
 */
export function generateMutationFormData(
  mutationName,
  currentInput,
  input,
  methodParams,
  mutationParams,
  returnFields,
  files = {},
  fileFields = [],
  formData = null,
  map = {},
  operations = null
) {
  if (formData && !(formData instanceof FormData)) {
    throw 'Тип параметра formData должен быть FormData';
  }

  if (!formData) {
    formData = new FormData();
  }

  if (!operations) {
    operations = {
      variables: {}
    };
  }

  for (let field in fileFields) {
    if (typeof fileFields[field] === 'string') {
      /** @var sync или connect */
      const gqlVariableType = fileFields[field];

      /** @var Идентификатор загрузки файлов с ПК. */
      // eslint-disable-next-line no-unused-vars
      let loadFormPC = false;

      if (files[field]) {
        if (Array.isArray(files[field])) {
          // Много файлов
          if (!operations.variables.files) operations.variables.files = {};

          operations.variables.files[field] = [];

          files[field].forEach((file, index) => {
            if (!file.id) {
              // Загружаем файлы с ПК
              formData.append(`${field}${index}`, file);

              // Добавляем параметры файла
              mutationParams.push(`$${field}Name${index}: String!`);
              mutationParams.push(`$${field}Src${index}: Upload!`);

              // Добавляем переменные файла
              operations.variables[`${field}Name${index}`] = file.name;
              operations.variables[`${field}Src${index}`] = null;

              map[`${field}${index}`] = [`variables.${field}Src${index}`];

              loadFormPC = true;
            } else {
              if (!mutationParams.includes(`$${field}Id: [ID!]!`)) {
                mutationParams.push(`$${field}Id: [ID!]!`);
              }

              if (!operations.variables[`${field}Id`]) {
                operations.variables[`${field}Id`] = [];
              }

              operations.variables[`${field}Id`].push(file.id);
            }
          });
        } else {
          // Один файл
          if (!files[field].id) {
            // Загружаем файл с ПК
            formData.append(field, files[field]);

            // Добавляем параметры файла
            mutationParams.push(`$${field}Name0: String!`);
            mutationParams.push(`$${field}Src0: Upload!`);

            // Добавляем переменные файла
            operations.variables[`${field}Name0`] = files[field].name;
            operations.variables[`${field}Src0`] = null;

            map[field] = [`variables.${field}Src0`];

            loadFormPC = true;
          } else {
            if (gqlVariableType === 'sync') {
              mutationParams.push(`$${field}Id: [ID!]!`);
            } else {
              mutationParams.push(`$${field}Id: ID`);
            }

            operations.variables[`${field}Id`] = files[field].id;
          }
        }

        // if (loadFormPC) {
        //   // Если файлы загружены с ПК
        //   if (currentInput && currentInput[field].length > 0) {
        //     mutationParams.push(`$${field}IdDisconnect: [ID!]!`);

        //     let fileIdsForDisconnect = [];

        //     if (Array.isArray(currentInput[field])) {
        //       fileIdsForDisconnect = currentInput[field].map(item => item.id);
        //     } else {
        //       fileIdsForDisconnect = [currentInput[field].id];
        //     }

        //     operations.variables[`${field}IdDisconnect`] = fileIdsForDisconnect;
        //   }
        // }

        // Добавляем параметр файл. Очищаем от ковычек.
        methodParams.push(`${field}: ${stringifyFields(input[field])}`);
      }

      delete input[field];
    }
  }

  // Формируем запрос
  operations.query = `
    mutation (${mutationParams.join(', ')}) {
      ${mutationName}(input: {${methodParams.join(', ')}}) {
        ${returnFields.join('\n')}
      }
    }`;

  // Присваиваем переменные
  operations.variables = Object.assign(operations.variables, input);

  formData.append('operations', JSON.stringify(operations));
  formData.append('map', JSON.stringify(map));

  return formData;
}
