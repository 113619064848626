var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mb-2",attrs:{"fluid":""}},[_c('ui-filter',{attrs:{"fields":_vm.filterFields},on:{"apply":_vm.filterApplyHandle},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [25, 50, 100] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('label-link',{attrs:{"to":{ name: 'GymEdit', params: { id: item.gym.id } }}},[_vm._v(" "+_vm._s(item.gym.title))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getWorkoutStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getWorkoutStatusText(item.status)))])]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.date_start, 'DD.MM.YYYY'))+" ")]}},{key:"item.user_full_name",fn:function(ref){
var item = ref.item;
return [_c('label-link',{attrs:{"to":{ name: 'UserEdit', params: { id: item.user.id } }}},[_vm._v(" "+_vm._s(_vm.vGetFullName(item)))])]}},{key:"item.workout_duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDurations(item.date_start, item.finished_at))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('ru'))+" ₽ ")]}},{key:"item.time_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.date_start, 'HH:mm'))+" ")]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.finished_at, 'HH:mm'))+" ")]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [(item.machineOrders.length)?_vm._l((_vm.vGetProducts(item.machineOrders)),function(product){return _c('v-row',{key:product.id,attrs:{"dense":"","title":product.title}},[_c('v-col',[_vm._v(_vm._s(product.name))])],1)}):_c('span',[_vm._v("-")])]}},{key:"item.products_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vGetProductsSum(item.machineOrders))+" ")]}},{key:"item.workout_extend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vGetExtendedTime(item.extends))+" ")]}},{key:"item.workout_pretend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vGetReducedTime(item.finished_at, item.date_end))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.onShowPhotos(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-camera-image")])]}}],null,true)},[_vm._v(" Фотографии зала ")])]}}],null,true)}),_c('GalleryStatusGym',{attrs:{"images-after":_vm.imagesAfter,"images-before":_vm.imagesBefore},model:{value:(_vm.galleryStatusDialog),callback:function ($$v) {_vm.galleryStatusDialog=$$v},expression:"galleryStatusDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }