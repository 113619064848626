<template>
  <div id="access-denied">
    <v-alert type="error">
      Доступ запрещен.</v-alert>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  data: () => ({
    title: "Доступ запрещен"
  })
};
</script>