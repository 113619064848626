class OptionsController {
  _prefix = 'userOptions';

  constructor(key) {
    if (!key) {
      throw Error('Не задан ключ для пользовательских опций.');
    }

    this.localStorageKey = `${this._prefix}_${key}`;
  }

  /**
   * Сохранение пользовательских опций.
   *
   * @param {Object} data
   */
  saveOptions(data) {
    if (!(data instanceof Object)) {
      throw Error('Входные данные должны быть объектом');
    }

    let options = data;

    if (Object.keys(data).length !== 0) {
      options = Object.assign(this.getOptions() || {}, data);
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(options));
  }

  getOptions() {
    return JSON.parse(localStorage.getItem(this.localStorageKey));
  }

  removeOptions() {
    localStorage.removeItem(this.localStorageKey);
  }

  optionsExists() {
    return !!this.getOptions();
  }

  removeAllOptions() {
    let optionKyes = [];

    for (let key in localStorage) {
      if (key.includes(this._prefix)) {
        optionKyes.push(key);
      }
    }

    optionKyes.forEach(key => {
      this._removeOptionsByKey(key);
    });
  }

  _removeOptionsByKey(key) {
    localStorage.removeItem(key);
  }
}

export default OptionsController;
