var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"equipment-viewer"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"options":_vm.options,"show-select":!_vm.loading,"footer-props":_vm.footerProps,"sort-by":"id","sort-desc":""},on:{"update:options":function($event){_vm.options=$event},"item-selected":_vm.equipmentSelectedHandle,"current-items":_vm.setCurrentItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Оборудование")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":_vm.addNewEquipment}},[_vm._v("Добавить")])],1)]},proxy:true},{key:"item.items",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-3"},_vm._l((item.items),function(el,id){return _c('v-checkbox',{key:id,attrs:{"label":el.title,"dense":"","color":"white","hide-details":"","value":el},on:{"change":_vm.equipmentItemSelectedHandle},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"white--text text-body-2"},[_vm._v(_vm._s(el.title))])]},proxy:true}],null,true),model:{value:(_vm.selectedEquipmentItems),callback:function ($$v) {_vm.selectedEquipmentItems=$$v},expression:"selectedEquipmentItems"}})}),1)]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"file-item my-3",staticStyle:{"overflow":"hidden"},attrs:{"height":"100","width":"100","flat":""}},[_c('v-sheet',{staticClass:"file-item__sheet d-flex align-center justify-center",attrs:{"color":_vm.getFileColor(item.file),"height":"100%","width":"100"}},[(_vm.isImage(item.file))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.isImage(item.file))?_c('v-img',{staticClass:"grey darken-3",attrs:{"src":item.file.url,"aspect-ratio":"1"},on:{"click":function($event){_vm.lightBoxIndex = _vm.getImageIndex(item.file.id)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{staticStyle:{"cursor":"pointer"},attrs:{"absolute":"","color":"secondary"}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-magnify-plus-outline")])],1):_vm._e()],1)],1):_vm._e()]}}],null,true)}):_vm._e()],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editEquipment(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),_c('CoolLightBox',{attrs:{"items":_vm.lightBoxItems,"index":_vm.lightBoxIndex,"slideshow":false},on:{"close":function($event){_vm.lightBoxIndex = null}}}),_c('gym-equipment-dialog',{attrs:{"item":_vm.equipmentItemDialog},on:{"close":_vm.equipmentDialogCloseHandle,"saved":_vm.updateItem},model:{value:(_vm.equipmentDialog),callback:function ($$v) {_vm.equipmentDialog=$$v},expression:"equipmentDialog"}}),(_vm.deleteItemId)?_c('delete-gym-equipment-dialog',{attrs:{"entity-id":_vm.deleteItemId},on:{"deleted":_vm.deletedHandle},model:{value:(_vm.deleteEquipmentDialog),callback:function ($$v) {_vm.deleteEquipmentDialog=$$v},expression:"deleteEquipmentDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }