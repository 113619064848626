import Dialog from './Dialog';
import Notify from '../Notify';

export default {
  mixins: [Dialog, Notify],

  props: {
    entityId: {
      type: [Number, String],
      required: true
    }
  },

  data: () => ({
    title: 'Подтверждение удаления',
    text: 'Удалить элемент?',

    // API (задаем эти свойства в дочернем компоненте)
    apiEntityName: null,
    apiDeleteMethodName: null
  }),

  methods: {
    checkProperties() {
      const message = 'Не задано свойство';

      if (this.apiEntityName === null) {
        const propertyMessage = `${message} apiEntityName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }

      if (this.apiDeleteMethodName === null) {
        const propertyMessage = `${message} apiFetchMethodName`;
        this.notifyError(propertyMessage);
        throw Error(propertyMessage);
      }
    },

    async deleteEntity() {
      this.checkProperties();

      try {
        this.loading = true;

        const result = await this.$api[this.apiEntityName][
          this.apiDeleteMethodName
        ](this.entityId);

        if (!result.data.errors) {
          this.$emit('deleted', result.data.data);
          this.dialog = false;
        }

        this.loading = false;
      } catch (exception) {
        this.loading = false;
        this.notifyError(exception);
        throw Error(exception);
      }
    }
  }
};
