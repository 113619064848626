<template>
  <v-file-input
    v-model="files"
    counter
    :label="label"
    :multiple="multiple"
    :placeholder="placeholder"
    prepend-icon="mdi-paperclip"
    :outlined="outlined"
    :disabled="disabled"
    :error-messages="errorMessages"
    :show-size="1000"
  >
    <template v-slot:selection="{ index, text }">
      <v-chip
        v-if="index < 2"
        dark
        label
        small
      >
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="overline grey--text text--darken-3 mx-2"
      >
        +{{ files.length - 2 }} {{getMoreText(files.length - 2, ['Файл', 'Файла', 'Файлов'])}}
      </span>
    </template>
  </v-file-input>
</template>

<script>
import InputComponent from './mixin/inputComponent';
import { declOfNum } from '@/utils';

export default {
  name: 'file-input',

  mixins: [InputComponent],

  props: {
    multiple: {
      type: Boolean,
      default: false
    },

    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    files: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    placeholder() {
      return this.multiple ? 'Выберите файлы' : 'Выберите файл';
    }
  },

  methods: {
    getMoreText(n, words) {
      return declOfNum(n, words);
    }
  }
};
</script>