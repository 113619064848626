<template>
  <div id="user-group">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
        <v-spacer />
        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>

    </v-card>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
        disable-sort
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            v-if="userCanUpdate"
          >mdi-pencil</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="canRemove(item) && userCanDelete"
          >mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <delete-user-group-dialog
      v-model="deleteUserGroupDialog"
      :entity-id="idForDelete"
      @deleted="handleDeleted()"
      v-if="userCanDelete"
    />
  </div>
</template>

<script>
import { UserOptions, EntityIndex } from "@/mixins";
import DeleteUserGroupDialog from "@/components/user-group/Dialogs/DeleteUserGroupDialog";

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [UserOptions, EntityIndex],

  components: {
    DeleteUserGroupDialog
  },

  data: () => ({
    title: "Группы пользователей",
    loading: false,
    total: 0,
    idForDelete: 0,
    deleteUserGroupDialog: false,
    items: [],
    policyKey: "user_group_policy"
  }),

  computed: {
    userOptionsKey() {
      return "user-group-grid";
    },

    headers() {
      const headers = [
        { text: "#", value: "id" },
        { text: "Название", value: "title" }
      ];

      if (this.userCanUpdate || this.userCanDelete) {
        headers.push({
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      const result = await this.$api.userGroup.groups(page, itemsPerPage);

      if (result.data.data.groups) {
        this.items = result.data.data.groups.data;
        this.total = result.data.data.groups.paginatorInfo.total;
      }

      this.loading = false;
    },

    add() {
      this.$router.push({ name: "UserGroupEdit", params: { id: 0 } });
    },

    edit(item) {
      this.$router.push({ name: "UserGroupEdit", params: { id: item.id } });
    },

    deleteItem(item) {
      this.idForDelete = item.id;
      this.deleteUserGroupDialog = true;
    },

    canRemove(item) {
      return parseInt(item.id) !== 1;
    },

    handleDeleted() {
      this.fetch();
    }
  }
};
</script>