import axios from 'axios';

export default {
  async files(page, itemsPerPage, search = null) {
    let args = `page: ${page}, first: ${itemsPerPage}`;

    if (search && search !== '') {
      args += `, search: "${search}"`;
    }

    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            files( ${args} ) {
              paginatorInfo {
                count
                currentPage
                hasMorePages
                lastPage
                perPage
                total
              }
              data {
                id
                src
                url
                name
                mime
                ext
                created_at
              }
            }
          }
      `
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  async file(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            file( id: ${id}) {
              id
              src
              url
              name
              mime
              ext
              created_at
            }
          }
      `
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  async createFile(file) {
    const fileName = file.name;
    const formData = new FormData();

    formData.append('file', file);
    formData.append(
      'operations',
      `
      {
        "query": "mutation ($src: Upload!, $name: String!) { createFile(input: {src: $src, name: $name}) { id, src, url, name, mime, ext } }",
        "variables": {"src": null, "name": "${fileName}"}
      }`
    );

    formData.append('map', '{"file": ["variables.src"]}');

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  async deleteFile(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation ($id: ID!) {
            deleteFile(id: $id) {
              id
              src
              url
              name
              mime
              ext
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
