var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"promo-code"}},[_c('v-card',{staticClass:"mb-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-card',[_c('v-container',{staticClass:"mb-2",attrs:{"fluid":""}},[_c('ui-filter',{ref:"uiFilter",attrs:{"fields":_vm.filterFields},on:{"apply":_vm.filterApplyHandle},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.label)+":")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.getGymProblemStatusText(item.value)))])]}},{key:"state",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.label)+":")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.getGymProblemStateText(item.value)))])]}}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getGymProblemStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getGymProblemStatusText(item.status)))])]}},{key:"item.gym",fn:function(ref){
var item = ref.item;
return [_c('label-link',{attrs:{"to":{ name: 'GymEdit', params: { id: item.gym.id } },"new-window":""}},[_vm._v(_vm._s(item.gym.title))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGymProblemStateText(item.state))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCanUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.userCanDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1),(_vm.userCanDelete)?_c('delete-gym-problem-dialog',{attrs:{"entity-id":_vm.idForDelete},on:{"deleted":function($event){return _vm.handleDeleted()}},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }