<template>
  <div class="transactions-viewer">
    <v-data-table
      :headers="headers"
      :items="items"
      :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      sort-by="id"
      sort-desc
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Транзакции</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.account`]="{ item }">
        <v-chip
          :color="getAccountColor(item.account)"
          dark
        >
          {{ splitNumber(item.account) }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { NumberMixin, UserAccount } from '@/mixins';
import { formatDateUnix } from '@/utils';

export default {
  mixins: [NumberMixin, UserAccount],

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    headers: [
      { text: '#', value: 'id' },
      { text: 'Изменение баланса', value: 'account' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Описание', value: 'description', sortable: false }
    ]
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    items() {
      return this.localValue.map((v) => {
        let item = {
          id: v.id,
          description: v.description,
          account: 0,
          created_at: formatDateUnix(v.created_at, 'DD.MM.YYYY HH:mm:ss')
        };

        if (v.income > 0) {
          item.account = v.income;
        } else if (v.outcome > 0) {
          item.account = 0 - v.outcome;
        }

        return item;
      });
    }
  }
};
</script>
