import axios from 'axios';
import {
  removeObservation
} from '@/utils';

export default {
  /**
 * Получить список тренировок.
 *
 * @param {Number|String} page Страница
 * @param {Number|String} itemsPerPage Количество элементов на странице
 * @param {*} input Параметры фильтрации и сортировки
 */
  async workouts(page, itemsPerPage, input = null) {
    input = removeObservation(input);

    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: WorkoutsInput) {
            workouts(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                date_start
                date_end
                status
                is_active
                price
                created_at
                finished_at
                user {
                  id
                  second_name
                  first_name
                  last_name
                }
                gym {
                  id
                  title
                }
                machineOrders {
                  id
                  amount
                  items
                }
                problems {
                  id
                  photos {
                    id
                    src
                    url
                    name
                    mime
                    ext
                  }
                }
                statusPhotos {
                  id
                  src
                  url
                  name
                  mime
                  ext
                }
                extends {
                  id
                  seconds
                }        
              }
              paginatorInfo {
                  total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },
}