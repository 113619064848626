<template>
  <div class="user-account">Баланс: <v-chip
      class="ml-1"
      :color="getAccountColor(account)"
      small
    >{{splitNumber(account)}}</v-chip>
    <v-btn
      class="ml-3"
      text
      x-small
      color="warning"
      @click="changeAccountDialog = true"
    >
      Изменить
    </v-btn>

    <change-account-dialog
      v-model="changeAccountDialog"
      :account="account"
      :user-id="userId"
      @create-transaction="createTransactionHandle($event)"
    />
  </div>
</template>

<script>
import { NumberMixin, UserAccount } from '@/mixins';
import ChangeAccountDialog from './ChangeAccountDialog';

export default {
  mixins: [NumberMixin, UserAccount],

  components: {
    ChangeAccountDialog
  },

  props: {
    userId: {
      type: [Number, String],
      required: true
    },

    account: {
      type: [Number, String],
      default: 0
    }
  },

  data: () => ({
    changeAccountDialog: false
  }),

  methods: {
    createTransactionHandle(value) {
      this.$emit("create-transaction", value);
    }
  }
};
</script>