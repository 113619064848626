export default {
  data: () => ({
    fileIconsMap: {
      pdf: 'mdi-file-pdf-box',
      doc: 'mdi-file-word-box',
      docx: 'mdi-file-word-box',
      xls: 'mdi-file-excel-box',
      xlsx: 'mdi-file-excel-box',
      xml: 'mdi-xml',
      csv: 'mdi-file-delimited-outline',
      zip: 'mdi-zip-box',
      rar: 'mdi-zip-box',
      default: 'mdi-file'
    },

    fileColorMap: {
      pdf: 'red darken-3',
      doc: 'blue darken-3',
      docx: 'blue darken-3',
      xls: 'green darken-3',
      xlsx: 'green darken-3',
      csv: 'green darken-3',
      zip: 'purple darken-3',
      rar: 'purple darken-3',
      default: 'grey darken-1'
    }
  }),

  methods: {
    getFileIcon(file) {
      if (!file.ext || !this.fileIconsMap[file.ext]) {
        return this.fileIconsMap.default;
      }

      return this.fileIconsMap[file.ext];
    },

    getFileColor(file) {
      if (!file.ext || !this.fileColorMap[file.ext]) {
        return this.fileColorMap.default;
      }

      return this.fileColorMap[file.ext];
    },

    isImage(file) {
      if (!file) return false;

      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

      return validImageTypes.includes(file.mime);
    }
  }
};
