<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    scrollable
  >
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-card-text>
        <user-viewer
          :save-filter="false"
          :save-grid-options="false"
          ref="userViewer"
          :key="userViewerKey"
          selectable
          :multiple="multiple"
          @select="selectedItems = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="loading"
          @click="selectItems()"
          :disabled="!this.selectedItems || this.selectedItems.length === 0"
        >
          Выбрать
        </v-btn>

        <v-btn
          text
          @click="closeModal"
          :disabled="loading"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Dialog } from '@/mixins';
import { uniqueId } from 'lodash';

export default {
  name: 'user-viewer-dialog',

  mixins: [Dialog],

  components: {
    UserViewer: () => import('@/components/UserViewer/UserViewer.vue')
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    title: 'Выбор пользователя',
    selectedItems: [],
    userViewerKey: 'user_viewer_0'
  }),

  methods: {
    dialogOpened() {
      this.userViewerKey = uniqueId('user_viewer_');

      setTimeout(() => {
        this.$refs.userViewer.blurCombobox();
      });
    },

    closeModal() {
      this.$refs.userViewer.resetSelection();
      this.dialog = false;
    },

    selectItems() {
      this.$emit('select', this.selectedItems);
      this.closeModal();
    }
  }
};
</script>