<template>
  <div id="files">
    <v-card class="mb-3">
      <v-card-title>
        Файлы
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-text>
        <file-viewer
          ref="fileViewer"
          @click="openFile($event)"
          multiple
          :disable-add-items="!userCanCreate"
          :disable-edit-mode="!userCanDelete"
        />
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import { EntityIndex } from "@/mixins";
import FileViewer from "@/components/FileViewer/FileViewer";

export default {
  name: "index",

  metaInfo: {
    title: "Файлы"
  },

  components: {
    FileViewer
  },

  mixins: [EntityIndex],

  data: () => ({
    policyKey: "file_policy"
  }),

  methods: {
    openFile(file) {
      this.$router.push({ name: "FileView", params: { id: file.id } });
    }
  }
};
</script>
