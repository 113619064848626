export default [
  { name: 'UserIndex', policies: ['user_policy::view'] },
  {
    name: 'UserEdit',
    policies: ['user_policy::create', 'user_policy::update']
  },
  { name: 'UserGroupIndex', policies: ['user_group_policy::view'] },
  {
    name: 'UserGroupEdit',
    policies: ['user_group_policy::create', 'user_group_policy::update']
  },
  { name: 'GymIndex', policies: ['gym_policy::view'] },
  { name: 'GymEdit', policies: ['gym_policy::create', 'gym_policy::update'] },
  { name: 'GymProblemIndex', policies: ['gym_problem_policy::view'] },
  {
    name: 'GymProblemEdit',
    policies: ['gym_problem_policy::create', 'gym_problem_policy::update']
  },
  { name: 'NewsIndex', policies: ['news_policy::view'] },
  {
    name: 'NewsEdit',
    policies: ['news_policy::create', 'news_policy::update']
  },
  { name: 'FileIndex', policies: ['file_policy::view'] },
  {
    name: 'FileView',
    policies: ['file_policy::create', 'file_policy::update']
  },
  {
    name: 'ProductsIndex',
    policies: ['product_policy::view']
  },
  {
    name: 'ProductsEdit',
    policies: ['product_policy::create', 'product_policy::update']
  },
  {
    name: 'PromocodeIndex',
    policies: ['promocode_policy::view']
  },
  {
    name: 'PromocodeEdit',
    policies: ['promocode_policy::create', 'promocode_policy::update']
  },
  { name: 'ConfigIndex', policies: ['config_policy::view'] },
  {
    name: 'ConfigEdit',
    policies: ['config_policy::create', 'config_policy::update']
  },
  {
    name: 'FaqIndex',
    policies: ['faq_policy::view']
  },
  {
    name: 'FaqEdit',
    policies: ['faq_policy::create', 'faq_policy::update']
  },
  { name: 'LogSmsIndex', policies: ['sms_log_policy::view'], },
  {
    name: 'WorkoutsIndex',
    policies: ['workout_policy::view'],
  }
];
