<template>
  <div id="reserves">
    <v-card class="mb-3">
      <v-card-title>
        Бронирование
      </v-card-title>
    </v-card>
    <v-card>
      <workout-table :fetch-items="fetchWorkouts" />
    </v-card>
  </div>
</template>

<script>
import WorkoutTable from '@/components/workout/table/index.vue';

export default {
  components: {
    WorkoutTable
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false
    }
  },

  methods: {
    async fetchWorkouts(payload) {
      const result = await this.$api.workout.workouts(
        payload.page,
        payload.itemsPerPage,
        payload.input);
      return result.data?.data?.workouts
    },
  }
}
</script>