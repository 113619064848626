<template>
  <div id="sms-meta-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
      scrollable
    >
      <v-card>
        <v-card-title>
          {{title}}
        </v-card-title>

        <v-card-text>
          <highlight language="json">
            <slot></slot>
          </highlight>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Dialog } from '@/mixins';
import Highlight from '@/components/Highlight';

export default {
  name: 'sms-meta-dialog',

  mixins: [Dialog],

  components: {
    Highlight
  },

  data: () => ({
    title: 'Детальная информация'
  })
};
</script>
