import axios from 'axios';
import { generateMutationFormData, removeObservation } from '@/utils';
import { compact, differenceBy } from 'lodash';

export default {
  /**
   * Получение списка оборудования.
   */
  async gymEquipments() {
    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, {
      query: `
        query {
          gymEquipments {
            id
            sort
            title
            file {
              id
              src
              url
              name
              mime
              ext
              created_at
            }
            items {
              id
              sort
              title
            }
          }
        }`
    });
  },

  /**
   * Создание оборудования.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async createGymEquipment(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'createGymEquipment',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Обновление оборудования.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async updateGymEquipment(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'updateGymEquipment',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Удаление оборудования.
   *
   * @param {Number|String} id ID оборудования
   */
  async deleteGymEquipment(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteGymEquipment(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};

/**
 * Генерация FormData для оборудования.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные
 * @param {Object} input Новые данные
 * @param {Object} files Файлы
 * @param {Object} fileFields Поля, в которых находятся файлы
 * @param {String[]} additionalReturnsFields Дополнительные возвращаемые поля
 */
function getFormData(
  mutationName,
  currentInput,
  input,
  files = {},
  fileFields = {},
  additionalReturnsFields = []
) {
  input = removeObservation(input);

  // Параметры мутации
  let mutationParams = ['$sort: Int!', '$title: String!'];

  // Параметры метода
  let methodParams = ['sort: $sort', 'title: $title'];

  const returnFields = [
    'id',
    'sort',
    'title',
    'file { id, src, url, name, mime }',
    'items { id, sort, title }'
  ].concat(additionalReturnsFields);

  if (input.id) {
    mutationParams.push('$id: ID!');
    methodParams.push('id: $id');
  }

  if (!input.sort) {
    input.sort = 500;
  }

  // Время работы и цена
  if (input.items) {
    const items = input.items;
    const params = {};

    // Созданные элементы
    const newItems = items.filter(item => !item.id);

    // Существующие элементы
    const existingItems = items.filter(item => item.id);

    if (newItems.length > 0) params.create = newItems;

    if (existingItems.length > 0) params.update = existingItems;

    if (currentInput && currentInput.items) {
      const removedItems = differenceBy(
        currentInput.items,
        existingItems,
        'id'
      );

      let removedItemsIds = removedItems.map(item => item.id);
      removedItemsIds = compact(removedItemsIds);

      if (removedItemsIds.length > 0) {
        params.delete = removedItemsIds;
      }
    }

    input.items = params;

    if (mutationName === 'createGymEquipment')
      mutationParams.push(`$items: CreateGymEquipmentItemHasMany`);
    else mutationParams.push(`$items: UpdateGymEquipmentItemHasMany`);

    methodParams.push(`items: $items`);
  }

  return generateMutationFormData(
    mutationName,
    currentInput,
    input,
    methodParams,
    mutationParams,
    returnFields,
    files,
    fileFields
  );
}
