<template>
  <v-row
    align="center"
    justify="center"
    class="fill-height"
  >
    <v-col>
      <v-card
        class="mx-auto"
        max-width="500"
      >
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>
        </v-card-title>

        <v-form @submit.prevent="step === 1 ? loginSms() : login()">
          <validation-observer ref="observer">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                  <validation-provider
                    v-slot="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <v-text-field
                      v-model="phone"
                      label="Телефон"
                      required
                      v-mask="'+7-###-###-##-##'"
                      :error-messages="getErrorMessage(errors, validationErrors.phone)"
                      outlined
                    ></v-text-field>
                  </validation-provider>

                  <span class="caption grey--text text--darken-1">
                    Введите номер телефона на который будет отправлено SMS с кодом.
                  </span>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text>
                  <validation-provider
                    v-slot="{ errors }"
                    name="code"
                    rules="required"
                  >
                    <v-text-field
                      v-model="code"
                      label="Пароль"
                      type="password"
                      :error-messages="getErrorMessage(errors, validationErrors.code)"
                      required
                      outlined
                    ></v-text-field>
                  </validation-provider>

                  <span class="caption grey--text text--darken-1">
                    Введите код, полученный в SMS.
                  </span>
                </v-card-text>
              </v-window-item>
            </v-window>
          </validation-observer>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              v-if="step !== 1"
              text
              @click.prevent="step--"
            >
              Назад
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="success"
              type="submit"
              :loading="loading"
              v-if="step === 1"
            >Отправить SMS</v-btn>
            <v-btn
              color="success"
              type="submit"
              :loading="loading"
              v-if="step === 2"
            >Войти</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Validation } from "@/mixins";

export default {
  name: "login",

  mixins: [Validation],

  metaInfo() {
    return {
      title: this.title
    };
  },

  data: () => ({
    title: "Авторизация",
    phone: "",
    code: "",
    loading: false,
    step: 1
  }),

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Введите номер телефона";
        case 2:
          return "Введи пароль из SMS";
        default:
          return "";
      }
    }
  },

  methods: {
    async login() {
      if (!(await this.validate(this))) {
        this.showValidationNotify();

        return;
      }

      this.loading = true;
      this.validationErrors = {};

      const result = await this.$api.auth.login(this.phone, this.code);

      if (!result || result.data.errors) {
        this.loading = false;

        this.setValidationErrors(result.data.errors);
        this.showValidationNotify(true);

        return;
      }

      if (result.data.data.login.token) {
        localStorage.setItem("token", result.data.data.login.token);

        const meResult = await this.$api.user.me();

        if (meResult.data.data.me.groups.length > 0) {
          this.$router.push("/");
        } else {
          this.$notify({
            type: "error",
            title: "Нет доступа."
          });

          this.$store.dispatch("user/logout");
        }
      }

      this.loading = false;
    },

    async loginSms() {
      if (!(await this.validate(this))) {
        this.showValidationNotify();

        return;
      }

      this.loading = true;
      this.validationErrors = {};

      const result = await this.$api.auth.loginSms(this.phone);

      if (!result || result.data.errors) {
        this.loading = false;

        this.setValidationErrors(result.data.errors);
        this.showValidationNotify(true);

        return;
      }

      if (result.data.data.loginSms) {
        this.step = 2;
      }

      this.loading = false;
    }
  }
};
</script>
