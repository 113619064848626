<template>
  <div class="worktime-viewer">
    <div class="mb-3" v-if="localValue.length === 0">Нет элементов.</div>

    <validation-observer ref="observer">
      <v-card v-for="(item, index) in localData" :key="index" class="mb-3" color="secondary">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" :name="`day_of_week${index}`" :rules="'required'">
                <v-select v-model="item.day_of_week" outlined label="День недели" :items="days"
                  :error-messages="getErrorMessage(errors)"></v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-dialog :ref="`dialogStartTime${index}`" v-model="modals[`startTime${index}`]"
                :return-value.sync="times[`startTime${index}`]" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" :name="`start_time${index}`" :rules="'required'">
                    <v-text-field v-model="times[`startTime${index}`]" label="Время начала"
                      prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined
                      :error-messages="getErrorMessage(errors)"></v-text-field>
                  </validation-provider>
                </template>
                <v-time-picker v-if="modals[`startTime${index}`]" v-model="times[`startTime${index}`]" full-width
                  color="primary" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals[`startTime${index}`] = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary"
                    @click="saveTime(`dialogStartTime${index}`, times[`startTime${index}`], 'start_time', index)">
                    Сохранить
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-dialog :ref="`dialogEndTime${index}`" v-model="modals[`endTime${index}`]"
                :return-value.sync="times[`endTime${index}`]" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" :name="`endTime${index}`" :rules="'required'">
                    <v-text-field v-model="times[`endTime${index}`]" label="Время конца"
                      prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined
                      :error-messages="getErrorMessage(errors)"></v-text-field>
                  </validation-provider>
                </template>
                <v-time-picker v-if="modals[`endTime${index}`]" v-model="times[`endTime${index}`]" full-width
                  color="primary" format="24hr">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modals[`endTime${index}`] = false">
                    Отмена
                  </v-btn>
                  <v-btn text color="primary"
                    @click="saveTime(`dialogEndTime${index}`, times[`endTime${index}`], 'end_time', index)">
                    Сохранить
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" :name="`price${index}`" :rules="'required'">
                <number-input v-model="item.price" label="Цена" outlined :min="minValue" :max="maxValue"
                  :error-messages="getErrorMessage(errors)" />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="error" small text @click="removeItem(index)">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>

    <v-divider class="mb-3" />

    <v-btn color="info" small @click="addItem">Добавить</v-btn>
  </div>
</template>

<script>
import { Validation } from '@/mixins';
import { generateId, removeObservation } from '@/utils';
import moment from 'moment';
import { NumberInput } from '@/components/UI';

export default {
  mixins: [Validation],

  components: {
    NumberInput
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    localData: {},
    days: [
      { value: 1, text: 'Понедельник' },
      { value: 2, text: 'Вторник' },
      { value: 3, text: 'Среда' },
      { value: 4, text: 'Четверг' },
      { value: 5, text: 'Пятница' },
      { value: 6, text: 'Суббота' },
      { value: 0, text: 'Воскресенье' }
    ],
    modals: {},
    times: {},
    maxValue: 99999,
    minValue: 0
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.prepareData();
    });
  },

  watch: {
    localData(value) {
      let returnedValue = [];

      for (let id in value) {
        returnedValue.push(value[id]);
      }

      this.localValue = returnedValue;
    }
  },

  methods: {
    prepareData() {
      this.localValue.sort((a, b) => {
        if (b.day_of_week === 0) {
          return -1
        } else if (a.day_of_week === 0 && b.day_of_week === 0) {
          return 0
        } else if (a.day_of_week === 0) {
          return 1
        }

        return a.day_of_week - b.day_of_week;
      });
      this.localValue.forEach((item) => {
        let _item = removeObservation(item);
        const id = generateId();

        this.$set(this.localData, id, _item);

        const startTime = parseInt(item.start_time);

        if (startTime >= 0) {
          this.$set(
            this.times,
            `startTime${id}`,
            moment.utc(startTime * 1000).format('HH:mm')
          );
        }

        const endTime = parseInt(item.end_time);

        if (endTime) {
          this.$set(
            this.times,
            `endTime${id}`,
            moment.utc(endTime * 1000).format('HH:mm')
          );
        }
      });
    },

    addItem() {
      this.$set(this.localData, generateId(), {
        start_time: null,
        end_time: null,
        price: null,
        day_of_week: null
      });
    },

    removeItem(index) {
      this.$delete(this.localData, index);
      delete this.times[`startTime${index}`];
      delete this.times[`endTime${index}`];
    },

    saveTime(refName, value, itemTimeName, itemIndex) {
      this.$refs[refName][0].save(value);
      this.localData[itemIndex][itemTimeName] = moment
        .duration(value)
        .asSeconds();
    }
  }
};
</script>
