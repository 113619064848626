<template>
  <div id="promo-code">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
      </v-card-title>
    </v-card>

    <v-card>
      <v-container
        class="mb-2"
        fluid
      >
        <ui-filter
          :fields="filterFields"
          v-model="filter"
          @apply="filterApplyHandle"
          ref="uiFilter"
        >
          <template v-slot:status="{ item }">
            <strong>{{item.label}}:</strong><span class="ml-1">{{getGymProblemStatusText(item.value)}}</span>
          </template>

          <template v-slot:state="{ item }">
            <strong>{{item.label}}:</strong><span class="ml-1">{{getGymProblemStateText(item.value)}}</span>
          </template>
        </ui-filter>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            :color="getGymProblemStatusColor(item.status)"
          >{{getGymProblemStatusText(item.status)}}</v-chip>
        </template>

        <template v-slot:[`item.gym`]="{ item }">
          <label-link
            :to="{ name: 'GymEdit', params: { id: item.gym.id } }"
            new-window
          >{{item.gym.title}}</label-link>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          {{getGymProblemStateText(item.state)}}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            v-if="userCanUpdate"
          >mdi-pencil</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="userCanDelete"
          >mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <delete-gym-problem-dialog
      v-model="deleteDialog"
      :entity-id="idForDelete"
      @deleted="handleDeleted()"
      v-if="userCanDelete"
    />
  </div>
</template>

<script>
import {
  UserOptions,
  Sortable,
  Filterable,
  GymProblemState,
  GymProblemStatus,
  EntityIndex
} from "@/mixins";
import { LabelLink } from "@/components/UI";
import { DeleteGymProblemDialog } from "@/components/gymProblem/Dialogs";
import { UiFilter } from "@/components/UI";
import { removeObservation } from "@/utils";

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [
    UserOptions,
    Sortable,
    Filterable,
    GymProblemState,
    GymProblemStatus,
    EntityIndex
  ],

  components: {
    LabelLink,
    DeleteGymProblemDialog,
    UiFilter
  },

  data: () => ({
    title: "Состояние залов",
    loading: false,
    total: 0,
    idForDelete: 0,
    deleteDialog: false,
    items: [],
    policyKey: "gym_problem_policy"
  }),

  computed: {
    userOptionsKey() {
      return "gym-problem-grid";
    },

    filterFields() {
      return [
        {
          label: "Статус",
          id: "status",
          component: "VSelect",
          props: {
            items: this.gymProblemStatuses
          }
        },
        {
          label: "Состояние зала",
          id: "state",
          component: "VSelect",
          props: {
            selectType: "gymProblemState",
            items: this.gymProblemStates
          }
        }
      ];
    },

    headers() {
      const headers = [
        { text: "#", value: "id" },
        { text: "Статус проблемы", value: "status" },
        { text: "Зал", value: "gym" },
        { text: "Состояние зала", value: "state" }
      ];

      if (this.userCanUpdate || this.userCanDelete) {
        headers.push({
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;

      const input = {};

      const filter = this.getFilterInput(
        removeObservation(this.filter),
        this.getFilterMap(this.filterFields)
      );

      if (filter) {
        input.filter = filter;
      }

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      const result = await this.$api.gymProblem.gymProblems(
        page,
        itemsPerPage,
        input
      );

      if (result.data.data.gymProblems) {
        this.items = result.data.data.gymProblems.data;
        this.total = result.data.data.gymProblems.paginatorInfo.total;
      }

      this.loading = false;
    },

    edit(item) {
      this.$router.push({ name: "GymProblemEdit", params: { id: item.id } });
    },

    deleteItem(item) {
      this.idForDelete = item.id;
      this.deleteDialog = true;
    },

    handleDeleted() {
      this.fetch();
    },

    filterApplyHandle() {
      this.fetch();
    }
  }
};
</script>