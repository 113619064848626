import axios from 'axios';
import { generateMutationFormData, removeObservation } from '@/utils';

export default {
  /**
   * Получить список проблем залов.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async gymProblems(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: GymProblemsInput) {
            gymProblems(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                state
                status
                gym {
                  id
                  title
                }
                workout {
                  id
                }
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить проблему зала по ID.
   *
   * @param {Number|String} id ID проблемы зала
   */
  async gymProblem(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            gymProblem(id: ${id}) {
              id
              state
              status
              gym
              {
                id
                title
                maintenance
                address
                price
                has_shower
                around_the_clock
              }
              photos {
                id
                src
                url
                name
                mime
              }
              workout {
                id
                status
                price
                date_start
                date_end
              }
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление проблемы зала.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async updateGymProblem(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'updateGymProblem',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Удаление проблемы зала.
   *
   * @param {Number|String} id ID проблемы зала
   */
  async deleteGymProblem(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteGymProblem(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};

/**
 * Генерация FormData для проблемы зала.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные
 * @param {Object} input Новые данные
 * @param {Object} files Файлы
 * @param {Object} fileFields Поля, в которых находятся файлы
 * @param {String[]} additionalReturnsFields Дополнительные возвращаемые поля
 */
function getFormData(
  mutationName,
  currentInput,
  input,
  files = {},
  fileFields = {},
  additionalReturnsFields = []
) {
  input = removeObservation(input);

  // Параметры мутации
  let mutationParams = ['$status: String'];

  // Параметры метода
  let methodParams = ['status: $status'];

  const returnFields = [
    'id',
    'state',
    'status',
    'gym { id, title, maintenance, address, price, has_shower, around_the_clock }',
    'photos { id, src, url, name, mime }',
    'workout { id, status, price, date_start, date_end }'
  ].concat(additionalReturnsFields);

  if (input.id) {
    mutationParams.push('$id: ID!');
    methodParams.push('id: $id');
  }

  return generateMutationFormData(
    mutationName,
    currentInput,
    input,
    methodParams,
    mutationParams,
    returnFields,
    files,
    fileFields
  );
}
