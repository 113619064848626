export default {
  computed: {
    GYM_PROBLEM_STATUS_NEW() {
      return 'NEW';
    },

    GYM_PROBLEM_STATUS_CONFIRMED() {
      return 'CONFIRMED';
    },

    GYM_PROBLEM_STATUS_PROCESS() {
      return 'PROCESS';
    },

    GYM_PROBLEM_STATUS_SOLVED() {
      return 'SOLVED';
    },

    GYM_PROBLEM_STATUS_CANCELED() {
      return 'CANCELED';
    },

    gymProblemStatuses() {
      return [
        {
          text: this.getGymProblemStatusText(this.GYM_PROBLEM_STATUS_NEW),
          value: this.GYM_PROBLEM_STATUS_NEW
        },
        {
          text: this.getGymProblemStatusText(this.GYM_PROBLEM_STATUS_CONFIRMED),
          value: this.GYM_PROBLEM_STATUS_CONFIRMED
        },
        {
          text: this.getGymProblemStatusText(this.GYM_PROBLEM_STATUS_PROCESS),
          value: this.GYM_PROBLEM_STATUS_PROCESS
        },
        {
          text: this.getGymProblemStatusText(this.GYM_PROBLEM_STATUS_SOLVED),
          value: this.GYM_PROBLEM_STATUS_SOLVED
        },
        {
          text: this.getGymProblemStatusText(this.GYM_PROBLEM_STATUS_CANCELED),
          value: this.GYM_PROBLEM_STATUS_CANCELED
        }
      ];
    }
  },

  methods: {
    getGymProblemStatusColor(value) {
      if (value === this.GYM_PROBLEM_STATUS_NEW) return 'purple';
      else if (value === this.GYM_PROBLEM_STATUS_PROCESS) return 'teal';
      else if (value === this.GYM_PROBLEM_STATUS_CONFIRMED)
        return 'amber darken-1';
      else if (value === this.GYM_PROBLEM_STATUS_SOLVED) return 'green';
      else if (value === this.GYM_PROBLEM_STATUS_CANCELED) return 'red';
      else return 'gray';
    },

    getGymProblemStatusText(value) {
      if (value === this.GYM_PROBLEM_STATUS_NEW) return 'Новый';
      else if (value === this.GYM_PROBLEM_STATUS_PROCESS) return 'В обработке';
      else if (value === this.GYM_PROBLEM_STATUS_CONFIRMED)
        return 'Подтверждено';
      else if (value === this.GYM_PROBLEM_STATUS_SOLVED) return 'Решено';
      else if (value === this.GYM_PROBLEM_STATUS_CANCELED) return 'Отменено';
      else return `Неизвестный статус ${value}`;
    }
  }
};
