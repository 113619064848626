<template>
  <div id="config">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
        <v-spacer />
        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>

    </v-card>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
        sort-by="id"
        sort-desc
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="edit(item)"
            v-if="userCanUpdate"
          >mdi-pencil</v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="userCanDelete"
          >mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <delete-config-dialog
      v-model="deleteDialog"
      :entity-id="idForDelete"
      @deleted="handleDeleted()"
      v-if="userCanDelete"
    />
  </div>
</template>

<script>
import { UserOptions, Sortable, EntityIndex } from "@/mixins";
import { DeleteConfigDialog } from "@/components/config/Dialogs";
import { formatDateUnix } from "@/utils";

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [UserOptions, Sortable, EntityIndex],

  components: {
    DeleteConfigDialog
  },

  data: () => ({
    title: "Конфигурация",
    loading: false,
    total: 0,
    idForDelete: 0,
    deleteDialog: false,
    items: [],
    policyKey: "config_policy"
  }),

  computed: {
    userOptionsKey() {
      return "config-grid";
    },

    headers() {
      const headers = [
        { text: "#", value: "id" },
        { text: "Название", value: "name" },
        { text: "Значение", value: "value" },
        { text: "Описание", value: "description" }
      ];

      if (this.userCanUpdate || this.userCanDelete) {
        headers.push({
          text: "Действия",
          value: "actions",
          sortable: false,
          align: "end"
        });
      }

      return headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.loading = true;

      const input = {};

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      const result = await this.$api.config.configList(
        page,
        itemsPerPage,
        input
      );

      if (result.data.data.configList) {
        this.items = result.data.data.configList.data;
        this.total = result.data.data.configList.paginatorInfo.total;
      }

      this.loading = false;
    },

    add() {
      this.$router.push({ name: "ConfigEdit", params: { id: 0 } });
    },

    edit(item) {
      this.$router.push({ name: "ConfigEdit", params: { id: item.id } });
    },

    deleteItem(item) {
      this.idForDelete = item.id;
      this.deleteDialog = true;
    },

    handleDeleted() {
      this.fetch();
    },

    vFormatDate(date) {
      return formatDateUnix(date);
    }
  }
};
</script>