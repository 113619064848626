<template>
  <div class="number-input">
    <v-text-field
      v-model.number="localValue"
      :label="label"
      @keypress="isNumber($event) && checkMax() && checkMin()"
      @keyup="checkMax() && checkMin()"
      type="number"
      :hide-details="hideDetails"
      :outlined="outlined"
      :min="min"
      :max="max"
      :error-messages="errorMessages"
      @blur="blurHandle"
    ></v-text-field>
  </div>
</template>

<script>
import { removeObservation } from '@/utils';
import { NumberMixin } from '@/mixins';
import InputComponent from './mixin/inputComponent';

export default {
  name: 'number-input',

  mixins: [InputComponent, NumberMixin],

  props: {
    max: {
      type: Number
    },

    min: {
      type: Number
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    returnString: {
      type: Boolean,
      default: false
    },

    value: {
      type: [Number, String]
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        let _value = removeObservation(value);

        if (this.returnString) {
          _value = _value.toString();
        }

        this.$emit('input', _value);
      }
    }
  },

  methods: {
    checkMax() {
      if (parseInt(this.localValue) > this.max) {
        this.localValue = this.max;
      }

      return true;
    },

    checkMin() {
      if (parseInt(this.localValue) < this.min) {
        this.localValue = this.min;
      }

      return true;
    }
  }
};
</script>