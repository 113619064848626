<template>
  <div class="edit">
    <validation-observer ref="observer">
      <v-container
        fluid
        class="px-0"
        v-for="(row, index) in fieldsRow"
        :key="index"
      >
        <div
          class="text-h5 mb-5"
          v-if="row.title"
        >{{row.title}}</div>

        <v-row>
          <v-col
            v-for="(field, cIndex) in row.fields"
            :key="cIndex"
            v-bind="field.colProps || row.colsProp"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="field.id"
              :rules="field.rules"
            >
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                outlined
                v-mask="field.directives.vMask"
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                @change="removeFieldError(field.id)"
                v-bind="field.props"
                v-if="renderComponent && field.directives && `${field.directives.vMask}`"
              />
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                outlined
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                @change="removeFieldError(field.id)"
                @history-click="historyClick(field.id)"
                v-bind="field.props"
                v-else-if="renderComponent"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-divider
          class="mb-3 mt-2"
          v-if="index + 1 !== fieldsRow.length"
        />
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import { Entity, Validation, UserStatus } from '@/mixins';
import { VTextField, VSelect } from 'vuetify/lib';
import { DatePicker, FileInputSelect } from '@/components/UI';

export default {
  name: 'edit-form-user',

  mixins: [Entity, Validation, UserStatus],

  components: {
    VTextField,
    VSelect,
    FileInputSelect,
    DatePicker
  },

  computed: {
    fieldsRow() {
      return [
        {
          fields: [
            {
              id: 'created_at',
              label: 'Дата регистрации',
              component: 'DatePicker',
              colProps: {
                lg: 4
              }
            }
          ]
        },

        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12,
            lg: 4
          },

          fields: [
            {
              id: 'last_name',
              label: 'Фамилия',
              component: 'VTextField',
              rules: 'required'
            },

            {
              id: 'first_name',
              label: 'Имя',
              component: 'VTextField',
              rules: 'required'
            },

            {
              id: 'second_name',
              label: 'Отчество',
              component: 'VTextField'
            },

            {
              id: 'phone',
              label: 'Телефон',
              component: 'VTextField',
              directives: {
                vMask: '+7-###-###-##-##'
              },
              rules: 'required'
            },

            {
              id: 'email',
              label: 'E-mail',
              component: 'VTextField',
              rules: 'required|email'
            },

            {
              id: 'birthday',
              label: 'Дата рождения',
              component: 'DatePicker'
            }
          ]
        },

        {
          title: 'Паспорт',

          colsProp: {
            cols: 12,
            lg: 4
          },

          fields: [
            {
              id: 'passport_series',
              label: 'Серия',
              component: 'VTextField'
            },
            {
              id: 'passport_number',
              label: 'Номер',
              component: 'VTextField'
            },
            {
              id: 'passport_date',
              label: 'Дата выдачи',
              component: 'DatePicker'
            },
            {
              id: 'passport_issued_by',
              label: 'Кем выдан',
              component: 'VTextField',
              colProps: {
                cols: 12
              }
            },
            {
              id: 'passportFront',
              label: 'Обложка',
              component: 'FileInputSelect',
              colProps: {
                lg: 6
              },
              props: {
                showHistoryBtn: true
              }
            },
            {
              id: 'passportSelfie',
              label: 'Селфи',
              component: 'FileInputSelect',
              colProps: {
                lg: 6
              },
              props: {
                showHistoryBtn: true
              }
            },
            {
              id: 'sign',
              label: 'Подпись',
              component: 'FileInputSelect',
              colProps: {
                lg: 6
              },
              props: {
                showHistoryBtn: true
              }
            }
          ]
        },

        {
          fields: [
            {
              id: 'status',
              label: 'Статус',
              component: 'VSelect',
              colProps: {
                lg: 4
              },
              props: {
                items: this.userStatuses
              }
            }
          ]
        }
      ];
    }
  },

  methods: {
    historyClick(id) {
      this.$emit('file-history-click', `${id}History`);
    }
  }
};
</script>