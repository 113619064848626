<template>
  <div class="equipment-elements">
    <v-data-table
      :items="localItems"
      :headers="headers"
      :footer-props="footerProps"
      sort-by="id"
      sort-desc
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Элементы</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer />

          <v-btn
            color="info"
            small
            @click="addItem"
          >Добавить</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <span v-if="!item.edit">{{item.title}}</span>
        <v-text-field
          class="my-3"
          v-model="item.title"
          outlined
          hide-details
          :ref="`textField${item.id}`"
          @keypress.enter="saveItem(item)"
          v-else
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item, `textField${item.id}`)"
          v-if="!item.edit"
        >mdi-pencil</v-icon>
        <v-icon
          small
          class="mr-2"
          @click="saveItem(item)"
          v-else
        >mdi-content-save-outline</v-icon>

        <v-icon
          small
          @click="removeItem(item.id)"
        >mdi-delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { removeObservation } from '@/utils';

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },

    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    headers: [
      { text: '#', value: 'id' },
      { text: 'Название', value: 'title' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'end' }
    ],
    itemsPerPage: 5,
    footerProps: { itemsPerPageOptions: [5, 10] },
    localItems: [],
    addedCount: 0
  }),

  methods: {
    setItems() {
      this.localItems = removeObservation(this.items);
    },

    addItem() {
      const item = {
        id: `n${this.addedCount}`,
        sort: 500,
        title: '',
        edit: true
      };

      this.localItems.push(item);
      this.addedCount++;

      setTimeout(() => {
        this.setFocus(`textField${item.id}`);
      });
    },

    editItem(item, refName) {
      this.$set(item, 'edit', true);

      setTimeout(() => {
        this.setFocus(refName);
      });
    },

    saveItem(item) {
      if (item.title === '') {
        this.removeItem(item.id);
        return;
      }

      this.$set(item, 'edit', false);
      this.sendModel();
    },

    removeItem(id) {
      const itemIndex = this.localItems.findIndex((item) => item.id === id);

      if (itemIndex !== -1) {
        this.localItems.splice(itemIndex, 1);
        this.sendModel();
      }
    },

    saveAllItems() {
      this.localItems.forEach((item) => {
        this.saveItem(item);
      });
    },

    sendModel() {
      let model = removeObservation(this.localItems);

      model = model.map((item) => {
        delete item.edit;

        if (isNaN(parseInt(item.id))) {
          delete item.id;
        }

        return item;
      });

      this.$emit('input', model);
    },

    setFocus(refName) {
      this.$refs[refName].focus();
    }
  }
};
</script>
