import { removeObservation, OptionsController } from '@/utils';
import { isEqual } from 'lodash';

export default {
  data: () => ({
    userOptions: null,

    /**
     * Опции, которые нужно передать, например в v-data-table.
     */
    options: {}
  }),

  computed: {
    /**
     * Задаем это свойство в дочерних компонентах.
     */
    userOptionsKey() {
      return null;
    }
  },

  watch: {
    options(value, oldValue) {
      if (!this.userOptionsKey || isEqual(value, oldValue)) {
        return;
      }

      if (!this.userOptions) {
        this.setUserOptions();

        return;
      }

      value = removeObservation(value);

      if (value.page && value.page > 1) {
        value.page = 1;
      }

      this.userOptions.saveOptions(value);
    }
  },

  beforeMount() {
    if (!this.userOptionsKey) {
      return;
    }

    this.setUserOptions();
  },

  methods: {
    setUserOptions() {
      this.userOptions = new OptionsController(this.userOptionsKey);

      if (this.userOptions.optionsExists()) {
        this.options = this.userOptions.getOptions();
      }
    }
  }
};
