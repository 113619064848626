import { find } from 'lodash';
import { removeObservation } from '@/utils';

export default {
  props: {
    entityData: {
      type: Object,
      default: () => ({}),
      required: true
    },

    value: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    renderComponent: true
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },

    fieldsRow() {
      return [];
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.prepareModel(this.fieldsRow);

      this.forceRerender();
    });
  },

  methods: {
    prepareModel(fieldsStorage) {
      let fields = [];

      fieldsStorage.forEach(row => {
        fields = fields.concat(row.fields);
      });

      const checkboxFields = fields.filter(
        item => item.component === 'VCheckbox'
      );

      checkboxFields.forEach(field => {
        if (!this.localValue[field.id]) {
          this.$set(this.localValue, field.id, false);
        }
      });

      for (let prop in this.entityData) {
        const field = find(fields, { id: prop });

        if (field) {
          this.$set(this.localValue, prop, this.entityData[prop]);
        }
      }
    },

    refreshData() {
      this.$nextTick(() => {
        this.prepareModel(this.fieldsRow);

        // Обновляем для применения масок
        this.forceRerender();
      });
    },

    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    /**
     * Фильтрация полей по свойству hide.
     * Поля могут быть скрыты по условию.
     *
     * @param {Object[]} rows строки формы
     */
    filterFields(rows) {
      let _rows = removeObservation(rows);
      let _filtered = [];

      _rows.forEach(row => {
        row.fields = row.fields.filter(field => {
          if (!field.props || !field.props.hide) return true;

          if (field.props.hide && !_filtered.includes(field.id)) {
            _filtered.push(field.id);
          }

          return !field.props.hide;
        });
      });

      this._removeFieldValues(_filtered);

      return _rows;
    },

    /**
     *  Удалить значения скрытых полей.
     *
     * @param {String[]} fieldIds
     */
    _removeFieldValues(fieldIds) {
      fieldIds.forEach(id => {
        if (this.localValue[id] || this.localValue[id] === null) {
          this.$delete(this.localValue, id);
        }
      });
    }
  }
};
