<template>
  <div class="edit">
    <validation-observer ref="observer">
      <v-container
        fluid
        class="px-0"
        v-for="(row, index) in filterFields(fieldsRow)"
        :key="index"
      >
        <div
          class="text-h5 mb-3"
          v-if="row.title"
        >{{row.title}}</div>

        <v-row>
          <v-col
            v-for="(field) in row.fields"
            :key="field.id"
            v-bind="field.colProps || row.colsProp"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="field.id"
              :rules="field.rules"
            >
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                v-bind="field.props"
                outlined
                :id="field.id"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-divider
          class="mb-3 mt-2"
          v-if="index + 1 !== fieldsRow.length"
        />
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import {
  Entity,
  Validation,
  GymProblemState,
  GymProblemStatus
} from '@/mixins';
import { VTextField, VSelect } from 'vuetify/lib';
import { DatePicker, FileInputSelect } from '@/components/UI';

export default {
  name: 'edit-gym-problem',

  mixins: [Entity, Validation, GymProblemState, GymProblemStatus],

  components: {
    VTextField,
    VSelect,
    FileInputSelect,
    DatePicker
  },

  computed: {
    fieldsRow() {
      return [
        {
          fields: [
            {
              id: 'status',
              label: 'Статус',
              component: 'VSelect',
              colProps: {
                lg: 4
              },
              props: {
                items: this.gymProblemStatuses
              }
            }
          ]
        },
        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12
          },

          fields: [
            {
              id: 'state',
              label: 'Состоние',
              component: 'VSelect',
              props: {
                items: this.gymProblemStates,
                readonly: true
              }
            },
            {
              id: 'photos',
              label: 'Фотографии',
              component: 'FileInputSelect',
              props: {
                readonly: true
              }
            }
          ]
        }
      ];
    }
  }
};
</script>