<template>
  <div id="file-viewer">
    <div class="file-viewer__toolbar">
      <v-toolbar
        flat
        color="transparent"
        class="mb-2"
      >
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Поиск"
          @input="handleSearchInput"
        ></v-text-field>

        <template v-if="!disableAddItems || !disableEditMode">
          <v-divider
            class="mx-3"
            vertical
            inset
          />

          <v-btn
            color="success"
            class="mr-3"
            @click="uploadFilesDialog = true"
            v-if="!disableAddItems"
          >
            Добавить
          </v-btn>

          <v-btn
            icon
            @click="tableView = !tableView"
          >
            <v-icon>{{viewIcon}}</v-icon>
          </v-btn>

          <v-menu
            bottom
            left
            offset-y
            v-if="!disableEditMode"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editMode = !editMode">
                <v-list-item-action>
                  <v-checkbox :input-value="editMode"></v-checkbox>
                </v-list-item-action>

                <v-list-item-title>Режим правки</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar>

      <v-slide-y-reverse-transition>
        <v-toolbar
          v-if="!disableEditMode && editMode"
          flat
        >

          Выбрано элементов: {{selectedItemsLength}}

          <v-divider
            class="mx-3"
            vertical
            inset
          />

          <v-btn
            color="error"
            small
            :disabled="selectedItemsLength === 0"
            @click="deleteFilesDialog = true"
          >Удалить</v-btn>

          <v-spacer />

          <v-btn
            small
            text
            @click="editMode = false"
          >Закрыть</v-btn>
        </v-toolbar>
      </v-slide-y-reverse-transition>
    </div>

    <data-iterator-view
      :items="items"
      :options.sync="options"
      :loading="loading"
      :server-items-length="total"
      :items-per-page="itemsPerPage"
      :footer-props="footerProps"
      :search-message="searchMessage"
      :selected-items.sync="selectedItems"
      :selection-mode="selectionMode"
      :multiple="multiple"
      @select="sendModel()"
      @click="handleClick($event)"
      v-if="!tableView"
    />

    <data-table-view
      :items="items"
      :options.sync="options"
      :loading="loading"
      :server-items-length="total"
      :items-per-page="itemsPerPage"
      :footer-props="footerProps"
      :search-message="searchMessage"
      :selected-items.sync="selectedItems"
      :selection-mode="selectionMode"  
      :multiple="multiple"
      @select="sendModel()"
      @click="handleClick($event)"
      @deleted="deletedHandle"
      v-else
    />

    <upload-files-dialog
      v-model="uploadFilesDialog"
      @uploaded="refresh"
      multiple
      v-if="!disableAddItems"
    />

    <delete-files-dialog
      v-model="deleteFilesDialog"
      :items="selectedItems"
      @deleted="deletedHandle"
    />
  </div>
</template>

<script>
import { DeleteFilesDialog, UploadFilesDialog } from './Dialogs';
import { debounce, isEqual } from 'lodash';
import { DataIteratorView, DataTableView } from './Views';
import { removeObservation } from '@/utils';
import { Notify, UserOptions } from '@/mixins';

export default {
  name: 'file-viewer',

  mixins: [Notify, UserOptions],

  components: {
    DeleteFilesDialog,
    UploadFilesDialog,
    DataIteratorView,
    DataTableView
  },

  props: {
    selectable: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    disableEditMode: {
      type: Boolean,
      default: false
    },

    disableAddItems: {
      type: Boolean,
      default: false
    },

    saveGridOptions: {
      type: Boolean,
      default: true
    },

    gridOptionsKey: {
      type: String,
      default: 'files'
    },

    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    search: '',
    searchMessage: '',
    loading: false,
    total: 0,
    itemsPerPage: 20,
    items: [],
    selectedItems: [],
    editMode: false,
    uploadFilesDialog: false,
    deleteFilesDialog: false,
    footerProps: { itemsPerPageOptions: [10, 20, 50] },
    tableView: false
  }),

  computed: {
    userOptionsKey() {
      return this.saveGridOptions ? this.gridOptionsKey : null;
    },

    selectionMode() {
      return this.editMode || this.selectable;
    },

    selectedItemsLength() {
      if (this.multiple || Array.isArray(this.selectedItems))
        return this.selectedItems.length;

      return this.selectedItems ? 1 : 0;
    },

    viewIcon() {
      return this.tableView
        ? 'mdi-view-comfy'
        : 'mdi-format-list-bulleted-square';
    }
  },

  watch: {
    options: {
      async handler(value, oldValue) {
        if (isEqual(value, oldValue)) {
          return;
        }        
        await this.fetch();
      },
      deep: true
    },

    selectionMode: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.selectedItems = [];
        }
      }
    },

    tableView(value) {
      this.userOptions.saveOptions({ tableView: value });
    }
  },

  beforeMount() {
    this.$nextTick(() => {
      const options = this.userOptions.getOptions();

      if (options) {
        this.tableView = options.tableView || false;
      }
    });
  },

  mounted() {
    this.sendModel();
  },

  methods: {
    async fetch() {
      const { page, itemsPerPage } = this.options;      
      this.items = [];      

      this.loading = true;

      const result = await this.$api.file.files(
        page,
        itemsPerPage,
        this.search
      );

      if (!result.data.errors) {
        this.items = result.data.data.files.data;
        this.total = result.data.data.files.paginatorInfo.total;
      }

      this.loading = false;

      this.sendModel();
    },

    refresh() {
      this.fetch();
      this.searchMessage = this.search;
    },

    handleClick(item) {      
      this.$emit('click', item);
    },

    sendModel() {
      let selectedItems = removeObservation(this.selectedItems);

      if (!this.multiple && Array.isArray(selectedItems))
        selectedItems = selectedItems[0];

      const items = this.selectable ? selectedItems : this.items;
      this.$emit('input', items);
      this.$emit('select', selectedItems);
    },

    resetSelection() {
      this.selectedItems = [];
      this.sendModel();
    },

    handleSearchInput: debounce(function () {
      this.refresh();
    }, 500),

    deletedHandle() {
      this.notifySuccess(`Файлы удалены.`);
      this.refresh();
    }
  }
};
</script>