import { OptionsController } from '@/utils';

const actions = {
  login({ commit }, payload) {
    commit('AUTHORIZE_USER', payload);
    commit('UPDATE_USER_INFO', payload);
  },

  logout({ commit }) {
    const userOptions = new OptionsController('system');
    userOptions.removeAllOptions();
    localStorage.removeItem('token');
    commit('DEAUTHORIZE_USER');
  },

  updateInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload);
  },

  setGroups({ commit }, payload) {
    commit('SET_GROUPS', payload);
  },

  async canView({ getters }, policy) {
    return getters.policies.includes(`${policy}::view`);
  },

  async canCreate({ getters }, policy) {
    return getters.policies.includes(`${policy}::create`);
  },

  async canUpdate({ getters }, policy) {
    return getters.policies.includes(`${policy}::update`);
  },

  async canDelete({ getters }, policy) {
    return getters.policies.includes(`${policy}::delete`);
  }
};

export default actions;
