<template>
  <div id="news-edit">
    <v-card
      :loading="loadingState"
      class="mb-3"
    >
      <v-card-title>
        {{cardTitle}}

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteDialog = true"
          v-if="canRemove && userCanDelete"
          :disabled="loadingState"
        >Удалить</v-btn>
      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert
        v-if="isCreateNew && !userCanCreate"
        type="error"
      >
        Нет доступа.
      </v-alert>

      <v-card
        v-else-if="entityData"
        class="mb-3"
      >
        <v-tabs
          v-model="currentTab"
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :href="`#tab-${tab.index}`"
            :key="tab.index"
            :disabled="!tab.component"
          >
            {{tab.title}}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="currentTab"
          class="mt-3"
        >
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.index"
            :value="`tab-${tab.index}`"
          >
            <v-card flat>
              <v-card-text>
                <component
                  ref="tabContent"
                  :is="tab.component"
                  :entity-data="entityData"
                  v-bind="tab.props"
                  v-model="editInfo"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions
        :saving="saving"
        :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate"
        @save="saveItem"
        @saveAndClose="saveAndClose"
        @close="close"
        v-if="entityData"
      />
    </v-scroll-y-reverse-transition>

    <delete-news-dialog
      v-model="deleteDialog"
      :entity-id="$route.params.id"
      @deleted="handleDeleted()"
      v-if="canRemove && userCanDelete"
    />
  </div>
</template>

<script>
import { EditNews, EditSlides } from '@/components/news/edit';
import { DeleteNewsDialog } from '@/components/news/Dialogs';
import { EntityCard } from '@/mixins';

export default {
  mixins: [EntityCard],

  components: {
    EditNews,
    EditSlides,
    DeleteNewsDialog
  },

  data: () => ({
    childFetch: true,
    useFormData: true,
    createNewTitle: 'Новая новость',
    notFoundMessage: 'Новость не найдена.',

    /**
     * Поля, в которых содержатся файлы.
     */
    fileFields: { image: 'connect' },

    // API
    apiEntityName: 'news',
    apiFetchMethodName: 'newsItem',
    apiCreateMethodName: 'createNews',
    apiUpdateMethodName: 'updateNews',

    // Router
    routerIndexName: 'NewsIndex',
    routerEditName: 'NewsEdit',

    policyKey: "news_policy"
  }),

  computed: {
    tabs() {
      return [
        {
          index: 1,
          title: 'Основное',
          component: 'EditNews'
        },
        {
          index: 2,
          title: 'Слайды',
          component: 'EditSlides'
        }
      ];
    },

    canRemove() {
      return this.dbData && !this.isCreateNew;
    },

    deletedMessage() {
      return `Новость «${this.dbData.title}» удалена.`;
    }
  },

  async mounted() {
    await this.fetch();
    this.prepareItem();
  },

  methods: {
    prepareItem() {
      if (this.isCreateNew) {
        this.editInfo = {
          slides: []
        };
      }
    },

    fetched(data) {
      this.prepareData(data);

      this.dbData = data;
    },

    beforeAssignDbData(data) {
      this.prepareData(data);

      return data;
    },

    prepareData(data) {
      data.slides.forEach((element, index) => {
        if (typeof element.button_action === 'string') {
          element.button_action = JSON.parse(element.button_action);

          if (!element.button_action || Array.isArray(element.button_action)) {
            this.$set(data.slides[index], 'button_action', {});
          }

          if (!element.button_action.entity) {
            this.$set(data.slides[index].button_action, 'entity', null);
          }

          if (!element.button_action.entity_id) {
            this.$set(data.slides[index].button_action, 'entity_id', null);
          }
        } else {
          if (!element.button_action || Array.isArray(element.button_action)) {
            this.$set(data.slides[index], 'button_action', {});
          }

          if (!element.button_action.entity) {
            this.$set(data.slides[index].button_action, 'entity', null);
          }

          if (!element.button_action.entity_id) {
            this.$set(data.slides[index].button_action, 'entity_id', null);
          }
        }
      });
    }
  }
};
</script>