export default {
  computed: {
    GYM_PROBLEM_STATE_EXTRANEOUS_INSIDE() {
      return 'EXTRANEOUS_INSIDE';
    },

    GYM_PROBLEM_STATE_DIRTY() {
      return 'DIRTY';
    },

    GYM_PROBLEM_STATE_DID_NOT_OPEN() {
      return 'DID_NOT_OPEN';
    },

    gymProblemStates() {
      return [
        {
          text: this.getGymProblemStateText(
            this.GYM_PROBLEM_STATE_EXTRANEOUS_INSIDE
          ),
          value: this.GYM_PROBLEM_STATE_EXTRANEOUS_INSIDE
        },
        {
          text: this.getGymProblemStateText(this.GYM_PROBLEM_STATE_DIRTY),
          value: this.GYM_PROBLEM_STATE_DIRTY
        },
        {
          text: this.getGymProblemStateText(
            this.GYM_PROBLEM_STATE_DID_NOT_OPEN
          ),
          value: this.GYM_PROBLEM_STATE_DID_NOT_OPEN
        }
      ];
    }
  },

  methods: {
    getGymProblemStateText(value) {
      if (value === this.GYM_PROBLEM_STATE_EXTRANEOUS_INSIDE)
        return 'В зале кто-то есть';
      else if (value === this.GYM_PROBLEM_STATE_DIRTY) return 'В зале грязно';
      else if (value === this.GYM_PROBLEM_STATE_DID_NOT_OPEN)
        return 'Зал не открылся';
      else return `Неизвестное состояние ${value}`;
    }
  }
};
