<template>
  <div>
    <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth">
      <v-card>
        <v-card-title v-if="imagesBefore.length">
          Состояние зала до тренировки
        </v-card-title>
        <v-card-text v-if="imagesBefore.length">
          <v-container>
            <v-row no-gutters>
              <v-col v-for="(image, i) in imagesBefore" :key="i" cols="12" sm="4">
                <v-sheet class="ma-2 pa-2">
                  <image-cell-picker :image="image.url" @onShow="beforeLightBoxIndex = i" />
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-title v-if="imagesAfter.length">
          Cостояние зала после тренировки
        </v-card-title>
        <v-container v-if="imagesAfter.length">
          <v-row no-gutters>
            <v-col v-for="(image, i) in imagesAfter" :key="i" cols="12" sm="4">
              <v-sheet class="ma-2 pa-2">
                <image-cell-picker :image="image.url" @onShow="afterLightBoxIndex = i" />
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="isEmpty">
          <v-row>
            <v-col>
              Фотографий нет
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog()" :disabled="loading">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CoolLightBox :items="lightBoxItems" :index="lightBoxIndex" @close="lightBoxIndex = null" :slideshow="false" />
  </div>
</template>

<script>
import { Dialog } from '@/mixins';
import ImageCellPicker from './ImageCellPicker.vue';

export default {
  name: 'gallery-status-gym',
  mixins: [Dialog],
  components: { ImageCellPicker },
  props: {
    imagesBefore: {
      type: Array,
      default: () => []
    },
    imagesAfter: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      beforeLightBoxIndex: null,
      afterLightBoxIndex: null
    }
  },
  computed: {
    isEmpty() {
      return !this.imagesAfter.length && !this.imagesBefore.length
    },
    lightBoxIndex: {
      get() {
        return this.beforeLightBoxIndex != null ? this.beforeLightBoxIndex : this.afterLightBoxIndex;
      },
      set(value) {
        if (this.beforeLightBoxIndex !== null) {
          this.beforeLightBoxIndex = value;
        } else if (this.afterLightBoxIndex !== null) {
          this.afterLightBoxIndex = value;
        }
      },
    },
    lightBoxItems() {
      if (this.beforeLightBoxIndex == null && this.afterLightBoxIndex == null) return [];
      if (this.beforeLightBoxIndex !== null && !this.imagesBefore.length) return [];
      if (this.afterLightBoxIndex !== null && !this.imagesAfter.length) return [];

      const tempImages = this.beforeLightBoxIndex !== null ? this.imagesBefore : this.imagesAfter;

      let items = [];

      tempImages.forEach((item) => {
        items.push({
          title: item.name,
          src: item.url,
        });
      });

      return items;
    },
  }
};
</script>
