<template>
  <div class="edit">
    <validation-observer ref="observer">
      <v-container
        fluid
        class="px-0"
        v-for="(row, index) in filterFields(fieldsRow)"
        :key="index"
      >
        <div
          class="text-h5 mb-3"
          v-if="row.title"
        >{{row.title}}</div>

        <v-row>
          <v-col
            v-for="(field) in row.fields"
            :key="field.id"
            v-bind="field.colProps || row.colsProp"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="field.id"
              :rules="field.rules"
            >
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                v-bind="field.props"
                outlined
                :id="field.id"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import { Entity, Validation } from '@/mixins';
import { VTextField } from 'vuetify/lib';
import { DatePicker, NumberInput } from '@/components/UI';

export default {
  name: 'edit-promocode',

  mixins: [Entity, Validation],

  components: {
    VTextField,
    NumberInput,
    DatePicker
  },

  computed: {
    fieldsRow() {
      return [
        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12
          },

          fields: [
            {
              id: 'code',
              label: 'Промокод',
              component: 'VTextField',
              rules: 'required'
            },
            {
              id: 'count_activated',
              label: 'Возможное количество активаций',
              component: 'NumberInput',
              rules: 'required',
              props: {
                min: 0
              }
            },
            {
              id: 'bonus',
              label: 'Количество начисляемых бонусов',
              component: 'NumberInput',
              rules: 'required',
              props: {
                min: 0
              }
            },
            {
              id: 'start_validity_period_at',
              label: 'Начало активности',
              component: 'DatePicker',
              rules:
                'beforeOrEqual:end_validity_period_at,Окончание активности',
              colProps: {
                lg: 6
              }
            },
            {
              id: 'end_validity_period_at',
              label: 'Окончание активности',
              component: 'DatePicker',
              rules: 'afterOrEqual:start_validity_period_at,Начало активности',
              colProps: {
                lg: 6
              }
            }
          ]
        }
      ];
    }
  }
};
</script>