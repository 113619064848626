<template>
  <div>
    <v-container class="mb-2" fluid>
      <ui-filter v-model="filter" :fields="filterFields" @apply="filterApplyHandle" />
    </v-container>

    <v-data-table :headers="headers" :items="items" :options.sync="options" :server-items-length="total"
      :loading="loading" :footer-props="{ itemsPerPageOptions: [25, 50, 100] }">
      <template v-slot:[`item.title`]="{ item }">
        <label-link :to="{ name: 'GymEdit', params: { id: item.gym.id } }"> {{ item.gym.title }}</label-link>

      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getWorkoutStatusColor(item.status)">{{ getWorkoutStatusText(item.status) }}</v-chip>
      </template>

      <template v-slot:[`item.date_start`]="{ item }">
        {{ vFormatDate(item.date_start, 'DD.MM.YYYY') }}
      </template>

      <template v-slot:[`item.user_full_name`]="{ item }">
        <label-link :to="{ name: 'UserEdit', params: { id: item.user.id } }"> {{ vGetFullName(item) }}</label-link>

      </template>

      <template v-slot:[`item.workout_duration`]="{ item }">
        {{ vFormatDurations(item.date_start, item.finished_at) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.price.toLocaleString('ru') }} ₽
      </template>

      <template v-slot:[`item.time_start`]="{ item }">
        {{ vFormatDate(item.date_start, 'HH:mm') }}
      </template>

      <template v-slot:[`item.finished_at`]="{ item }">
        {{ vFormatDate(item.finished_at, 'HH:mm') }}
      </template>
      <template v-slot:[`item.products`]="{ item }">
        <template v-if="item.machineOrders.length">
          <v-row v-for="product in vGetProducts(item.machineOrders)" dense :key="product.id" :title="product.title">
            <v-col>{{ product.name }}</v-col>
          </v-row>
        </template>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.products_cost`]="{ item }">
        {{ vGetProductsSum(item.machineOrders) }}
      </template>
      <template v-slot:[`item.workout_extend`]="{ item }">
        {{ vGetExtendedTime(item.extends) }}
      </template>
      <template v-slot:[`item.workout_pretend`]="{ item }">
        {{ vGetReducedTime(item.finished_at, item.date_end) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="onShowPhotos(item)">mdi-camera-image</v-icon>
          </template>
          Фотографии зала
        </v-tooltip>
      </template>
    </v-data-table>
    <GalleryStatusGym v-model="galleryStatusDialog" :images-after="imagesAfter" :images-before="imagesBefore" />
  </div>
</template>

<script>
import { UserOptions, Filterable, Sortable, EntityIndex, WorkoutStatus } from "@/mixins";
import { UiFilter } from "@/components/UI";
import { formatDateUnix, timestampDuration } from "@/utils";
import { LabelLink } from "@/components/UI";
import GalleryStatusGym from "../dialogs/GalleryStatusGym.vue";

export default {
  mixins: [UserOptions, Filterable, Sortable, EntityIndex, WorkoutStatus],
  components: {
    UiFilter,
    GalleryStatusGym,
    LabelLink
  },
  props: {
    fetchItems: {
      type: Function,
      required: true,
      default: () => { },
    }
  },
  data() {
    return {
      galleryStatusDialog: false,
      items: [],
      total: 0,
      loading: false,
      chosenItem: null,
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: "Id", value: "id", width: 60, sortable: false },
        { text: "Зал", value: "title", width: 160, sortable: false },
        { text: "Статус", value: "status", width: 100, sortable: false },
        { text: "Дата", value: "date_start", width: 120 },
        { text: "Пользователь", value: "user_full_name", width: 180 },
        { text: "Продол-жительность", value: "workout_duration", width: 130 },
        { text: "Стоимость", value: "price", width: 140 },
        { text: "Время начала", value: "time_start", width: 100, sortable: false },
        { text: "Время конца", value: "finished_at", width: 100, sortable: false },
        { text: "Продукты", value: "products", width: 200, sortable: false },
        { text: "Стоимость продуктов", value: "products_cost", width: 140, sortable: false },
        { text: "Продленные минуты", value: "workout_extend", width: 100 },
        { text: "Сокращенные минуты", value: "workout_pretend", width: 100 },
        { text: 'Действия', value: 'actions', sortable: false, align: 'end' }
      ];

      return headers;
    },
    imagesBefore() {
      return this.chosenItem?.problems?.flatMap(el => el.photos.filter(e => !!e.src)) ?? [];
    },
    imagesAfter() {
      return this.chosenItem?.statusPhotos?.filter(e => !!e.src) ?? [];
    },
    filterFields() {
      return [
        {
          label: "Статус",
          id: "status",
          component: "VSelect",
          props: {
            items: this.WorkoutStatuses
          }
        },
        {
          label: "Дата начала",
          id: "date_start",
          component: "DatePicker"
        },
        {
          label: "Дата конца",
          id: "date_end",
          component: "DatePicker"
        },
        {
          label: "Стоимость",
          id: "price",
          component: "NumberInputRange"
        },
      ];
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    vGetFullName(item) {
      let result = '';

      if (item.user?.last_name) {
        result += item.user.last_name;
      }
      if (item.user?.first_name) {
        result += result ? ` ${item.user.first_name}` : item.user.first_name;
      }
      if (item.user?.second_name) {
        result += result ? ` ${item.user.second_name}` : item.user.second_name;
      }
      return result || 'Пользователь';
    },
    vFormatDurations(dateStart, finishEnd) {
      return dateStart < finishEnd ? timestampDuration(dateStart, finishEnd) + 'м' : '-';
    },
    vFormatDate(date, format = "DD.MM.YYYY") {
      return formatDateUnix(date, format);
    },
    vGetProducts(orders = []) {
      return orders.flatMap(e => e.items ? JSON.parse(e.items).map((el, i) => ({ ...el, id: `${e.id}_${i}` })) : []);
    },
    vGetExtendedTime(extendItems = []) {
      const totalSeconds = extendItems.reduce((acc, curr) => acc += curr.seconds, 0);
      const secondsInMinute = 60;
      return totalSeconds > 0 ? Math.ceil(totalSeconds / secondsInMinute) + 'м' : '-';
    },
    vGetReducedTime(finishedAt, dateEnd) {
      if (!finishedAt || !dateEnd) return '-'
      return this.vFormatDurations(finishedAt, dateEnd)
    },
    vGetProductsSum(products = []) {
      const sum = products.reduce((acc, curr) => acc + curr.amount, 0)
      return sum ? `${sum.toLocaleString('ru')} ₽` : '-';
    },
    async fetch() {
      // eslint-disable-next-line no-unused-vars
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loading = true;

      const filter = this.getFilterInput(
        this.filter,
        this.getFilterMap(this.filterFields)
      );

      const input = {
        filter
      };

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }
      const workoutsData = await this.fetchItems({ page, itemsPerPage, input });

      if (workoutsData) {
        this.items = workoutsData.data;
        this.total = workoutsData.paginatorInfo?.total;
      }

      this.loading = false;
    },
    filterApplyHandle() {
      this.fetch();
    },
    onShowPhotos(item) {
      this.chosenItem = item;
      this.galleryStatusDialog = !this.galleryStatusDialog;
    },
  }
}
</script>