export default {
  props: {
    persistent: {
      type: Boolean,
      default: true
    },

    maxWidth: {
      type: String,
      default: '700'
    },

    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: false,
    title: 'Диалоговое окно',
    text: 'Текст диалогового окна.',
  }),

  computed: {
    dialog: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    },
  },

  watch: {
    dialog(value) {
      if (value) {
        this.dialogOpened();
      }
    }
  },

  methods: {
    dialogOpened() {},

    closeDialog(eventName = 'close', eventData = null) {
      this.dialog = false;

      setTimeout(() => {
        this.$emit(eventName, eventData);
      });
    }
  }
};
