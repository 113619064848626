var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-input-select"},[_c('v-menu',{attrs:{"bottom":"","disabled":_vm.disabled || _vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('v-file-input',_vm._g(_vm._b({ref:"fileInput",attrs:{"label":_vm.label,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"prepend-icon":"mdi-paperclip","outlined":_vm.outlined,"dense":_vm.dense,"disabled":_vm.disabled,"show-size":1000,"clearable":_vm.clearable,"error-messages":_vm.errorMessages},on:{"click":function($event){return _vm.handleClick($event, value)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var file = ref.file;
return [(index < 2)?_c('v-chip',{attrs:{"dark":"","label":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClickFile(file)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(file.name)+" ")])]},proxy:true}],null,true)}):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" "+_vm._s(_vm.getMoreText(_vm.files.length - 2, ['Файл', 'Файла', 'Файлов']))+" ")]):_vm._e()]}},(_vm.showHistoryBtn)?{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":""},on:{"click":_vm.historyClick}},[_c('v-icon',[_vm._v("mdi-history")])],1)]},proxy:true}:null],null,true),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}},'v-file-input',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.fileViewerDialog = true}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-file-document-multiple")])],1),_c('v-list-item-title',[_vm._v(" Из медиатеки ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.loadFromComputer()}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-upload")])],1),_c('v-list-item-title',[_vm._v("Загрузить новый файл")])],1)],1)],1),_c('file-viewer-dialog',{attrs:{"multiple":_vm.multiple},on:{"select":function($event){_vm.files = $event}},model:{value:(_vm.fileViewerDialog),callback:function ($$v) {_vm.fileViewerDialog=$$v},expression:"fileViewerDialog"}}),_c('CoolLightBox',{attrs:{"items":_vm.lightBoxItems,"index":_vm.lightBoxIndex,"slideshow":false},on:{"close":function($event){_vm.lightBoxIndex = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }