export default {
  methods: {
    notifySuccess(text, title = null) {
      this.$notify({
        type: 'success',
        title,
        text
      });
    },

    notifyWarning(text, title = null) {
      this.$notify({
        type: 'warn',
        title,
        text
      });
    },

    notifyError(text, title = 'Произошла ошибка') {
      this.$notify({
        type: 'error',
        title,
        text
      });
    }
  }
};
