<template>
  <div id="gym-equipment-dialog">
    <validation-observer
      ref="dialogObserver"
      v-slot="{ invalid }"
    >
      <v-dialog
        v-model="dialog"
        :persistent="persistent"
        :max-width="maxWidth"
        scrollable
      >
        <v-card>
          <v-card-title>
            {{cardTitle}}
          </v-card-title>

          <v-card-text
            v-if="item"
            class="pt-1"
          >
            <validation-provider
              v-slot="{ errors }"
              name="title"
              rules="required"
            >
              <v-text-field
                label="Название"
                v-model="item.title"
                outlined
                :error-messages="errors"
                data-vv-validate-on="change"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <file-input-select
                label="Изображение"
                v-model="item.file"
                outlined
                :error-messages="errors"
                data-vv-validate-on="change"
              />
            </validation-provider>

            <div class="equipment-elements-block mt-3">
              <equipment-elements
                :items="item.items"
                v-model="item.items"
                ref="equipmentElements"
              />
            </div>
          </v-card-text>
          <v-card-text v-else>
            Элемент не задан.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="loading"
              :disabled="invalid"
              @click="beforeItemSave(item)"
              v-if="item"
            >
              Сохранить
            </v-btn>
            <v-btn
              text
              @click="closeDialog()"
              :disabled="loading"
            >
              Закрыть
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </validation-observer>
  </div>
</template>

<script>
import { Dialog, EntityCard } from '@/mixins';
import { FileInputSelect } from '@/components/UI';
import equipmentElements from './equipmentElements';
import { removeObservation } from '@/utils';

export default {
  name: 'gym-equipment-dialog',

  mixins: [Dialog, EntityCard],

  components: {
    FileInputSelect,
    equipmentElements
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    useFormData: true,
    useDialog: true,
    createNew: false,

    /**
     * Поля, в которых содержатся файлы.
     */
    fileFields: { file: 'connect' },

    // API
    apiEntityName: 'gymEquipments',
    apiFetchMethodName: '',
    apiCreateMethodName: 'createGymEquipment',
    apiUpdateMethodName: 'updateGymEquipment',

    // Router
    routerIndexName: '',
    routerEditName: ''
  }),

  computed: {
    cardTitle() {
      return this.isCreateNew
        ? 'Добавить оборудование'
        : 'Редактировать оборудование';
    },

    isCreateNew() {
      return this.createNew;
    }
  },

  watch: {
    dialog(value) {
      if (value) {
        this.$refs.dialogObserver.reset();

        this.eventsUp();

        if (Object.keys(this.item).length !== 0) {
          this.dbData = removeObservation(this.item);
          this.createNew = false;
        } else {
          this.dbData = {};
          this.createNew = true;
        }

        setTimeout(() => {
          this.$refs.equipmentElements.setItems();
        });
      } else {
        this.eventsDown();
      }
    }
  },

  methods: {
    eventsUp() {
      this.$on('save-item:after', () => {
        this.closeDialog('saved', this.dbData);
      });
    },

    eventsDown() {
      this.$off('save-item:after');
    },

    beforeItemSave(item) {
      this.$refs.equipmentElements.saveAllItems();

      setTimeout(() => {
        this.saveItem(item);
      });
    }
  }
};
</script>