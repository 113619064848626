import moment from 'moment';
import { compact } from 'lodash';

export function formatDate(date, format = 'DD.MM.YYYY') {
  if (!date) return '';

  return moment(date).format(format);
}

export function formatDateUnix(date, format = 'DD.MM.YYYY') {
  if (!date) return '';

  return moment.unix(date).format(format);
}

export function timestampDuration(fromTimestamp, endTimestamp) {
  const from = moment.unix(fromTimestamp)
  const to = moment.unix(endTimestamp)

  return to.diff(from, 'minutes')
}

export function formatDates(dates, format = 'DD.MM.YYYY', separator = ' ~ ') {
  let _dates = [];

  dates.forEach(d => {
    if (d) _dates.push(formatDate(d, format));
  });

  // Удаляем пустые значения
  _dates = compact(_dates);

  return _dates.join(separator);
}

export function timestampRangeDay(timestamp) {
  const from = moment(
    moment.unix(timestamp).format('YYYY-MM-DD 00:00:00')
  ).format('X');

  const to = moment(moment.unix(from).format('YYYY-MM-DD 23:59:59')).format(
    'X'
  );

  return { from, to };
}
