<template>
  <div class="label-link">
    <router-link
      :target="newWindow ? '_blank' : null"
      :to="to"
    >
      <v-chip
        label
        small
        link
        :color="color"
      >
        <slot />
        <v-icon right small v-if="newWindow">
          mdi-open-in-new
        </v-icon>
      </v-chip>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      required: true
    },

    newWindow: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'primary'
    }
  }
};
</script>

<style lang="scss">
.label-link {
  a {
    text-decoration: none;
  }
}
</style>