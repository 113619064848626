import axios from 'axios';

export default {
  /**
   * Получить список вопросов.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async faqs(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: IndexFaqInput) {
            faqs(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                sort
                question
                answer
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить вопрос по ID.
   *
   * @param {Number|String} id ID вопроса
   */
  async faq(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            faq(id: ${id}) {
              id
              sort
              question
              answer
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание вопроса.
   *
   * @param {Object} input Новые данные
   */
   async createFaq(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreateFaqInput!) {
            createFaq(input: $input) {
              id
              sort
              question
              answer
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление вопроса.
   *
   * @param {Object} input Новые данные
   */
  async updateFaq(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: UpdateFaqInput!) {
            updateFaq(input: $input) {
              id
              sort
              question
              answer
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Удаление вопроса.
   *
   * @param {Number|String} id ID вопроса
   */
  async deleteFaq(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteFaq(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
