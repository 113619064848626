import axios from 'axios';
import {
  clearPhone,
  generateMutationFormData,
  removeObservation
} from '@/utils';

export default {
  /**
   * Получить информацию об авторизованном пользователе.
   */
  async me() {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            me {
              id
              first_name
              last_name
              groups {
                id
                title
                policies
              }
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить список пользователей.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async users(page, itemsPerPage, input = null) {
    input = removeObservation(input);

    if (input.filter && input.filter.phone) {
      input.filter.phone = clearPhone(input.filter.phone);
    }

    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: UserListInput) {
            users(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                  id
                  created_at
                  phone
                  email
                  first_name
                  last_name
                  status
                  account
              }
              paginatorInfo {
                  total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить пользователя по ID.
   *
   * @param {Number|String} id ID пользователя
   */
  async user(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            user(id: ${id}) {
              id
              created_at
              phone
              email
              first_name
              second_name
              last_name
              birthday
              account
              passport_series
              passport_number
              passport_date
              passport_issued_by
              passportFront {
                id
                src
                url
                name
                mime
              }
              passportFrontHistory {
                id
                src
                url
                name
                mime
              }
              passportSelfie {
                id
                src
                url
                name
                mime
              }
              passportSelfieHistory {
                id
                src
                url
                name
                mime
              }
              sign {
                id
                src
                url
                name
                mime
              }
              signHistory {
                id
                src
                url
                name
                mime
              }
              groups {
                id
                title
              }
              status
              transactions {
                id
                income
                outcome
                description
                created_at
              }
              favoriteGyms {
                id
                active
                title
                maintenance
                address
                price
                has_shower
                around_the_clock
              }
              workouts {
                id
                status
                price
                date_start
                date_end
              }
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание пользователя.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async createUser(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'createUser',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Обновление пользователя.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async updateUser(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'updateUser',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  }
};

/**
 * Генерация FormData для пользователя.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные
 * @param {Object} input Новые данные
 * @param {Object} files Файлы
 * @param {Object} fileFields Поля, в которых находятся файлы
 * @param {String[]} additionalReturnsFields Дополнительные возвращаемые поля
 */
function getFormData(
  mutationName,
  currentInput,
  input,
  files = {},
  fileFields = {},
  additionalReturnsFields = []
) {
  input = removeObservation(input);
  input.phone = clearPhone(input.phone);

  // Параметры мутации
  let mutationParams = [
    '$phone: String!',
    '$first_name: String',
    '$second_name: String',
    '$last_name: String',
    '$email: String',
    '$birthday: Timestamp',
    '$status: UserStatus!',
    '$passport_series: String',
    '$passport_number: String',
    '$passport_date: Timestamp',
    '$passport_issued_by: String'
  ];

  // Параметры метода
  let methodParams = [
    'phone: $phone',
    'first_name: $first_name',
    'second_name: $second_name',
    'last_name: $last_name',
    'email: $email',
    'birthday: $birthday',
    'status: $status',
    'passport_series: $passport_series',
    'passport_number: $passport_number',
    'passport_date: $passport_date',
    'passport_issued_by: $passport_issued_by'
  ];

  const returnFields = [
    'id',
    'first_name',
    'second_name',
    'last_name',
    'phone',
    'email',
    'birthday',
    'status',
    'passport_series',
    'passport_number',
    'passport_date',
    'passport_issued_by',
    'account',
    'groups { id, title }',
    'passportFront { id, src, url, name, mime}',
    'passportFrontHistory { id, src, url, name, mime}',
    'passportSelfie { id, src, url, name, mime}',
    'passportSelfieHistory { id, src, url, name, mime}',
    'sign { id, src, url, name, mime}',
    'signHistory { id, src, url, name, mime}'
  ].concat(additionalReturnsFields);

  if (input.id) {
    mutationParams.push('$id: ID!');
    methodParams.push('id: $id');
  }

  // Группы пользователей
  if (input.groups) {
    if (Array.isArray(input.groups)) {
      const groupsIds = input.groups.map(group => parseInt(group.id));

      const params = {
        sync: groupsIds
      };

      input.groups = params;
      mutationParams.push(`$groups: ConnectGroupBelongsToMany`);
      methodParams.push(`groups: $groups`);
    }
  }

  return generateMutationFormData(
    mutationName,
    currentInput,
    input,
    methodParams,
    mutationParams,
    returnFields,
    files,
    fileFields
  );
}
