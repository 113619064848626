<template>
  <div class="file-viewer__data-iterator">
    <v-data-iterator
      :items="items"
      :options.sync="optionsLocal"
      :loading="loading"
      :server-items-length="serverItemsLength"
      :items-per-page="itemsPerPage"
      :footer-props="footerProps"
    >
      <template v-slot:loading>
        <v-container>
          <div
            class="d-flex justify-center align-center"
            style="min-height: 100px;"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-container>
      </template>

      <template v-slot:no-data>
        <v-container>
          <div
            class="d-flex justify-center align-center"
            style="min-height: 100px;"
          >
            <span v-if="searchMessage === ''">Файлы не найдены.</span>
            <span v-else>По запросу «{{searchMessage}}» ничего не найдено.</span>

          </div>
        </v-container>
      </template>

      <template v-slot:default="props">
        <v-item-group
          :multiple="multiple"
          v-model="selectedItemsLocal"
        >
          <v-container fluid>
            <v-row>
              <v-col
                v-for="item in props.items"
                :key="item.id"
                cols="12"
                sm="4"
                md="3"
                xl="2"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="item"
                >
                  <v-card
                    height="70"
                    :color="active ? 'blue darken-4' : 'grey darken-3'"
                    class="file-item d-flex"
                    @click="selectionMode ? toggle() || handleSelect() : handleClick(item)"
                  >
                    <v-sheet
                      :color="getFileColor(item)"
                      height="100%"
                      width="70"
                      class="file-item__sheet d-flex align-center justify-center"
                    >
                      <v-img
                        :src="item.url"
                        aspect-ratio="1"
                        v-if="isImage(item)"
                        class="grey darken-3"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-icon v-else>{{getFileIcon(item)}}</v-icon>

                      <v-fade-transition>
                        <v-overlay
                          v-if="active"
                          absolute
                          color="primary"
                          opacity="1"
                          style="border-radius: 0;"
                        >
                          <v-icon size="32">
                            mdi-checkbox-marked-circle
                          </v-icon>
                        </v-overlay>
                      </v-fade-transition>
                    </v-sheet>

                    <div class="file-item__name d-flex align-center">
                      <span
                        style="max-width: 100%;"
                        class="file-name d-inline-block text-truncate"
                      >{{item.name}}</span>
                    </div>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import view from './mixin/view';

export default {
  mixins: [view]
};
</script>

<style lang="scss" scoped>
.file-viewer__data-iterator {
  .file-name {
    user-select: none;
  }

  .file-item {
    overflow: hidden;

    &__name {
      padding: 1rem;
      width: calc(100% - 70px);
      height: 100%;
    }

    &__sheet {
      position: relative;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      flex-shrink: 0;
    }
  }
}
</style>