<template>
  <div class="edit edit__gym">
    <validation-observer ref="observer">
      <v-container
        fluid
        class="px-0"
        v-for="(row, index) in fieldsRow"
        :key="index"
      >
        <div
          class="text-h5 mb-3"
          v-if="row.title"
        >{{row.title}}</div>

        <v-row>
          <v-col
            v-for="(field, cIndex) in row.fields"
            :key="cIndex"
            v-bind="field.colProps || row.colsProp"
            class="py-0"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="field.id"
              :rules="field.rules"
            >
              <component
                :is="field.component"
                v-model="localValue[field.id]"
                :label="field.label"
                :error-messages="getErrorMessage(errors, fieldErrors[field.id])"
                v-bind="field.props"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-divider
          class="mb-3 mt-2"
          v-if="index + 1 !== fieldsRow.length"
        />
      </v-container>
    </validation-observer>
  </div>
</template>

<script>
import { Entity, Validation } from '@/mixins';
import { VTextField, VTextarea, VCheckbox } from 'vuetify/lib';
import { FileInputSelect, NumberInput } from '@/components/UI';

export default {
  name: 'edit-gym',

  mixins: [Entity, Validation],

  components: {
    VTextField,
    VTextarea,
    VCheckbox,
    FileInputSelect,
    NumberInput
  },

  computed: {
    fieldsRow() {
      return [
        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12,
            sm: 6,
            lg: 3
          },

          fields: [
            {
              id: 'active',
              label: 'Активен',
              component: 'VCheckbox',
              props: { class: 'mt-0' }
            },
            {
              id: 'has_shower',
              label: 'Есть душ',
              component: 'VCheckbox',
              props: { class: 'mt-0' }
            },
            {
              id: 'around_the_clock',
              label: 'Круглосуточно',
              component: 'VCheckbox',
              props: { class: 'mt-0' }
            },
            {
              id: 'maintenance',
              label: 'Техническое обслуживание',
              component: 'VCheckbox',
              props: { class: 'mt-0' }
            }
          ]
        },

        {
          // Свойства для всех колонок в строке
          colsProp: {
            cols: 12
          },

          fields: [
            {
              id: 'title',
              label: 'Название',
              component: 'VTextField',
              rules: 'required',
              colProps: {
                cols: 12,
                lg: 6
              }
            },
            {
              id: 'price',
              label: 'Базовая цена',
              component: 'NumberInput',
              rules: 'required',
              colProps: {
                cols: 12,
                lg: 6
              },
              props: {
                max: 99999999,
                min: 0
              }
            },
            {
              id: 'address',
              label: 'Адрес',
              component: 'VTextField',
              rules: 'required',
              colProps: {
                cols: 12
              }
            },
            {
              id: 'how_to_find',
              label: 'Как найти',
              component: 'VTextarea',
              rules: 'required',
              colProps: {
                cols: 12
              }
            },
            {
              id: 'door_key',
              label: 'Ключ API для двери',
              component: 'VTextField',
              rules: '',
              colProps: {
                cols: 12,
                lg: 6
              },
            },
            {
              id: 'door_ip',
              label: 'IP адрес для двери',
              component: 'VTextField',
              rules: 'ip',
              colProps: {
                cols: 12,
                lg: 6
              },
            },
            {
              id: 'free_cancel_time',
              label:
                'Количество секунд за которое можно отменить бронь бесплатно',
              component: 'NumberInput',
              rules: 'required',
              colProps: {
                cols: 12,
                lg: 6
              },
              props: {
                max: 99999999,
                min: 0
              }
            },
            {
              id: 'min_cancel_time',
              label:
                'Минимальное количество времени за которое заплатит клиент если отменяет бронь платно',
              component: 'NumberInput',
              rules: 'required',
              colProps: {
                cols: 12,
                lg: 6
              },
              props: {
                max: 99999999,
                min: 0
              }
            }
          ]
        },

        {
          fields: [
            {
              id: 'files',
              label: 'Картинки',
              component: 'FileInputSelect',
              colProps: {
                cols: 12,
                lg: 6
              },
              props: {
                multiple: true
              }
            },
            {
              id: 'howToFindImages',
              label: 'Как найти (картинки)',
              component: 'FileInputSelect',
              colProps: {
                cols: 12,
                lg: 6
              },
              props: {
                multiple: true
              }
            }
          ]
        }
      ];
    }
  }
};
</script>
