<template>
  <div class="app-bar">
    <v-app-bar app fixed clipped-left flat class="v-bar--underline">
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isAuthorized"
        class="hidden-lg-and-up mr-3"></v-app-bar-nav-icon>

      <router-link class="d-inline-block" to="/">
        <v-img :src="require('@/assets/logo.svg')" max-width="148" width="148"></v-img>
      </router-link>

      <template v-if="isAuthorized">
        <v-spacer />

        <v-menu left bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" :fab="$vuetify.breakpoint.smAndDown"
              :small="$vuetify.breakpoint.smAndDown">
              <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-account</v-icon><span
                v-if="!$vuetify.breakpoint.smAndDown">{{ userFullName }}</span>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item :to="{ name: 'UserEdit', params: { id: userId } }" v-if="canEditProfile">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout()">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app floating v-if="isAuthorized" clipped>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem">
          <template v-for="(menuItem, i) in menu">
            <v-list-group v-if="menuItem.subitems" :key="i" no-action :value="isActive(menuItem)">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ menuItem.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(subitem, sIndex) in menuItem.subitems" :key="sIndex" :to="subitem.path" exact>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item>

              <v-divider class="mb-3"></v-divider>
            </v-list-group>

            <v-list-item :key="`e_${i}`" :to="menuItem.path" v-else>
              <v-list-item-icon>
                <v-icon>{{ menuItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { find } from "lodash";

export default {
  name: "app-bar",

  data: () => ({
    drawer: null,
    selectedItem: null,
    menu: [],
    canEditProfile: true
  }),

  computed: {
    ...mapGetters("user", {
      isAuthorized: "isAuthorized",
      userId: "id",
      userFullName: "fullName",
      groups: "groups"
    })
  },

  watch: {
    async groups() {
      this.menu = await this.createMenu();
    }
  },

  async created() {
    this.canEditProfile = await this.$store.dispatch(
      "user/canUpdate",
      "user_policy"
    );
  },

  methods: {
    async createMenu() {
      const menu = [];

      const userMenu = {
        title: "Пользователи",
        icon: "mdi-weight-lifter",
        subitems: []
      };

      if (await this.$store.dispatch("user/canView", "user_policy")) {
        userMenu.subitems.push({
          title: "Список пользователей",
          path: "/user"
        });
      }

      if (await this.$store.dispatch("user/canView", "user_group_policy")) {
        userMenu.subitems.push({
          title: "Группы пользователей",
          path: "/user-group"
        });
      }

      if (userMenu.subitems.length > 0) {
        menu.push(userMenu);
      }

      const gymMenu = {
        title: "Залы",
        icon: "mdi-dumbbell",
        subitems: []
      };

      if (await this.$store.dispatch("user/canView", "gym_policy")) {
        gymMenu.subitems.push({
          title: "Список залов",
          path: "/gym"
        });
      }

      if (await this.$store.dispatch("user/canView", "gym_problem_policy")) {
        gymMenu.subitems.push({
          title: "Состояние залов",
          path: "/gym-problem"
        });
      }

      if (gymMenu.subitems.length > 0) {
        menu.push(gymMenu);
      }

      if (await this.$store.dispatch("user/canView", "news_policy")) {
        menu.push({
          title: "Новости",
          icon: "mdi-newspaper-variant-outline",
          path: "/news"
        });
      }

      if (await this.$store.dispatch("user/canView", "file_policy")) {
        menu.push({
          title: "Файлы",
          icon: "mdi-file-document-multiple-outline",
          path: "/file"
        });
      }

      menu.push({
        title: "Продукты",
        icon: "mdi-gift-outline",
        path: "/products"
      });

      menu.push({
        title: "Промокоды",
        icon: "mdi-gift-outline",
        path: "/promocode"
      });

      if (await this.$store.dispatch("user/canView", "config_policy")) {
        menu.push({
          title: "Конфигурация",
          icon: "mdi-cog-outline",
          path: "/config"
        });
      }

      menu.push({
        title: "FAQ",
        icon: "mdi-help-circle-outline",
        path: "/faq"
      });

      const logMenu = {
        title: "Логи",
        icon: "mdi-list-status",
        subitems: []
      };

      if (await this.$store.dispatch("user/canView", "sms_log_policy")) {
        logMenu.subitems.push({
          title: "SMS",
          path: "/log-sms"
        });
      }

      if (logMenu.subitems.length > 0) {
        menu.push(logMenu);
      }

      menu.push({
        title: "Бронирование",
        icon: "mdi-view-list",
        path: "/workouts"
      })

      return menu;
    },

    logout() {
      this.$store.dispatch("user/logout");
      this.$router.push("/login");
    },

    isActive(item) {
      if (!item.subitems) return false;

      const subitem = find(item.subitems, { path: this.$route.path });

      return Boolean(subitem);
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer--fixed {
  z-index: 9 !important;
}
</style>