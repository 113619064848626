<template>
  <div id="gym">
    <v-card class="mb-3">
      <v-card-title>
        {{ title }}
        <v-spacer />

        <v-btn
          color="success"
          @click="add()"
          v-if="userCanCreate"
        >Добавить</v-btn>
      </v-card-title>
    </v-card>

    <v-card>
      <v-container
        fluid
        class="yandex-map"
      >
        <yandex-map
          :coords="mapCoords"
          :zoom="10"
          :controls="mapControls"
          :options="mapOptions"
        >
          <ymap-marker
            v-for="point in points"
            :key="point.id"
            :marker-id="point.id"
            :coords="[point.point.lat, point.point.lon]"
            cluster-name="1"
            @click="edit(point)"
          />
        </yandex-map>
      </v-container>
    </v-card>
  </div>

</template>

<script>
import { EntityIndex } from "@/mixins";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import Config from "@/config";

export default {
  name: "index",

  components: {
    yandexMap,
    ymapMarker
  },

  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [EntityIndex],

  data: () => ({
    ...Config.map,
    title: "Залы",
    loading: false,
    points: [],
    policyKey: "gym_policy"
  }),

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.loading = true;

      const result = await this.$api.gym.gyms();

      if (result.data.data.gyms) {
        this.points = result.data.data.gyms;
      }

      this.loading = false;
    },

    add() {
      this.$router.push({ name: "GymEdit", params: { id: 0 } });
    },

    edit(item) {
      this.$router.push({ name: "GymEdit", params: { id: item.id } });
    }
  }
};
</script>


<style lang="scss" scoped>
.yandex-map {
  height: 600px;

  .ymap-container {
    height: 100%;
  }
}
</style>