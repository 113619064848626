<template>
  <div class="yandex-map">
    <yandex-map
      :coords="mapCoords"
      :zoom="10"
      :controls="mapControls"
      :options="mapOptions"
      @click="onClick"
    >
      <ymap-marker
        :marker-id="1"
        :coords="[localValue.lat, localValue.lon]"
      />
    </yandex-map>
  </div>
</template>

<script>
import Config from '@/config';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    ...Config.map
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    onClick(e) {
      const coords = e.get('coords');
      this.$set(this.localValue, "lat", coords[0]);
      this.$set(this.localValue, "lon", coords[1]);

      // Почему-то через computed не отлавливаются изменения
      this.$emit('input', this.localValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.yandex-map {
  height: 600px;

  .ymap-container {
    height: 100%;
  }
}
</style>