import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import './plugins/vmask';
import router from './router';
import api from './api/index';
import Notifications from 'vue-notification';
import axios from 'axios';
import store from './store';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import YmapPlugin from 'vue-yandex-maps';

Vue.config.productionTip = false;

Vue.prototype.$api = api;

const settings = {
  apiKey: 'c25343c1-eafc-4204-a384-4c51df4f6a41',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
};

Vue.use(Notifications);
Vue.use(CoolLightBox);
Vue.use(YmapPlugin, settings);

axios.interceptors.response.use(
  response => {
    // Handle errors
    if (response.data.errors && Array.isArray(response.data.errors)) {
      // Prevent authentication error
      if (response.data.errors[0].extensions.category === 'authentication') {
        return response;
      }

      // Prevent validation error
      if (response.data.errors[0].extensions.category === 'validation') {
        return response;
      }

      const errors = response.data.errors.map(item => item.message);

      Vue.notify({
        type: 'error',
        title: 'Произошла ошибка',
        text: errors.join('\n'),
        duration: 10000
      });
    }

    return response;
  },
  error => {
    return Promise.reject(error.message);
  }
);

// Свойство, которое будет отвечать за общую шину событий
Object.defineProperty(Vue.prototype, '$bus', {
  get: function() {
    return this.$root.bus;
  }
});

new Vue({
  vuetify,
  router,
  store,
  data: {
    bus: new Vue({}) // Here we bind our event bus to our $root Vue model.
  },
  render: h => h(App)
}).$mount('#app');
