export default {
  data: () => ({
    policyKey: null,
    userCanCreate: false,
    userCanUpdate: false,
    userCanDelete: false
  }),

  async created() {
    this.userCanCreate = await this.canCreate();
    this.userCanUpdate = await this.canUpdate();
    this.userCanDelete = await this.canDelete();
  },

  methods: {
    async canCreate() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canCreate', this.policyKey);
    },

    async canUpdate() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canUpdate', this.policyKey);
    },

    async canDelete() {
      if (!this.policyKey) return false;
      return await this.$store.dispatch('user/canDelete', this.policyKey);
    }
  }
};
