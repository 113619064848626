<template>
  <div class="date-picker date-picker__range">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      :return-value.sync="dates"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :class="htmlClass"
          :value="computedDateFormatted"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :outlined="outlined"
          :hide-details="hideDetails"
          :clearable="clearable"
          @click:clear="dates = []"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        range
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          Отмена
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.menu.save(dates)"
        >
          Принять
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';
import InputComponent from './mixin/inputComponent';
import { formatDate, formatDateUnix, formatDates } from '@/utils';

export default {
  name: 'date-picker-range',

  mixins: [InputComponent],

  props: {
    value: {
      type: Array,
      default: () => []
    },

    returnString: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    menu: false
  }),

  computed: {
    dates: {
      get() {
        const value = [];
        const format = 'YYYY-MM-DD';

        this.value.forEach((v) => {
          if (parseInt(v) > 0) {
            value.push(v ? formatDateUnix(v, format) : v);
          } else {
            value.push(v ? formatDate(v, format) : v);
          }
        });

        return value;
      },

      set(value) {
        const input = [];

        value.sort();

        value.forEach((v) => {
          const momentValue = moment(v);

          input.push(
            this.returnString
              ? momentValue.format('YYYY-MM-DD 00:00:00')
              : momentValue.unix()
          );
        });

        this.$emit('input', input);
      }
    },

    computedDateFormatted() {
      return formatDates(this.dates);
    }
  }
};
</script>