<template>
  <v-sheet dark>
    <highlight
      :language="language"
      class="code--custom"
    >
      <slot></slot>
    </highlight>
  </v-sheet>
</template>

<script>
import Highlight from 'vue-highlight-component';
import 'highlight.js/styles/qtcreator_dark.css';

export default {
  props: {
    language: String
  },

  components: {
    Highlight
  }
};
</script>

<style lang="scss" scoped>
.v-application .code--custom code {
  all: unset;
  color: #eee;
}
</style>