export default {
  props: {
    label: {
      type: String
    },

    outlined: {
      type: Boolean,
      default: false
    },

    dense: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },

    hideDetails: {
      type: Boolean,
      default: false
    },

    htmlClass: {
      type: String,
      default: ''
    },

    id: {
      type: String
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    blurHandle(event) {
      this.$emit('blur', event);
    }
  }
};
