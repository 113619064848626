<template>
  <div id="delete-files-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title>
          Изменение баланса
        </v-card-title>

        <validation-observer
          ref="dialogObserver"
          v-slot="{ invalid }"
        >
          <v-form @submit.prevent="createTransaction()">
            <v-card-text>
              <div class="mb-3">Баланс после изменения: <v-chip
                  :color="getAccountColor(accountAfterChange)"
                  small
                >{{splitNumber(accountAfterChange)}}</v-chip>
              </div>

              <validation-provider
                v-slot="{ errors }"
                name="changeAccountValue"
                rules="required|notZero"
              >
                <number-input
                  label="Изменить баланс на"
                  outlined
                  v-model="changeAccountValue"
                  :error-messages="errors"
                  :max="maxValue"
                  :min="minValue"
                  @input="handleChangedAccountInput()"
                  data-vv-validate-on="change"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <v-textarea
                  label="Описание"
                  outlined
                  v-model="description"
                  :error-messages="errors"
                  data-vv-validate-on="change"
                ></v-textarea>
              </validation-provider>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                type="submit"
                :loading="loading"
                :disabled="invalid"
              >
                Изменить
              </v-btn>
              <v-btn
                text
                @click="closeDialog()"
                :disabled="loading"
              >
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Dialog, NumberMixin, UserAccount, Validation } from '@/mixins';
import { debounce } from 'lodash';
import { NumberInput } from '@/components/UI';

export default {
  name: 'change-account-dialog',

  mixins: [Dialog, NumberMixin, UserAccount, Validation],

  components: {
    NumberInput
  },

  props: {
    account: {
      type: [Number, String],
      default: 0
    },

    userId: {
      type: [Number, String],
      required: true
    }
  },

  data: () => ({
    changeAccountValue: '',
    accountAfterChange: 0,
    description: '',
    maxValue: 999999,
    minValue: -999999
  }),

  methods: {
    dialogOpened() {
      setTimeout(() => {
        this.resetFormValidation();
      });

      this.calcAccountAfterChange();
    },

    async createTransaction() {
      this.loading = true;

      const input = {
        user: {
          connect: parseInt(this.userId)
        },
        description: this.description
      };

      const result = await this.$api.transaction.createTransaction(
        this.changeAccountValue,
        input
      );

      if (result.data.data.createTransaction) {
        this.$emit('create-transaction', result.data.data.createTransaction);
        this.closeDialog();
      }

      this.loading = false;
    },

    handleChangedAccountInput: debounce(function () {
      this.calcAccountAfterChange();
    }, 500),

    calcAccountAfterChange() {
      const changeAccountValue = parseInt(this.changeAccountValue) || 0;
      this.accountAfterChange = parseInt(this.account) + changeAccountValue;
    },

    closeDialog() {
      this.changeAccountValue = '';
      this.accountAfterChange = 0;
      this.description = '';
      this.resetFormValidation();
      this.dialog = false;
    },

    resetFormValidation() {
      this.$refs.dialogObserver.reset();
    }
  }
};
</script>