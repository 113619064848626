var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-viewer"},[_c('v-container',{staticClass:"mb-2",attrs:{"fluid":""}},[_c('ui-filter',{ref:"uiFilter",attrs:{"fields":_vm.filterFields},on:{"apply":_vm.filterApplyHandle},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.label)+":")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.getUserStatusText(item.value)))])]}}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"show-select":_vm.selectable,"single-select":!_vm.multiple,"footer-props":{ itemsPerPageOptions: [ 5, 25, 50, 100 ] },"sort-by":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.created_at))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.phone,'+# (###) ###-##-##')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getUserStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getUserStatusText(item.status)))])]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getAccountColor(item.account)}},[_vm._v(_vm._s(_vm.splitNumber(item.account)))])]}},(_vm.useActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")])]}}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }