import axios from 'axios';

export default {
  /**
   * Получить список промокодов.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async promocodes(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: PromocodeListInput) {
            promocodes(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                type
                code
                count_activated
                bonus
                start_validity_period_at
                end_validity_period_at
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить промокод по ID.
   *
   * @param {Number|String} id ID промокода
   */
  async promocode(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            promocode(id: ${id}) {
              id
              type
              code
              count_activated
              bonus
              type
              start_validity_period_at
              end_validity_period_at
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание промокода.
   *
   * @param {Object} input Новые данные
   */
   async createPromocode(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: CreatePromocodeInput!) {
            createPromocode(input: $input) {
              id
              type
              code
              count_activated
              bonus
              start_validity_period_at
              end_validity_period_at
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Обновление промокода.
   *
   * @param {Object} input Новые данные
   */
  async updatePromocode(input) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($input: UpdatePromocodeInput!) {
            updatePromocode(input: $input) {
              type
              code
              count_activated
              bonus
              start_validity_period_at
              end_validity_period_at
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Удаление промокода.
   *
   * @param {Number|String} id ID промокода
   */
  async deletePromocode(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deletePromocode(id: $id) {
              id
              code
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};
