var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workout-viewer"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localValue,"footer-props":{ itemsPerPageOptions: [ 5, 25, 50, 100 ] },"hide-default-footer":"","disable-filtering":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Тренировка")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getWorkoutStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getWorkoutStatusText(item.status)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.splitNumber(item.price))+" ")]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.date_start))+" ")]}},{key:"item.date_end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.date_end))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }