<template>
  <div id="user-group">
    <v-card class="mb-3">
      <v-card-title>
        {{title}}
      </v-card-title>
    </v-card>

    <v-card>
      <v-container
        class="mb-2"
        fluid
      >
        <ui-filter
          :fields="filterFields"
          v-model="filter"
          @apply="filterApplyHandle"
        />
      </v-container>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
        sort-by="id"
      >
        <template v-slot:[`item.phone`]="{ item }">
          <span>{{item.phone | VMask('+# (###) ###-##-##')}}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            small
            color="primary"
            @click="showMeta(item)"
          >Результат</v-btn>
        </template>
      </v-data-table>
    </v-card>

    <sms-meta-dialog v-model="dialog">
      {{currentMeta}}
    </sms-meta-dialog>
  </div>
</template>

<script>
import { SmsMetaDialog } from '@/components/log/sms/Dialogs';
import { Filterable, Sortable, UserOptions } from '@/mixins';
import { UiFilter } from '@/components/UI';
import { removeObservation } from '@/utils';

export default {
  metaInfo() {
    return {
      title: this.title
    };
  },

  mixins: [Filterable, Sortable, UserOptions],

  components: {
    SmsMetaDialog,
    UiFilter
  },

  data: () => ({
    title: 'Логи SMS',
    loading: false,
    total: 0,
    headers: [
      { text: '#', value: 'id' },
      { text: 'Телефон', value: 'phone' },
      { text: 'Статус', value: 'status' },
      { text: 'Действия', value: 'actions', sortable: false, align: 'end' }
    ],
    items: [],
    dialog: false,
    currentMeta: null,
    filterFields: [
      {
        label: 'Телефон',
        id: 'phone',
        component: 'VTextField'
      },
      {
        label: 'Статус',
        id: 'status',
        component: 'VTextField'
      }
    ]
  }),

  computed: {
    userFilterKey() {
      return 'log-sms-filter';
    },

    userOptionsKey() {
      return 'log-sms';
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    }
  },

  methods: {
    async fetch() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loading = true;
      const filter = removeObservation(this.filter);

      const input = {
        filter
      };

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      const result = await this.$api.logs.smsLogs(page, itemsPerPage, input);

      if (result.data.data.smsLogs) {
        this.items = result.data.data.smsLogs.data;
        this.total = result.data.data.smsLogs.paginatorInfo.total;
      }

      this.loading = false;
    },

    showMeta(item) {
      this.currentMeta = JSON.parse(item.meta.trim());
      this.dialog = true;
    },

    filterApplyHandle() {
      this.fetch();
    }
  }
};
</script>