<template>
  <div id="product-edit">
    <v-card
      :loading="loadingState || checkReferal"
      class="mb-3"
    >
      <v-card-title>
        {{ cardTitle }}

        <v-spacer />

      </v-card-title>
    </v-card>

    <v-scroll-y-reverse-transition>
      <v-alert
        v-if="isCreateNew && !userCanCreate"
        type="error"
      >
        Нет доступа.
      </v-alert>

      <v-card
        v-else-if="entityData && !checkReferal"
        class="mb-3"
      >
        <v-tabs
          v-model="currentTab"
          show-arrows
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="tab in tabs"
            :href="`#tab-${tab.index}`"
            :key="tab.index"
            :disabled="!tab.component"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="currentTab"
          class="mt-3"
        >
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.index"
            :value="`tab-${tab.index}`"
          >
            <v-card flat>
              <v-card-text>
                <component
                  ref="tabContent"
                  :is="tab.component"
                  :entity-data="entityData"
                  v-bind="tab.props"
                  v-model="editInfo"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-scroll-y-reverse-transition>

    <v-scroll-y-reverse-transition>
      <entity-card-actions
        :saving="saving"
        :loading-state="loadingState"
        :can-save="isCreateNew ? userCanCreate : userCanUpdate"
        @save="SaveProduct"
        @saveAndClose="SaveAndCloseProduct"
        @close="close"
        v-if="entityData && !checkReferal"
      />
    </v-scroll-y-reverse-transition>

  </div>
</template>

<script>
import { ProductsEdit } from "@/components/products/edit";
//import { DeletePromocodeDialog } from '@/components/promocode/Dialogs';
import { EntityCard } from "@/mixins";

export default {
  mixins: [EntityCard],

  components: {
    ProductsEdit
    //DeletePromocodeDialog
  },

  data: () => ({
    params: [],
    checkReferal: true,
    childFetch: true,
    createNewTitle: "Новый продукт",
    notFoundMessage: "Продукт не найден.",

    // API
    apiEntityName: "products",
    apiFetchMethodName: "product",
    apiCreateMethodName: "createProduct",
    apiUpdateMethodName: "updateProduct",

    /**
     * Поля, в которых содержатся файлы.
     */
    fileFields: { images: "sync" },
    useFormData: true,

    // Router
    routerIndexName: "ProductsIndex",
    routerEditName: "ProductsEdit",

    policyKey: 'product_policy'
  }),

  computed: {
    tabs() {
      return [
        {
          index: 1,
          title: "Основное",
          component: "ProductsEdit"
        }
      ];
    },

    canRemove() {
      return this.dbData && !this.isCreateNew;
    },

    deletedMessage() {
      return `Продукт «${this.dbData.code}» удален.`;
    },

    cardTitle() {
      if (this.isCreateNew) {
        return this.createNewTitle;
      }

      return this.dbData && !this.checkReferal
        ? `Продукт #${this.dbData.id}`
        : "Загрузка...";
    }
  },

  async mounted() {
    await this.fetch();

    if (!this.isCreateNew && this.dbData.type === "referal") {
      this.notifyError(this.notFoundMessage);
      this.close();
      return;
    }

    this.checkReferal = false;

    this.prepareItem();
  },

  methods: {
    formatParams() {
      let tmp = {};
      let create = [];
      let update = [];
      let params = {};
      let editInfo = this.editInfo.parameters;

      tmp = this.editInfo;

      if (
        editInfo &&
        (Object.prototype.hasOwnProperty.call(editInfo, "create") ||
          Object.prototype.hasOwnProperty.call(editInfo, "update"))
      ) {
        for (let action in editInfo) {
          for (let item in editInfo[action]) {
            if (action === "update") {
              update.push(editInfo[action][item]);
            }
            if (action === "create") {
              create.push(editInfo[action][item]);
            }
          }
          if (create.length > 0) {
            params.create = create;
          }
          if (update.length > 0) {
            params.update = update;
          }
        }
      } else {
        for (let type in editInfo) {
          for (let title in editInfo[type]) {
            if (editInfo[type][title].value) {
              const dbParameter = this.dbData.parameters.find(e => e.type === type && e.title === title)
              if (editInfo[type][title].id || dbParameter) {
                update.push({
                  id: dbParameter?.id || editInfo[type][title].id,
                  title: title,
                  value: editInfo[type][title].value,
                  type: type,
                  sort: editInfo[type][title].sort
                });
              } else {
                create.push({
                  title: title,
                  value: editInfo[type][title].value,
                  type: type,
                  sort: editInfo[type][title].sort
                });
              }
            }
          }
          if (create.length > 0) {
            params.create = create;
          }
          if (update.length > 0) {
            params.update = update;
          }
        }
      }
      console.log("tmp", tmp);
      tmp.parameters = params;

      if (tmp.images.length > 0 && !tmp.images[0].size) {
        delete tmp.images;
      }

      return tmp;
    },

    SaveProduct() {
      this.fetch()
        .then(() => this.saveItem(this.formatParams()))
        .then(() => {
          this.$bus.$emit("refresh");
        });
    },

    SaveAndCloseProduct() {
      this.fetch()
        .then(() => this.saveItem(this.formatParams()))
        .then(() => {
          this.$bus.$emit("refresh");
        });
      this.close();
    },

    prepareItem() {
      if (this.isCreateNew) {
        this.editInfo = {
          type: "global"
        };
      } else {
        this.editInfo = {
          type: this.entityData.type
        };
      }
    }
  }
};
</script>