import axios from 'axios';
import {
  generateMutationFormData,
  removeObservation,
  stringifyFields
} from '@/utils';
import { compact, differenceBy } from 'lodash';

export default {
  /**
   * Получить список новостей.
   *
   * @param {Number|String} page Страница
   * @param {Number|String} itemsPerPage Количество элементов на странице
   * @param {*} input Параметры фильтрации и сортировки
   */
  async news(page, itemsPerPage, input = null) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query($input: IndexNewsInput) {
            news(page: ${page}, first: ${itemsPerPage}, input: $input) {
              data {
                id
                active_from
                active_to
                title
                description
                created_at
                updated_at
              }
              paginatorInfo {
                total
              }
            }
          }`,
        variables: {
          input
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Получить новость по ID.
   *
   * @param {Number|String} id ID новости
   */
  async newsItem(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          query {
            newsItem(id: ${id}) {
              id
              active_from
              active_to
              title
              description
              image {
                id
                src
                url
                name
                mime
              }
              slides {
                id
                image {
                  id
                  src
                  url
                  name
                  mime
                }
                button_text
                button_action
              }
              created_at
              updated_at
            }
          }`
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  },

  /**
   * Создание новости.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async createNews(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'createNews',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Обновление новости.
   *
   * @param {Object} currentInput Текущие данные
   * @param {Object} input Новые данные
   * @param {Object} files Файлы
   * @param {Object} fileFields Поля, в которых находятся файлы
   */
  async updateNews(currentInput, input, files = {}, fileFields = {}) {
    const formData = getFormData(
      'updateNews',
      currentInput,
      input,
      files,
      fileFields
    );

    return await axios.post(process.env.VUE_APP_GRAPHQL_URL, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
  },

  /**
   * Удаление промокода.
   *
   * @param {Number|String} id ID промокода
   */
  async deleteNews(id) {
    return await axios.post(
      process.env.VUE_APP_GRAPHQL_URL,
      {
        query: `
          mutation($id: ID!) {
            deleteNews(id: $id) {
              id
            }
          }`,
        variables: {
          id
        }
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
    );
  }
};

/**
 * Генерация FormData для зала.
 *
 * @param {String} mutationName Имя мутации
 * @param {Object} currentInput Текущие данные
 * @param {Object} input Новые данные
 * @param {Object} files Файлы
 * @param {Object} fileFields Поля, в которых находятся файлы
 * @param {String[]} additionalReturnsFields Дополнительные возвращаемые поля
 */
function getFormData(
  mutationName,
  currentInput,
  input,
  files = {},
  fileFields = {},
  additionalReturnsFields = []
) {
  input = removeObservation(input);

  // Параметры мутации
  let mutationParams = [
    '$active_from: Timestamp!',
    '$active_to: Timestamp!',
    '$title: String!',
    '$description: String!'
  ];

  // Параметры метода
  let methodParams = [
    'active_from: $active_from',
    'active_to: $active_to',
    'title: $title',
    'description: $description'
  ];

  const returnFields = [
    'id',
    'active_from',
    'active_to',
    'title',
    'description',
    'created_at',
    'updated_at',
    'image { id, src, url, name, mime }',
    'slides { id, image { id, src, url, name, mime }, button_text, button_action }'
  ].concat(additionalReturnsFields);

  if (input.id) {
    mutationParams.push('$id: ID!');
    methodParams.push('id: $id');
  }

  const formData = new FormData();

  let map = {};

  const operations = {
    variables: {}
  };

  /**
   * Слайды
   *
   * Блок кода ниже - один сплошной костыль.
   *
   * Сложности вызвали файлы, из-за того, что они вложены в объект, и формирование json поля.
   */

  if (input.slides) {
    const slides = input.slides;
    const params = [];

    // Созданные элементы
    const newSlides = slides.filter(item => !item.id);

    // Существующие элементы
    const existingSlides = slides.filter(item => item.id);

    let fileIndex = 0;

    if (newSlides.length > 0) {
      const newSlidesParams = [];

      newSlides.forEach(slide => {
        if (Array.isArray(slide.image)) {
          slide.image = slide.image[0];
        }

        if (slide.image.id) {
          slide.image = {
            connect: slide.image.id
          };
        } else {
          map[`slides_image_${fileIndex}`] = [
            `variables.slides_image_${fileIndex}Src`
          ];
          formData.append(`slides_image_${fileIndex}`, slide.image);

          mutationParams.push(`$slides_image_${fileIndex}Name: String!`);
          mutationParams.push(`$slides_image_${fileIndex}Src: Upload!`);

          // Добавляем переменные файла
          operations.variables[`slides_image_${fileIndex}Name`] =
            slide.image.name;
          operations.variables[`slides_image_${fileIndex}Src`] = null;

          slide.image = {
            create: {
              name: `$slides_image_${fileIndex}Name`,
              src: `$slides_image_${fileIndex}Src`
            }
          };
        }

        // Формирование строки для json
        slide.button_action = `{'entity': '${
          slide.button_action.entity
        }', 'entity_id': ${
          slide.button_action.entity_id !== null
            ? "'" + slide.button_action.entity_id + "'"
            : slide.button_action.entity_id
        }}`;

        newSlidesParams.push(
          stringifyFields(slide, [
            slide.button_text,
            slide.button_action
          ]).replaceAll("'", `\\"`)
        );

        fileIndex++;
      });

      params.push(`create: [${newSlidesParams.join(',')}]`);
    }

    if (existingSlides.length > 0) {
      const existingSlidesParams = [];

      existingSlides.forEach(slide => {
        if (Array.isArray(slide.image)) {
          slide.image = slide.image[0];
        }

        if (slide.image.id) {
          slide.image = {
            connect: slide.image.id
          };
        } else {
          map[`slides_image_${fileIndex}`] = [
            `variables.slides_image_${fileIndex}Src`
          ];
          formData.append(`slides_image_${fileIndex}`, slide.image);

          mutationParams.push(`$slides_image_${fileIndex}Name: String!`);
          mutationParams.push(`$slides_image_${fileIndex}Src: Upload!`);

          // Добавляем переменные файла
          operations.variables[`slides_image_${fileIndex}Name`] =
            slide.image.name;
          operations.variables[`slides_image_${fileIndex}Src`] = null;

          slide.image = {
            create: {
              name: `$slides_image_${fileIndex}Name`,
              src: `$slides_image_${fileIndex}Src`
            }
          };
        }

        // Формирование строки для json
        slide.button_action = `{'entity': '${
          slide.button_action.entity
        }', 'entity_id': ${
          slide.button_action.entity_id !== null
            ? "'" + slide.button_action.entity_id + "'"
            : slide.button_action.entity_id
        }}`;

        existingSlidesParams.push(
          stringifyFields(slide, [
            slide.button_text,
            slide.button_action
          ]).replaceAll("'", `\\"`)
        );

        fileIndex++;
      });

      params.push(`update: [${existingSlidesParams.join(',')}]`);
    }

    if (currentInput && currentInput.slides) {
      const removedSlides = differenceBy(
        currentInput.slides,
        existingSlides,
        'id'
      );

      let removedSlideIds = removedSlides.map(item => item.id);
      removedSlideIds = compact(removedSlideIds);

      if (removedSlideIds.length > 0) {
        params.push(`delete: [${removedSlideIds.join(',')}]`);
      }
    }

    methodParams.push(`slides: {${params.join(',')}}`);
  }

  return generateMutationFormData(
    mutationName,
    currentInput,
    input,
    methodParams,
    mutationParams,
    returnFields,
    files,
    fileFields,
    formData,
    map,
    operations
  );
}
