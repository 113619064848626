<template>
  <div id="upload-file-dialog">
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title>
          Добавить файлы
        </v-card-title>

        <v-card-text>
          <file-input
            outlined
            :multiple="multiple"
            v-model="filesForUpload"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="uploadFiles()"
            :loading="loading"
            :disabled="filesForUpload.length === 0"
          >
            Загрузить
          </v-btn>
          <v-btn
            text
            @click="closeUploadingFilesDialog()"
            :disabled="loading"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Dialog } from '@/mixins';
import { FileInput } from '@/components/UI';

export default {
  name: 'upload-file-dialog',

  mixins: [Dialog],

  components: {
    FileInput
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    filesForUpload: []
  }),

  methods: {
    async uploadFiles() {
      this.loading = true;

      for (const file of this.filesForUpload) {
        await this.$api.file.createFile(file);
      }

      this.$emit('uploaded', this.filesForUpload);
      this.loading = false;

      this.closeUploadingFilesDialog();
    },

    closeUploadingFilesDialog() {
      this.loading = false;
      this.filesForUpload = [];
      this.dialog = false;
    }
  }
};
</script>