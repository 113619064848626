<template>
  <div class="favorite-gyms-viewer">
    <v-container fluid>
      <gym-viewer
        :items="localValue"
        no-items-text="Нет избранных залов."
        new-window
      ></gym-viewer>
    </v-container>
  </div>
</template>

<script>
import GymViewer from '@/components/GymViewer/GymViewer';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  components: {
    GymViewer
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
