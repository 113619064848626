<template>
  <div class="policies-viewer">
    <template v-if="!loading">
      <v-card v-for="(groupPolicies, key) in policiesGroups" :key="key" class="mb-3" color="grey darken-3">
        <v-card-title>
          <v-checkbox hide-details class="mt-0" v-model="policiesGroupsChecked[key]" @click="changeGroupState(key)"
            :disabled="disabled">
            <template v-slot:label>
              <span class="text-h6 white--text">{{ getPolicyTitle(key) }}</span>
            </template>
          </v-checkbox>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="(policy, pKey) in groupPolicies" :key="pKey">
              <v-checkbox class="mt-0" v-model="localValue" hide-details :value="getPolicyName(key, policy)"
                :label="getPolicyValueTitle(policy)" @click="checkPoliciesGroupsChecked()"
                :disabled="disabled"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>

    <div class="d-flex justify-center" v-else>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { indexOf } from 'lodash';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    policies: [],

    loading: false,

    policiesTitle: {
      user_group_policy: 'Группы пользователей',
      user_policy: 'Пользователи',
      file_policy: 'Файлы',
      gym_policy: 'Залы',
      gym_problem_policy: 'Состояние залов',
      gym_equipment_policy: 'Оборудование',
      sms_log_policy: 'Логи SMS',
      news_policy: 'Новости',
      config_policy: 'Конфигурация',
      machine_policy: 'Автоматы',
      product_policy: 'Товары',
      product_parameter_policy: 'Свойства товаров',
      promocode_policy: 'Промокоды',
      faq_policy: 'FAQ',
      order_policy: 'Заказы',
      point_policy: 'point_policy',
      transaction_policy: 'Транзакции',
      workout_policy: 'Тренировки'
    },

    policiesValueTitle: {
      view: 'Просмотр',
      create: 'Создание',
      update: 'Обновление',
      delete: 'Удаление'
    },

    policiesGroups: {},

    /** @var Определение, что выбрана вся группа прав */
    policiesGroupsChecked: {}
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  async mounted() {
    await this.fetch();
    this.preparePolicies();
    this.checkPoliciesGroupsChecked();
  },

  methods: {
    async fetch() {
      this.loading = true;

      const result = await this.$api.userGroup.policies();

      if (result.data.data.policies) {
        this.policies = result.data.data.policies;
      }

      this.loading = false;
    },

    preparePolicies() {
      this.policies.forEach((policy) => {
        const policyName = policy.substr(0, policy.indexOf('::'));
        const policyValue = policy.substr(policy.indexOf('::') + 2);

        if (!this.policiesGroups[policyName]) {
          this.$set(this.policiesGroups, policyName, []);
        }

        if (!this.policiesGroups[policyName].includes(policyValue)) {
          this.policiesGroups[policyName].push(policyValue);
        }
      });

      this.$emit('input', this.localValue);
    },

    checkPoliciesGroupsChecked() {
      setTimeout(() => {
        for (let policyGroup in this.policiesGroups) {
          if (!this.policiesGroupsChecked[policyGroup]) {
            this.$set(this.policiesGroupsChecked, policyGroup, true);
          }

          this.policiesGroups[policyGroup].forEach((policyValue) => {
            if (
              !this.localValue.includes(
                this.getPolicyName(policyGroup, policyValue)
              )
            ) {
              this.$set(this.policiesGroupsChecked, policyGroup, false);
            }
          });
        }
      });
    },

    changeGroupState(policyGroup) {
      this.policiesGroups[policyGroup].forEach((policyValue) => {
        const policyName = this.getPolicyName(policyGroup, policyValue);

        if (!this.policiesGroupsChecked[policyGroup]) {
          const policyIndex = indexOf(this.localValue, policyName);

          if (policyIndex !== -1) {
            this.localValue.splice(policyIndex, 1);
          }
        } else {
          if (!this.localValue.includes(policyName)) {
            this.localValue.push(policyName);
          }
        }
      });
    },

    getPolicyTitle(policyKey) {
      return this.policiesTitle[policyKey] || policyKey;
    },

    getPolicyValueTitle(policyValueKey) {
      return this.policiesValueTitle[policyValueKey];
    },

    getPolicyName(polycyGroup, policyValue) {
      return `${polycyGroup}::${policyValue}`;
    }
  }
};
</script>
