import { File } from '@/mixins';

export default {
  mixins: [File],

  props: {
    items: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      defaul: false
    },

    total: {
      type: Number,
      default: 0
    },

    itemsPerPage: {
      type: Number,
      default: 0
    },

    serverItemsLength: {
      type: Number,
      default: 0
    },

    footerProps: {
      type: Object,
      default: () => ({})
    },

    searchMessage: {
      type: String,
      default: ''
    },

    multiple: {
      type: Boolean,
      defaul: false
    },

    selectedItems: {
      type: [Array, Object],
      default: () => []
    },

    options: {
      type: Object,
      default: () => ({})
    },

    selectionMode: {
      type: Boolean,
      defaul: false
    }
  },

  computed: {
    optionsLocal: {
      get() {
        return this.options;
      },

      set(value) {
        this.$emit('update:options', value);
      }
    },

    selectedItemsLocal: {
      get() {
        return this.selectedItems;
      },

      set(value) {
        this.$emit('update:selectedItems', value);
        this.$emit('select', value);
      }
    }
  },

  methods: {
    handleSelect() {
      this.$emit('select', this.selectedItemsLocal);
    },

    handleClick(item) {
      this.$emit('click', item);
    }
  }
};
