import { splitNumber as _splitNumber } from '@/utils';

export default {
  methods: {
    splitNumber(number) {
      return _splitNumber(number);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
