var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-viewer__data-table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsLocal,"loading":_vm.loading,"server-items-length":_vm.serverItemsLength,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"disable-sort":"","single-select":!_vm.multiple,"show-select":_vm.selectionMode},on:{"update:options":function($event){_vm.optionsLocal=$event}},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"file-item ma-3",staticStyle:{"overflow":"hidden"},attrs:{"height":"70","width":"70","flat":""}},[_c('v-sheet',{staticClass:"file-item__sheet d-flex align-center justify-center",attrs:{"color":_vm.getFileColor(item),"height":"100%","width":"70"}},[(_vm.isImage(item))?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.isImage(item))?_c('v-img',{staticClass:"grey darken-3",attrs:{"src":item.url,"aspect-ratio":"1"},on:{"click":function($event){_vm.lightBoxIndex = _vm.getImageIndex(item.id)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{staticStyle:{"cursor":"pointer"},attrs:{"absolute":"","color":"secondary"}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-magnify-plus-outline")])],1):_vm._e()],1)],1):_vm._e()]}}],null,true)}):_c('v-icon',[_vm._v(_vm._s(_vm.getFileIcon(item)))])],1)],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFileName(item.name))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.vFormatDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('v-container',[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"min-height":"100px"}},[(_vm.searchMessage === '')?_c('span',[_vm._v("Файлы не найдены.")]):_c('span',[_vm._v("По запросу «"+_vm._s(_vm.searchMessage)+"» ничего не найдено.")])])])]},proxy:true}],null,true),model:{value:(_vm.selectedItemsLocal),callback:function ($$v) {_vm.selectedItemsLocal=$$v},expression:"selectedItemsLocal"}}),_c('delete-files-dialog',{attrs:{"items":_vm.itemForDeletion,"description-text":"Вы действительно хотите удалить файл?"},on:{"deleted":function($event){return _vm.handleDeleted()}},model:{value:(_vm.deleteFileDialog),callback:function ($$v) {_vm.deleteFileDialog=$$v},expression:"deleteFileDialog"}}),_c('CoolLightBox',{attrs:{"items":_vm.lightBoxItems,"index":_vm.lightBoxIndex},on:{"close":function($event){_vm.lightBoxIndex = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }