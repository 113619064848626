export default {
  computed: {
    USER_STATUS_NEW() {
      return 'NEW';
    },

    USER_STATUS_FILLED() {
      return 'FILLED';
    },

    USER_STATUS_VERIFIED() {
      return 'VERIFIED';
    },

    USER_STATUS_RECHECK() {
      return 'RECHECK';
    },

    userStatuses() {
      return [
        {
          text: this.getUserStatusText(this.USER_STATUS_NEW),
          value: this.USER_STATUS_NEW
        },
        {
          text: this.getUserStatusText(this.USER_STATUS_FILLED),
          value: this.USER_STATUS_FILLED
        },
        {
          text: this.getUserStatusText(this.USER_STATUS_VERIFIED),
          value: this.USER_STATUS_VERIFIED
        },
        {
          text: this.getUserStatusText(this.USER_STATUS_RECHECK),
          value: this.USER_STATUS_RECHECK
        }
      ];
    }
  },

  methods: {
    getUserStatusColor(value) {
      if (value === this.USER_STATUS_VERIFIED) return 'green';
      else if (value === this.USER_STATUS_FILLED) return 'amber darken-1';
      else if (value === this.USER_STATUS_NEW) return 'purple';
      else if (value === this.USER_STATUS_RECHECK) return 'cyan darken-1';
      else return 'gray';
    },

    getUserStatusText(value) {
      if (value === this.USER_STATUS_VERIFIED) return 'Проверен';
      else if (value === this.USER_STATUS_FILLED) return 'Заполнен';
      else if (value === this.USER_STATUS_NEW) return 'Новый';
      else if (value === this.USER_STATUS_RECHECK) return 'Отправить на перепроверку';
      else return `Неизвестный статус ${value}`;
    }
  }
};
