export function removeObservation(data) {
  if (!data) return data;

  if (
    typeof data === 'number' ||
    typeof data === 'string' ||
    typeof data === 'boolean'
  )
    return JSON.parse(JSON.stringify(data));

  if (Array.isArray(data)) {
    let _data = [];

    data.forEach(obj => {
      _data.push(obj instanceof File ? obj : removeObservation(obj));
    });

    return _data;
  } else {
    return Object.keys(data).reduce(
      (res, e) =>
        Object.assign(res, {
          [e]: data[e] instanceof File ? data[e] : removeObservation(data[e])
        }),
      {}
    );
  }
}
