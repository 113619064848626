<template>
  <div class="slides-viewer">
    <div
      class="mb-3"
      v-if="localValue.length === 0"
    >Нет элементов.</div>

    <validation-observer ref="observer">
      <v-row v-if="localValue.length > 0">
        <v-col
          v-for="(item, index) in localValue"
          :key="index"
          cols="12"
          md="6"
        >
          <v-card
            class="mb-3"
            color="secondary"
          >
            <v-card-text class="pb-0">
              <v-col
                cols="12"
                class="py-0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`image${index}`"
                  :rules="'required'"
                >
                  <file-input-select
                    v-model="item.image"
                    outlined
                    label="Изображение"
                    :error-messages="getErrorMessage(errors)"
                  ></file-input-select>
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`button_text${index}`"
                  :rules="'required'"
                >
                  <v-text-field
                    v-model="item.button_text"
                    label="Текст кнопки"
                    outlined
                    :error-messages="getErrorMessage(errors)"
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                class="py-0"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`button_action${index}`"
                  :rules="'required'"
                >
                  <v-select
                    v-model="item.button_action.entity"
                    :items="buttonActions"
                    label="Действие кнопки"
                    outlined
                    @change="onButtonActionChange(item, $event)"
                    :error-messages="getErrorMessage(errors)"
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="12"
                class="py-0"
                v-if="item.button_action.entity === 'gym'"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="`button_action${index}`"
                  :rules="'required'"
                >
                  <v-autocomplete
                    v-model="item.button_action.entity_id"
                    :items="gyms"
                    :loading="loading"
                    hide-no-data
                    item-text="title"
                    item-value="id"
                    label="Зал"
                    placeholder="Введите название зала для поиска"
                    outlined
                    cache-items
                    :error-messages="getErrorMessage(errors)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeGym(item)"
                      >
                        {{ data.item.title }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{data.item.title}}</v-list-item-title>
                        <v-list-item-subtitle>{{`id: ${data.item.id}`}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="error"
                small
                text
                @click="removeItem(index)"
              >Удалить</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </validation-observer>

    <v-divider class="mb-3" />

    <v-btn
      color="info"
      small
      @click="addItem"
    >Добавить</v-btn>
  </div>
</template>

<script>
import { Validation } from '@/mixins';
import { FileInputSelect } from '@/components/UI';

export default {
  mixins: [Validation],

  components: {
    FileInputSelect
  },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    loading: false,
    search: null,
    gyms: [],
    buttonActions: [
      {
        text: 'Мои тренировки',
        value: 'workouts'
      },
      {
        text: 'Список залов',
        value: 'gyms'
      },
      {
        text: 'Переход на зал',
        value: 'gym'
      },
      {
        text: 'Уведомления',
        value: 'notifications'
      },
      {
        text: 'Пополнить счёт',
        value: 'account'
      },
      {
        text: 'Промокоды',
        value: 'promocodes'
      },
      {
        text: 'Избранные залы',
        value: 'favorite_gyms'
      },
      {
        text: 'Личная информация',
        value: 'profile'
      }
    ]
  }),

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.fetchGyms();
    });
  },

  methods: {
    async fetchGyms() {
      this.loading = true;

      const result = await this.$api.gym.gyms();

      if (result.data.data.gyms) {
        this.$set(this, 'gyms', result.data.data.gyms);
      }

      this.loading = false;
    },

    removeGym(item) {
      item.button_action.entity_id = null;
    },

    addItem() {
      this.localValue.push({
        image: null,
        button_text: null,
        button_action: {
          entity: null,
          entity_id: null
        }
      });
    },

    removeItem(index) {
      this.$delete(this.localValue, index);
    },

    onButtonActionChange(item, value) {
      if (value !== 'gym') {
        this.removeGym(item);
      }
    }
  }
};
</script>
