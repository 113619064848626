<template>
  <div class="edit">
    <v-container
      fluid
      class="px-0"
      v-for="(row, index) in fieldsRow"
      :key="index"
    >
      <div
        class="text-h5 mb-3"
        v-if="row.title"
      >{{row.title}}</div>

      <v-row>
        <v-col
          v-for="(field, cIndex) in row.fields"
          :key="cIndex"
          v-bind="field.colProps || row.colsProp"
          class="py-0"
        >
          <component
            :is="field.component"
            v-model="localValue[field.id]"
            v-bind="field.props"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Entity } from '@/mixins';
import gymViewer from './viewer';

export default {
  mixins: [Entity],

  components: {
    gymViewer
  },

  computed: {
    fieldsRow() {
      return [
        {
          fields: [
            {
              id: 'gym',
              component: 'gymViewer'
            }
          ]
        }
      ];
    }
  }
};
</script>
