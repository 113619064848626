<template>
  <div class="user-viewer">
    <v-container
      class="mb-2"
      fluid
    >
      <ui-filter
        :fields="filterFields"
        v-model="filter"
        @apply="filterApplyHandle"
        ref="uiFilter"
      >
        <template v-slot:status="{ item }">
          <strong>{{item.label}}:</strong><span class="ml-1">{{getUserStatusText(item.value)}}</span>
        </template>
      </ui-filter>
    </v-container>

    <v-data-table
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      :show-select="selectable"
      :single-select="!multiple"
      :footer-props="{ itemsPerPageOptions: [ 5, 25, 50, 100 ] }"
      sort-by="id"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{vFormatDate(item.created_at)}}
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <span>{{item.phone | VMask('+# (###) ###-##-##')}}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          small
          :color="getUserStatusColor(item.status)"
        >{{getUserStatusText(item.status)}}</v-chip>
      </template>

      <template v-slot:[`item.account`]="{ item }">
        <v-chip
          small
          :color="getAccountColor(item.account)"
        >{{splitNumber(item.account)}}</v-chip>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
        v-if="useActions"
      >
        <v-icon
          small
          class="mr-2"
          @click="edit(item)"
        >mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatDateUnix, removeObservation } from '@/utils';
import {
  Filterable,
  Notify,
  NumberMixin,
  Sortable,
  UserAccount,
  UserOptions,
  UserStatus
} from '@/mixins';
import { UiFilter } from '@/components/UI';

export default {
  name: 'user-viewer',

  mixins: [
    Filterable,
    Notify,
    NumberMixin,
    Sortable,
    UserAccount,
    UserOptions,
    UserStatus
  ],

  props: {
    useActions: {
      type: Boolean,
      default: false
    },

    saveFilter: {
      type: Boolean,
      default: true
    },

    selectable: {
      type: Boolean,
      default: false
    },

    /**
     * Работает, если selectable = true.
     */
    multiple: {
      type: Boolean,
      default: false
    },

    saveGridOptions: {
      type: Boolean,
      default: true
    }
  },

  components: {
    UiFilter
  },

  data: () => ({
    loading: false,
    total: 0,
    items: [],
    selectedItems: []
  }),

  computed: {
    userFilterKey() {
      return this.saveFilter ? 'user-grid-filter' : null;
    },

    userOptionsKey() {
      return this.saveGridOptions ? 'user-grid' : null;
    },

    filterFields() {
      return [
        {
          label: 'Телефон',
          id: 'phone',
          component: 'VTextField'
        },
        {
          label: 'Email',
          id: 'email',
          component: 'VTextField'
        },
        {
          label: 'Имя',
          id: 'first_name',
          component: 'VTextField'
        },
        {
          label: 'Фамилия',
          id: 'second_name',
          component: 'VTextField'
        },
        {
          label: 'Дата рождения',
          id: 'birthday',
          component: 'DatePickerRange'
        },
        {
          label: 'Дата подтверждения Email',
          id: 'email_verified_at',
          component: 'DatePickerRange'
        },
        {
          label: 'Статус',
          id: 'status',
          component: 'VSelect',
          props: {
            items: this.userStatuses
          }
        },
        {
          label: 'Баланс',
          id: 'account',
          component: 'NumberInputRange'
        },
        {
          label: 'Дата регистрации',
          id: 'created_at',
          component: 'DatePickerRange'
        },
        {
          label: 'Дата обновления',
          id: 'updated_at',
          component: 'DatePickerRange'
        }
      ];
    },

    headers() {
      let _headers = [
        { text: '#', value: 'id' },
        { text: 'Дата регистрации', value: 'created_at' },
        { text: 'Телефон', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Имя', value: 'first_name' },
        { text: 'Фамилия', value: 'last_name' },
        { text: 'Статус', value: 'status' },
        { text: 'Баланс', value: 'account' }
      ];

      if (this.useActions) {
        _headers.push({
          text: 'Действия',
          value: 'actions',
          sortable: false,
          align: 'end'
        });
      }

      return _headers;
    }
  },

  watch: {
    options: {
      async handler() {
        await this.fetch();
      },
      deep: true
    },

    selectedItems() {
      this.sendModel();
    }
  },

  methods: {
    blurCombobox() {
      this.$refs.uiFilter.blurCombobox();
    },

    async fetch() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      const input = {};

      const filter = this.getFilterInput(
        removeObservation(this.filter),
        this.getFilterMap(this.filterFields)
      );

      if (filter) {
        input.filter = filter;
      }

      const orderBy = this.getSortInput(sortBy, sortDesc);

      if (orderBy) {
        input.orderBy = orderBy;
      }

      this.loading = true;
      const result = await this.$api.user.users(page, itemsPerPage, input);

      if (result.data.data.users) {
        this.items = result.data.data.users.data;
        this.total = result.data.data.users.paginatorInfo.total;
      }

      this.loading = false;
    },

    edit(item) {
      this.$router.push({ name: 'UserEdit', params: { id: item.id } });
    },

    filterApplyHandle() {
      this.fetch();
    },

    vFormatDate(date) {
      return formatDateUnix(date, 'DD.MM.YYYY HH:mm:ss');
    },

    sendModel() {
      let selectedItems = removeObservation(this.selectedItems);

      if (!this.multiple && Array.isArray(selectedItems))
        selectedItems = selectedItems[0];

      const items = this.selectable ? selectedItems : this.items;
      this.$emit('input', items);
      this.$emit('select', selectedItems);
    },

    resetSelection() {
      this.selectedItems = [];
      this.sendModel();
    }
  }
};
</script>