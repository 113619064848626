<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    scrollable
  >
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-card-text>
        <file-viewer
          ref="fileViewer"
          :multiple="multiple"
          disable-edit-mode
          selectable
          grid-options-key="files-modal"
          @select="selectedItems = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="loading"
          @click="selectItems()"
          :disabled="!this.selectedItems || this.selectedItems.length === 0"
        >
          Выбрать
        </v-btn>

        <v-btn
          text
          @click="closeModal"
          :disabled="loading"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Dialog } from '@/mixins';
import FileViewer from '@/components/FileViewer/FileViewer';

export default {
  name: 'file-viewer-dialog',

  mixins: [Dialog],

  components: {
    FileViewer
  },

  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    title: 'Медиатека',
    selectedItems: []
  }),

  methods: {
    closeModal() {
      this.$refs.fileViewer.resetSelection();
      this.dialog = false;
    },

    selectItems() {
      this.$emit(
        'select',
        this.multiple ? this.selectedItems : [this.selectedItems]
      );
      this.closeModal();
    }
  }
};
</script>